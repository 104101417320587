import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AiTwotoneEdit } from "react-icons/ai";
// import { Button, ButtonGroup } from "react-bootstrap";
import TreeItem from "@mui/lab/TreeItem";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";

import {
  changeEditableComponent,
  toogleShowComponent,
  changeActiveTab,
} from "../../../../config/slices/builder";

export default function Layer({ component, childs = [] }) {
  const { components } = useSelector((state) => state.builder);
  const { property } = components[component];
  const dispatch = useDispatch();

  const handleToggleShow = (component) =>
    dispatch(toogleShowComponent(component));

  const handleEdit = (component) => {
    dispatch(changeEditableComponent(component));
    dispatch(changeActiveTab("settings"));
  };

  return (
    <TreeItem
      label={
        <ListItem
          component="div"
          sx={{ pl: 0 }}
          secondaryAction={
            <>
              {property.hasOwnProperty("show") && (
                <IconButton
                  size="small"
                  color=""
                  onClick={() => handleToggleShow(component)}
                >
                  {property.show ? <FaEyeSlash /> : <FaEye />}
                </IconButton>
              )}

              <IconButton
                size="small"
                color="warning"
                onClick={() => handleEdit(component)}
              >
                <AiTwotoneEdit />
              </IconButton>
            </>
          }
        >
          {component}
        </ListItem>
      }
      nodeId={component}
    >
      {childs.length > 0 &&
        childs.map((layer) => (
          <Layer key={`Layer.${layer.component}`} {...layer} />
        ))}
    </TreeItem>
  );
}
