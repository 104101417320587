import { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import SvgIcon from "@mui/material/SvgIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { validationSchema } from "../../../config";
import { updBilling } from "../../../config/slices/user";
import { BILLING, CAMPAIGN } from "../../../config/RESTAPI/";
import { TextInput } from "../../";

const Step3 = ({ backStepCallback, formData, nextStepCallback }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => window.scrollTo(0, 0), []);

  const handleSubmit = async (values) => {
    nextStepCallback();

    if (!values.contract.limit_enable || values.contract.nft_type === "single") {
      values.contract.max_mint_amount = 0;
    }

    if (values.contract.nft_type === "single" && values.contract.single_type !== "limited") {
      values.contract.total_number = 0;
    }

    try {
      const { status } = await CAMPAIGN.Add({ ...formData, ...values });

      if (status === "success") {
        const { status, data } = await BILLING.GetList();

        if (status === 200) {
          dispatch(updBilling({ ...data }));
        }

        Swal.fire({
          title: "Success!",
          text: "Your campaign has been created.\nPlease note you need to publish your smart contract in order to get the campaign running. You can do so by using Options in the Manage Campaigns section. Publishing a smart contract will cost you some gas.",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(({ isConfirmed }) => isConfirmed && navigate("/campaigns"));
      } else {
        Swal.fire("Error", "Something went wrong when creating a campaign. Please try again later.", "error");
      }
    } catch (e) {
      Swal.fire("Error", "Something went wrong when creating a campaign. Please try again later.", "error");
    }
  };

  return (
    <Box
      sx={{
        my: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Formik
        initialValues={{
          contract: {
            nft_type: "collection",
            nft_name: "",
            nft_symbol: "",
            cid: "",
            nft_price: "",
            affiliate_percent: "",
            limit_enable: false,
            max_mint_amount: "",
            total_number: "",
            single_type: "one",
            hidden_nft: false,
          },
        }}
        validationSchema={validationSchema.contract}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, touched }) => (
          <Form>
            <Field name="contract[nft_type]">
              {({ field }) => (
                <Box sx={{ mb: 4.5 }}>
                  <InputLabel
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: "21px",
                      ml: 4.5,
                      color: "#ADADAD",
                    }}
                  >
                    NFT Type
                  </InputLabel>

                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      position: "relative",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 16,
                      lineHeight: "24px",
                      p: "20px 36px",
                      background: "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                      color: "#ADADAD",
                      borderRadius: 14,
                      ".MuiInputBase-input": {
                        p: 0,
                        "&::placeholder": {
                          color: "#ADADAD",
                        },
                        "&.Mui-disabled": {
                          textFillColor: "#ADADAD",
                        },
                      },
                      ".MuiInputAdornment-root": {
                        mr: 0,
                        color: "var(--default-text-color)",
                        ".MuiTypography-root": {
                          fontSize: "inherit",
                          color: "inherit",
                        },
                      },
                    }}
                  >
                    <SvgIcon
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={null}
                      sx={{
                        fill: "none",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        overflow: "visible",
                        zIndex: 10,
                      }}
                    >
                      <rect x="0.5" y="0.5" width="100%" height="100%" rx="31.5" stroke="white" />
                    </SvgIcon>

                    <Select
                      {...field}
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{
                        width: "100%",
                        "& > fieldset": {
                          display: "none",
                        },
                        ".MuiSelect-select": {
                          color: "#ADADAD",
                          fontSize: 16,
                          lineHeight: "24px",
                        },
                        ".MuiSvgIcon-root": {
                          width: "1em",
                          height: "1em",
                          color: "#ADADAD",
                        },
                      }}
                    >
                      <MenuItem value="single">Single</MenuItem>
                      <MenuItem value="collection">Collection</MenuItem>
                    </Select>
                  </Box>

                  <Typography variant="helper.text" component="p" sx={{ mt: 1 }}>
                    Note: if you have one source file that will be tokenized, select Single. If you have multiple source files, select Collection.
                  </Typography>
                </Box>
              )}
            </Field>

            <Field name="contract[nft_name]">
              {({ field }) => (
                <TextInput
                  field={{ ...field, disabled: isSubmitting }}
                  labelText="NFT Name"
                  error={touched?.contract?.nft_name && Boolean(errors?.contract?.nft_name)}
                  helperText={touched?.contract?.nft_name && errors?.contract?.nft_name}
                />
              )}
            </Field>

            <Field name="contract[nft_symbol]">
              {({ field }) => (
                <TextInput
                  field={{ ...field, disabled: isSubmitting }}
                  labelText="Symbol"
                  error={touched?.contract?.nft_symbol && Boolean(errors?.contract?.nft_symbol)}
                  helperText={touched?.contract?.nft_symbol && errors?.contract?.nft_symbol}
                />
              )}
            </Field>

            <Field name="contract[hidden_nft]">
              {({ field }) => (
                <Box sx={{ display: "flex", alignItems: "center", mb: 4.5 }}>
                  <Checkbox {...field} id="hidden_nft" defaultChecked={false} />
                  <InputLabel htmlFor="hidden_nft" sx={{ color: "var(--default-text-color)" }}>
                    Delayed Reveal
                  </InputLabel>
                </Box>
              )}
            </Field>

            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              {values.contract.hidden_nft ? (
                <span>
                  Provide a link to your hidden NFT Metadata. If it's stored on IPFS, the URL will be like this:
                  ipfs://QmXtCxaLpmz8gmWZ6M1NcuWMyaC75hv4SYF1J7o9YA82Qx where the last part is your CID. If the metadata is stored in the Cloud, provide a full
                  URL to the JSON file or folder (i.e. https://example-domain.com/folder/hidden.json)
                </span>
              ) : values.contract.nft_type === "single" ? (
                <span>
                  Provide a link to your NFT Metadata. If it's stored on IPFS, the URL will be like this: ipfs://QmXtCxaLpmz8gmWZ6M1NcuWMyaC75hv4SYF1J7o9YA82Qx
                  where the last part is your CID. If the metadata is stored in the Cloud, provide a full URL to the JSON file (i.e.
                  https://example-domain.com/folder/1.json).
                </span>
              ) : (
                <span>
                  Provide a link to your NFT Metadata folder. If it's stored on IPFS, the URL will be like this:
                  ipfs://QmXtCxaLpmz8gmWZ6M1NcuWMyaC75hv4SYF1J7o9YA82Qx where the last part is your CID. If the metadata is stored in the Cloud, provide a full
                  URL to the folder with JSON files (i.e. https://example-domain.com/folder/). Important: Make sure your JSON files start with number 1.
                  Example: 1.json, 2.json, etc.
                </span>
              )}
            </Alert>

            <Field name="contract[cid]">
              {({ field }) => (
                <TextInput
                  field={{ ...field, disabled: isSubmitting }}
                  labelText={values.contract.hidden_nft ? "Hidden Metadata URL" : "NFT Metadata URL"}
                  error={touched?.contract?.cid && Boolean(errors?.contract?.cid)}
                  helperText={
                    values.contract.hidden_nft &&
                    "Note: You will be able to further reveal your NFTs while managing your smart contract through REminto dashboard."
                  }
                />
              )}
            </Field>

            <Field name="contract[nft_price]">
              {({ field }) => (
                <TextInput
                  field={{ ...field, disabled: isSubmitting }}
                  labelText="NFT Price"
                  error={touched?.contract?.nft_price && Boolean(errors?.contract?.nft_price)}
                  helperText={
                    <>
                      Note: The price will be set in the currency of the corresponding network, so for Ethereum the defined price will be set in ETH, and for
                      Polygon network in MATIC. You can use this{" "}
                      <a href="https://eth-converter.com/" target="_blank" rel="noreferrer">
                        Converter
                      </a>{" "}
                      to correctly calculate price in ETH or MATIC.
                    </>
                  }
                />
              )}
            </Field>

            <Field name="contract[affiliate_percent]">
              {({ field }) => (
                <TextInput
                  field={{ ...field, disabled: isSubmitting }}
                  labelText="Affiliate Percent"
                  error={touched?.contract?.affiliate_percent && Boolean(errors?.contract?.affiliate_percent)}
                  helperText={touched?.contract?.affiliate_percent && errors?.contract?.affiliate_percent}
                />
              )}
            </Field>

            {values.contract.nft_type === "collection" && (
              <>
                <Box sx={{ mb: 4.5 }}>
                  <Field name="contract[limit_enable]">
                    {({ field }) => <FormControlLabel {...field} disabled={isSubmitting} control={<Checkbox />} label="Mint Limit per Wallet" />}
                  </Field>

                  {values.contract.limit_enable && (
                    <Field name="contract[max_mint_amount]">
                      {({ field }) => (
                        <TextInput
                          field={{ ...field, disabled: isSubmitting }}
                          sx={{ mb: 1 }}
                          error={touched?.contract?.max_mint_amount && Boolean(errors?.contract?.max_mint_amount)}
                          helperText={touched?.contract?.max_mint_amount && errors?.contract?.max_mint_amount}
                        />
                      )}
                    </Field>
                  )}
                  <Typography variant="helper.text" component="p">
                    Note: This lets you limit the quantity of NFTs that one wallet address can mint.
                  </Typography>
                </Box>

                <Box>
                  <Field name="contract[total_number]">
                    {({ field }) => (
                      <TextInput
                        labelText="Total Number of NFTs"
                        field={{ ...field, disabled: isSubmitting }}
                        error={touched?.contract?.total_number && Boolean(errors?.contract?.total_number)}
                        helperText="Note: Input the total number of NFTs in the collection, for example, 1000."
                      />
                    )}
                  </Field>
                </Box>
              </>
            )}

            {values.contract.nft_type === "single" && (
              <Field name="contract[single_type]">
                {({ field }) => (
                  <Box sx={{ mb: 4.5 }}>
                    <InputLabel
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        ml: 4.5,
                        color: "#ADADAD",
                      }}
                    >
                      Number of Tokens
                    </InputLabel>

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        position: "relative",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "24px",
                        p: "20px 36px",
                        background: "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                        color: "#ADADAD",
                        borderRadius: 14,
                        ".MuiInputBase-input": {
                          p: 0,
                          "&::placeholder": {
                            color: "#ADADAD",
                          },
                          "&.Mui-disabled": {
                            textFillColor: "#ADADAD",
                          },
                        },
                        ".MuiInputAdornment-root": {
                          mr: 0,
                          color: "var(--default-text-color)",
                          ".MuiTypography-root": {
                            fontSize: "inherit",
                            color: "inherit",
                          },
                        },
                      }}
                    >
                      <SvgIcon
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={null}
                        sx={{
                          fill: "none",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          pointerEvents: "none",
                          overflow: "visible",
                          zIndex: 10,
                        }}
                      >
                        <rect x="0.5" y="0.5" width="100%" height="100%" rx="31.5" stroke="white" />
                      </SvgIcon>

                      <Select
                        {...field}
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          width: "100%",
                          "& > fieldset": {
                            display: "none",
                          },
                          ".MuiSelect-select": {
                            color: "#ADADAD",
                            fontSize: 16,
                            lineHeight: "24px",
                          },
                          ".MuiSvgIcon-root": {
                            width: "1em",
                            height: "1em",
                            color: "#ADADAD",
                          },
                        }}
                      >
                        <MenuItem value="one">One</MenuItem>
                        <MenuItem value="unlimited">Unlimited</MenuItem>
                        <MenuItem value="limited">Limited</MenuItem>
                      </Select>
                    </Box>

                    <Typography variant="helper.text" component="p" sx={{ mt: 1 }}>
                      Note: <strong>One</strong> - NFT can be minted just once. <strong>Unlimited</strong> - unlimited number of tokens can be created.{" "}
                      <strong>Limited</strong> - limit the number of times NFT can be minted.
                    </Typography>
                  </Box>
                )}
              </Field>
            )}

            {values.contract.nft_type === "single" && values.contract.single_type === "limited" && (
              <Box>
                <Field name="contract[total_number]">
                  {({ field }) => (
                    <TextInput
                      labelText="Total Number of NFTs"
                      field={{ ...field, disabled: isSubmitting }}
                      error={touched?.contract?.total_number && Boolean(errors?.contract?.total_number)}
                      helperText="Note: Input the total number of NFTs that can be created, for example, 1000."
                    />
                  )}
                </Field>
              </Box>
            )}

            <Box sx={{ display: "flex", justifyContent: "center", mt: 6.875 }}>
              <Button variant="default" disabled={isSubmitting} onClick={() => backStepCallback()} sx={{ mx: 1.75 }}>
                Back
              </Button>

              <Button type="submit" variant="default" disabled={Object.keys(errors).length > 0 || isSubmitting} sx={{ mx: 1.75 }}>
                Create
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Step3;
