import { createSlice } from "@reduxjs/toolkit";

export const user = createSlice({
  name: "user",
  initialState: {
    auth: false,
    uid: null,
    apikey: false,
    wallet_address: false,
    developer_mode: false,
    settings: {
      page_limit: 10,
    },
    billing: {
      tx: [],
      widget: 0,
      hosting: 0,
    },
    uploads: [],
  },
  reducers: {
    signIn: (state, action) => {
      return { ...state, ...action.payload };
    },
    logout: (state) => {
      window.localStorage.removeItem("apikey");

      return {
        ...state,
        auth: false,
        uid: null,
        apikey: false,
        wallet_address: false,
        developer_mode: false,
        billing: {
          tx: [],
          widget: 0,
          hosting: 0,
        },
      };
    },
    updBilling: (state, action) => {
      return { ...state, billing: action.payload };
    },
    uploadFile: (state, action) => {
      state.uploads = [action.payload, ...state.uploads];
    },
    removeFile: (state, action) => {
      state.uploads = state.uploads.filter((item) => item.key !== action.payload);
    },
  },
});

export const { signIn, logout, updBilling, uploadFile, removeFile } = user.actions;
export const getUserData = (state) => state?.user;
export default user.reducer;
