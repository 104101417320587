import { useTheme } from "@mui/material";

export default function Logo() {
  const { palette } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="28"
      viewBox="0 0 128 28"
      className="logo"
    >
      <path
        fill={palette.text.primary}
        d="M6.1,27.309a2.1,2.1,0,0,0,1.542-.641,2.037,2.037,0,0,0,.641-1.521V16.218a4.067,4.067,0,0,1,4.044-4.064h1.221a2.153,2.153,0,0,0,2.162-2.162,2.037,2.037,0,0,0-.641-1.521,2.08,2.08,0,0,0-1.521-.641H12.323A8.078,8.078,0,0,0,6.4,10.293a8.078,8.078,0,0,0-2.462,5.926v8.929a2.035,2.035,0,0,0,.641,1.521,2.036,2.036,0,0,0,1.521.641h0Z"
      />
      <path
        fill={palette.text.primary}
        d="M21.772,15.4a4.018,4.018,0,0,1,1.061-2.242,3.3,3.3,0,0,1,2.462-.981h0.06a3.3,3.3,0,0,1,2.462.981A4.02,4.02,0,0,1,28.879,15.4H21.772Zm4.044,11.912a8.353,8.353,0,0,0,3.784-.9,8.264,8.264,0,0,0,2.963-2.5,2.037,2.037,0,0,0,.4-1.6,2.075,2.075,0,0,0-.841-1.441,2.181,2.181,0,0,0-1.622-.4,2.136,2.136,0,0,0-1.421.861,3.864,3.864,0,0,1-3.263,1.642h-0.06a3.923,3.923,0,0,1-2.562-.921,3.833,3.833,0,0,1-1.4-2.3h9.329a2.1,2.1,0,0,0,1.541-.641,2.137,2.137,0,0,0,.621-1.541V16.218a8.394,8.394,0,0,0-2.262-5.986,7.458,7.458,0,0,0-5.666-2.4H25.3a7.5,7.5,0,0,0-5.666,2.4,8.393,8.393,0,0,0-2.262,5.986v2.7a8.078,8.078,0,0,0,2.462,5.926,8.078,8.078,0,0,0,5.926,2.462h0.06Z"
      />
      <path
        fill={palette.text.default}
        d="M55.685,7.89a7.545,7.545,0,0,1,5.625,2.342,7.793,7.793,0,0,1,2.3,5.706v9.209a2.162,2.162,0,0,1-4.324,0V15.938a3.644,3.644,0,0,0-1.021-2.663,3.432,3.432,0,0,0-2.582-1.061h-0.06a3.432,3.432,0,0,0-2.583,1.061,3.644,3.644,0,0,0-1.021,2.663v9.209a2.153,2.153,0,0,1-2.142,2.162,2.1,2.1,0,0,1-1.541-.641,2.068,2.068,0,0,1-.621-1.521V15.938A3.644,3.644,0,0,0,46.7,13.276a3.432,3.432,0,0,0-2.582-1.061h-0.06a3.432,3.432,0,0,0-2.583,1.061,3.644,3.644,0,0,0-1.021,2.663v9.209a2.162,2.162,0,0,1-4.324,0V15.938a7.791,7.791,0,0,1,2.3-5.706A7.545,7.545,0,0,1,44.054,7.89h0.06a7.576,7.576,0,0,1,5.766,2.482A7.512,7.512,0,0,1,55.625,7.89h0.06Z"
      />
      <path
        fill={palette.text.default}
        d="M68.818,27.309a2.093,2.093,0,0,0,1.561-.661,2.115,2.115,0,0,0,.661-1.581V9.472A2.158,2.158,0,0,0,70.38,7.89a2.093,2.093,0,0,0-1.561-.661,2.115,2.115,0,0,0-1.581.661,2.158,2.158,0,0,0-.661,1.582v15.6a2.256,2.256,0,0,0,2.242,2.242h0Z"
      />
      <path
        fill={palette.text.primary}
        d="M68.818,0.823a2.126,2.126,0,0,1,1.561.641,2.158,2.158,0,0,1,.661,1.582,2.158,2.158,0,0,1-.661,1.582,2.125,2.125,0,0,1-1.561.641,2.147,2.147,0,0,1-1.581-.641,2.157,2.157,0,0,1-.661-1.582,2.158,2.158,0,0,1,.661-1.582A2.148,2.148,0,0,1,68.818.823h0Z"
      />
      <path
        fill={palette.text.default}
        d="M90.259,27.309a2.079,2.079,0,0,0,1.522-.641,2.036,2.036,0,0,0,.641-1.521V16.238a8.079,8.079,0,0,0-2.462-5.926A8.078,8.078,0,0,0,84.033,7.85h-0.06a8.078,8.078,0,0,0-5.926,2.462,8.079,8.079,0,0,0-2.462,5.926v8.909a2.188,2.188,0,0,0,2.162,2.162,2.1,2.1,0,0,0,1.541-.641,2.07,2.07,0,0,0,.621-1.521V16.238a4.1,4.1,0,0,1,4.064-4.064h0.06a4.067,4.067,0,0,1,4.044,4.064v8.909a2.035,2.035,0,0,0,.641,1.521,2.1,2.1,0,0,0,1.541.641h0Z"
      />
      <path
        fill={palette.text.default}
        d="M104.373,23.085a2.046,2.046,0,0,1,1.5.621,2.022,2.022,0,0,1,.621,1.481,2.12,2.12,0,0,1-2.122,2.122h-1.161a8.2,8.2,0,0,1-8.188-8.188V2.905a2.045,2.045,0,0,1,.621-1.5,2.079,2.079,0,0,1,1.5-.6,2.085,2.085,0,0,1,2.1,2.1V8.171h2.8a2.118,2.118,0,0,1,2.122,2.122,2.024,2.024,0,0,1-.62,1.481,2.045,2.045,0,0,1-1.5.621h-2.8v6.727a3.965,3.965,0,0,0,3.964,3.964h1.161Z"
      />
      <path
        fill={palette.text.default}
        d="M116.605,12.195a4.1,4.1,0,0,1,4.064,4.064v2.683a4.068,4.068,0,0,1-4.064,4.044h-0.06a4.033,4.033,0,0,1-4.044-4.044V16.259a4.067,4.067,0,0,1,4.044-4.064h0.06Zm0,15.115a8.408,8.408,0,0,0,8.388-8.368V16.259a8.078,8.078,0,0,0-2.463-5.926,8.077,8.077,0,0,0-5.925-2.462h-0.06a8.011,8.011,0,0,0-5.906,2.462,8.078,8.078,0,0,0-2.463,5.926v2.683a8.011,8.011,0,0,0,2.463,5.906,8.011,8.011,0,0,0,5.906,2.462h0.06Z"
      />
    </svg>
  );
}
