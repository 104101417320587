import RESTAPI from "../";

export default class BILLING {
  static async GetList() {
    try {
      const { data } = await RESTAPI.axios.get(`api/billing`);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async Add({ hash }) {
    try {
      const { data } = await RESTAPI.axios.post(`api/billing`, { hash });
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }
}
