import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BigNumber, utils } from "ethers";
import { Field, Form, Formik } from "formik";
import moment from "moment";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import SvgIcon from "@mui/material/SvgIcon";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { Billing as _Billing, NETWORKS } from "../../config";
import { Caption } from "../";
import { updBilling } from "../../config/slices/user";
import { BILLING } from "../../config/RESTAPI/";

import IconWallet from "../../images/icon-wallet.png";
import IconBolt from "../../images/icon-bolt.png";

const Billing = () => {
  const {
    user: { developer_mode },
  } = useSelector((state) => state);

  const [costs, setCosts] = useState([]);
  const [cost, setCost] = useState(BigNumber.from(0));
  const [error, setError] = useState(false);

  const [paymentError, setPaymentError] = useState(false);

  useEffect(() => {
    _Billing
      .getCosts(developer_mode)
      .then((costs) => {
        setCosts(costs);
        setCost(costs[2]);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  }, [developer_mode]);

  const [disabledPay, setDisabledPay] = useState(false);
  const dispatch = useDispatch();

  const { billing } = useSelector((state) => state.user);
  const { widget, hosting, tx } = billing;

  const handles = {
    submit: async (values, actions) => {
      setPaymentError(false);

      const { result, message } = await _Billing.pay({
        order: values,
        amount: cost,
      });

      if (!result) {
        setPaymentError(message);
        return false;
      }

      const { status, data } = await BILLING.GetList();
      if (status === 200) {
        dispatch(updBilling({ ...data }));
      }

      actions.setSubmitting(false);
    },
    change: (type, action) => {
      switch (action) {
        case "add":
          setCost(cost.add(costs[type]));
          break;

        case "remove":
          setCost(cost.sub(costs[type]));
          break;

        default:
          break;
      }
    },
  };

  const initialValues = {
    widget: false,
    hosting: true,
    widget_count: 1,
    hosting_count: 1,
  };

  if (error) {
    return (
      <Box sx={{ px: 2, py: 3 }}>
        <Alert severity="error">
          Oups, we have a little problem. Please try reloading the page in a
          minute.
        </Alert>
      </Box>
    );
  }

  if (costs.length === 0) {
    return null;
  }

  return (
    <>
      <Caption Icon={<img src={IconWallet} alt="" />} title="Billing" />

      <Alert
        severity="warning"
        sx={{
          mb: 6,
          py: 1.6875,
          px: 3.5,
          ".MuiAlert-icon": {
            mr: 2.5,
            ".MuiSvgIcon-root": {
              width: 24,
              height: 24,
            },
          },
          ".MuiAlert-message": {
            color: "var(--default-text-color)",
            fontWeight: 500,
          },
        }}
      >
        Available for creation: {(+widget || 0) + (+hosting || 0)} campaigns
      </Alert>

      {paymentError && (
        <Alert
          severity="error"
          sx={{
            mb: 6,
            py: 1.6875,
            px: 3.5,
            ".MuiAlert-icon": {
              mr: 2.5,
              ".MuiSvgIcon-root": {
                width: 24,
                height: 24,
              },
            },
            ".MuiAlert-message": {
              color: "var(--default-text-color)",
              fontWeight: 500,
            },
          }}
        >
          {paymentError}
        </Alert>
      )}

      <Box
        sx={{
          maxWidth: 792,
          display: "flex",
          flexDirection: "column",
          m: "auto",
          mb: 10.5,
        }}
      >
        <Paper variant="elevation2">
          <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={null}
            sx={{
              fill: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "visible",
              pointerEvents: "none",
            }}
          >
            <rect
              x="0.5"
              y="0.5"
              width="100%"
              height="100%"
              rx="55.5"
              stroke="url(#paint1_linear_979_1988)"
              strokeOpacity="0.3"
            />
            <linearGradient
              id="paint1_linear_979_1988"
              x1="234.068"
              y1="-16.1879"
              x2="652.316"
              y2="402.585"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </SvgIcon>

          <Formik initialValues={initialValues} onSubmit={handles.submit}>
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 22,
                    lineHeight: "33px",
                    color: "var(--default-text-color)",
                  }}
                >
                  I would like to create an affiliate campaign
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ml: 2,
                    mr: 2,
                    mb: 4.5,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ mr: 6 }}>
                      <Field name="widget">
                        {({ field }) => (
                          <FormControlLabel
                            sx={{
                              ".MuiCheckbox-root": {
                                ml: 0,
                              },
                            }}
                            {...field}
                            disabled={isSubmitting || true}
                            control={
                              <Checkbox
                                // defaultChecked
                                onChange={(e) => {
                                  field.onChange(e);

                                  const newCost = costs[1].mul(
                                    values.widget_count
                                  );
                                  setCost(
                                    values.widget
                                      ? cost.sub(newCost)
                                      : cost.add(newCost)
                                  );

                                  setDisabledPay(
                                    !Boolean(!values.widget | values.hosting)
                                  );
                                }}
                              />
                            }
                            value={true}
                            label={`and run it on REminto Marketplace for ${utils.formatEther(
                              costs[1]
                            )} ETH`}
                          />
                        )}
                      </Field>
                      <Typography variant="caption" component={"p"}>
                        Note: this will let you create a smart contract for your
                        NFT(s) and promote your campaign on REminto marketplace.
                        You will also get a Mint widget you could optionally
                        host on your own website.
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        ml: "auto",
                        position: "relative",
                        minWidth: 174,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SvgIcon
                        viewBox={null}
                        sx={{
                          position: "absolute",
                          fill: "none",
                          left: 0,
                          top: 0,
                          width: "100%",
                          height: "100%",
                          overflow: "visible",
                        }}
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="100%"
                          height="100%"
                          rx="23.5"
                          stroke="url(#paint0_linear_1138_3946)"
                        />
                      </SvgIcon>

                      <IconButton
                        disabled={!values.widget || isSubmitting}
                        onClick={() =>
                          setFieldValue(
                            "widget_count",
                            values.widget_count + 1
                          ) && handles.change(1, "add")
                        }
                      >
                        <SvgIcon
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          sx={{ fill: "var(--default-text-color)" }}
                        >
                          <path d="M11.1429 6.85714H6.85714V11.1429C6.85714 11.3702 6.76684 11.5882 6.60609 11.7489C6.44535 11.9097 6.22733 12 6 12C5.77267 12 5.55465 11.9097 5.39391 11.7489C5.23316 11.5882 5.14286 11.3702 5.14286 11.1429V6.85714H0.857143C0.629814 6.85714 0.411797 6.76684 0.251052 6.60609C0.090306 6.44535 0 6.22733 0 6C0 5.77267 0.090306 5.55465 0.251052 5.39391C0.411797 5.23316 0.629814 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.629814 5.23316 0.411797 5.39391 0.251051C5.55465 0.0903056 5.77267 0 6 0C6.22733 0 6.44535 0.0903056 6.60609 0.251051C6.76684 0.411797 6.85714 0.629814 6.85714 0.857143V5.14286H11.1429C11.3702 5.14286 11.5882 5.23316 11.749 5.39391C11.9097 5.55465 12 5.77267 12 6C12 6.22733 11.9097 6.44535 11.749 6.60609C11.5882 6.76684 11.3702 6.85714 11.1429 6.85714Z" />
                        </SvgIcon>
                      </IconButton>

                      <Field name="widget_count">
                        {({ field }) => {
                          return (
                            <Input
                              inputProps={{
                                readOnly: true,
                              }}
                              disableUnderline={true}
                              sx={{
                                width: 50,
                                "& > input": {
                                  textAlign: "center",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: 16,
                                  lineHeight: "12px",
                                  color: "var(--default-text-color)",
                                  height: 48,
                                  p: 0,
                                  "&.Mui-disabled": {
                                    opacity: 0.3,
                                    textFillColor: "var(--default-text-color)",
                                  },
                                },
                              }}
                              readOnly={true}
                              disabled={!values.widget || isSubmitting}
                              {...field}
                            />
                          );
                        }}
                      </Field>

                      <IconButton
                        disabled={!values.widget || isSubmitting}
                        onClick={() =>
                          values.widget_count > 1 &&
                          setFieldValue(
                            "widget_count",
                            values.widget_count - 1
                          ) &&
                          handles.change(1, "remove")
                        }
                      >
                        <SvgIcon
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          sx={{ fill: "var(--default-text-color)" }}
                        >
                          <path d="M11.1429 6.85735H0.857143C0.629814 6.85735 0.411797 6.76705 0.251051 6.6063C0.090306 6.44556 0 6.22754 0 6.00021C0 5.77288 0.090306 5.55486 0.251051 5.39412C0.411797 5.23337 0.629814 5.14307 0.857143 5.14307H11.1429C11.3702 5.14307 11.5882 5.23337 11.7489 5.39412C11.9097 5.55486 12 5.77288 12 6.00021C12 6.22754 11.9097 6.44556 11.7489 6.6063C11.5882 6.76705 11.3702 6.85735 11.1429 6.85735Z" />
                        </SvgIcon>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ml: 2,
                    mr: 2,
                    mb: 5.875,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ mr: 6 }}>
                      <Field name="hosting">
                        {({ field }) => (
                          <FormControlLabel
                            sx={{
                              ".MuiCheckbox-root": {
                                ml: 0,
                              },
                            }}
                            {...field}
                            disabled={isSubmitting}
                            control={
                              <Checkbox
                                defaultChecked
                                onChange={(e) => {
                                  field.onChange(e);

                                  const newCost = costs[2].mul(
                                    values.hosting_count
                                  );
                                  setCost(
                                    values.hosting
                                      ? cost.sub(newCost)
                                      : cost.add(newCost)
                                  );

                                  setDisabledPay(
                                    !Boolean(values.widget | !values.hosting)
                                  );
                                }}
                              />
                            }
                            label={`and create a custom hosted Mint page for ${utils.formatEther(
                              costs[2]
                            )} ETH`}
                          />
                        )}
                      </Field>

                      <Typography variant="caption" component="p">
                        Note: you will be able to show your campaign on REminto
                        marketplace and create a custom Mint page with REminto
                        Builder. Your page will be hosted at
                        https://nftulla.com/
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        ml: "auto",
                        position: "relative",
                        minWidth: 174,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SvgIcon
                        viewBox={null}
                        sx={{
                          position: "absolute",
                          fill: "none",
                          left: 0,
                          top: 0,
                          width: "100%",
                          height: "100%",
                          overflow: "visible",
                        }}
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="100%"
                          height="100%"
                          rx="23.5"
                          stroke="url(#paint0_linear_1138_3946)"
                        />
                      </SvgIcon>

                      <IconButton
                        disabled={!values.hosting || isSubmitting}
                        onClick={() =>
                          setFieldValue(
                            "hosting_count",
                            values.hosting_count + 1
                          ) && handles.change(2, "add")
                        }
                      >
                        <SvgIcon
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          sx={{ fill: "var(--default-text-color)" }}
                        >
                          <path d="M11.1429 6.85714H6.85714V11.1429C6.85714 11.3702 6.76684 11.5882 6.60609 11.7489C6.44535 11.9097 6.22733 12 6 12C5.77267 12 5.55465 11.9097 5.39391 11.7489C5.23316 11.5882 5.14286 11.3702 5.14286 11.1429V6.85714H0.857143C0.629814 6.85714 0.411797 6.76684 0.251052 6.60609C0.090306 6.44535 0 6.22733 0 6C0 5.77267 0.090306 5.55465 0.251052 5.39391C0.411797 5.23316 0.629814 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.629814 5.23316 0.411797 5.39391 0.251051C5.55465 0.0903056 5.77267 0 6 0C6.22733 0 6.44535 0.0903056 6.60609 0.251051C6.76684 0.411797 6.85714 0.629814 6.85714 0.857143V5.14286H11.1429C11.3702 5.14286 11.5882 5.23316 11.749 5.39391C11.9097 5.55465 12 5.77267 12 6C12 6.22733 11.9097 6.44535 11.749 6.60609C11.5882 6.76684 11.3702 6.85714 11.1429 6.85714Z" />
                        </SvgIcon>
                      </IconButton>

                      <Field name="hosting_count">
                        {({ field }) => {
                          return (
                            <Input
                              inputProps={{
                                readOnly: true,
                              }}
                              disableUnderline={true}
                              sx={{
                                width: 50,
                                "& > input": {
                                  textAlign: "center",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: 16,
                                  lineHeight: "12px",
                                  color: "var(--default-text-color)",
                                  height: 48,
                                  p: 0,
                                  "&.Mui-disabled": {
                                    opacity: 0.3,
                                    textFillColor: "var(--default-text-color)",
                                  },
                                },
                              }}
                              readOnly={true}
                              disabled={!values.hosting || isSubmitting}
                              {...field}
                            />
                          );
                        }}
                      </Field>

                      <IconButton
                        disabled={!values.hosting || isSubmitting}
                        onClick={() =>
                          values.hosting_count > 1 &&
                          setFieldValue(
                            "hosting_count",
                            values.hosting_count - 1
                          ) &&
                          handles.change(2, "remove")
                        }
                      >
                        <SvgIcon
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          sx={{ fill: "var(--default-text-color)" }}
                        >
                          <path d="M11.1429 6.85735H0.857143C0.629814 6.85735 0.411797 6.76705 0.251051 6.6063C0.090306 6.44556 0 6.22754 0 6.00021C0 5.77288 0.090306 5.55486 0.251051 5.39412C0.411797 5.23337 0.629814 5.14307 0.857143 5.14307H11.1429C11.3702 5.14307 11.5882 5.23337 11.7489 5.39412C11.9097 5.55486 12 5.77288 12 6.00021C12 6.22754 11.9097 6.44556 11.7489 6.6063C11.5882 6.76705 11.3702 6.85735 11.1429 6.85735Z" />
                        </SvgIcon>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ ml: 3.5 }}>
                  <Button
                    variant="default"
                    type="submit"
                    disableRipple
                    disabled={disabledPay || isSubmitting}
                    sx={{
                      px: 6,
                      py: 3.1875,
                      fontSize: 16,
                      lineHeight: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Pay {utils.formatEther(cost)} ETH
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>

      <Box>
        <Caption
          Icon={<img src={IconBolt} alt="" />}
          title="Transaction Lists"
        />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>Amount, ETH</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tx.map(({ transaction_hash, status, value, createdAt }) => (
              <TableRow key={transaction_hash}>
                <TableCell>
                  <Typography variant="billing.text">
                    {moment(createdAt).format("LL")}
                    <br />
                    {moment(createdAt).format("LT")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="billing.text"
                    component="a"
                    href={`${NETWORKS[1].TX_URL}${transaction_hash}`}
                    target="_blank"
                  >
                    {`${transaction_hash.substring(0, 13)}...`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="billing.text">
                    {utils.formatEther(BigNumber.from(value))} ETH
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="billing.text">
                    {Boolean(status) ? "Completed" : "Terminated"}

                    <Typography
                      variant={
                        Boolean(status) ? "success.round" : "error.round"
                      }
                      component="span"
                    ></Typography>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Billing;
