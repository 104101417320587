import PropTypes from "prop-types";
import loadable from "@loadable/component";
import Typography from "@mui/material/Typography";

const LoadableComponent = loadable(() => import("../../Elements"), {
  resolveComponent: (components, props) => components[props.component],
});

export default function CustomField({ name, label, fields }) {
  const _fields = Object.keys(fields);

  return (
    <>
      <Typography>{label}</Typography>

      {_fields.map((field) => (
        <LoadableComponent
          key={`${name}.${field}`}
          name={`${name}.${field}`}
          {...fields[field]}
        />
      ))}
    </>
  );
}

CustomField.defaultProps = {};

CustomField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};
