import { createSlice } from "@reduxjs/toolkit";

export const contractSlice = createSlice({
  name: "contract",
  initialState: {
    // campaign_name: "Test Campaign",
    // address: "0x189e5930FB9D01a7A1dbd0bf8b0b18Fd6a2f05Fa",
    // networkId: 5,
    // nft_type: "collection",
    // limit_enable: false,
    // total_enable: false,
    // abi: [
    //   { type: "constructor", inputs: [], stateMutability: "nonpayable" },
    //   {
    //     name: "Approval",
    //     type: "event",
    //     inputs: [
    //       {
    //         name: "owner",
    //         type: "address",
    //         indexed: true,
    //         internalType: "address",
    //       },
    //       {
    //         name: "approved",
    //         type: "address",
    //         indexed: true,
    //         internalType: "address",
    //       },
    //       {
    //         name: "tokenId",
    //         type: "uint256",
    //         indexed: true,
    //         internalType: "uint256",
    //       },
    //     ],
    //     anonymous: false,
    //   },
    //   {
    //     name: "ApprovalForAll",
    //     type: "event",
    //     inputs: [
    //       {
    //         name: "owner",
    //         type: "address",
    //         indexed: true,
    //         internalType: "address",
    //       },
    //       {
    //         name: "operator",
    //         type: "address",
    //         indexed: true,
    //         internalType: "address",
    //       },
    //       {
    //         name: "approved",
    //         type: "bool",
    //         indexed: false,
    //         internalType: "bool",
    //       },
    //     ],
    //     anonymous: false,
    //   },
    //   {
    //     name: "OwnershipTransferred",
    //     type: "event",
    //     inputs: [
    //       {
    //         name: "previousOwner",
    //         type: "address",
    //         indexed: true,
    //         internalType: "address",
    //       },
    //       {
    //         name: "newOwner",
    //         type: "address",
    //         indexed: true,
    //         internalType: "address",
    //       },
    //     ],
    //     anonymous: false,
    //   },
    //   {
    //     name: "Paused",
    //     type: "event",
    //     inputs: [
    //       {
    //         name: "account",
    //         type: "address",
    //         indexed: false,
    //         internalType: "address",
    //       },
    //     ],
    //     anonymous: false,
    //   },
    //   {
    //     name: "Transfer",
    //     type: "event",
    //     inputs: [
    //       {
    //         name: "from",
    //         type: "address",
    //         indexed: true,
    //         internalType: "address",
    //       },
    //       {
    //         name: "to",
    //         type: "address",
    //         indexed: true,
    //         internalType: "address",
    //       },
    //       {
    //         name: "tokenId",
    //         type: "uint256",
    //         indexed: true,
    //         internalType: "uint256",
    //       },
    //     ],
    //     anonymous: false,
    //   },
    //   {
    //     name: "Unpaused",
    //     type: "event",
    //     inputs: [
    //       {
    //         name: "account",
    //         type: "address",
    //         indexed: false,
    //         internalType: "address",
    //       },
    //     ],
    //     anonymous: false,
    //   },
    //   {
    //     name: "approve",
    //     type: "function",
    //     inputs: [
    //       { name: "to", type: "address", internalType: "address" },
    //       { name: "tokenId", type: "uint256", internalType: "uint256" },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "balanceOf",
    //     type: "function",
    //     inputs: [{ name: "owner", type: "address", internalType: "address" }],
    //     outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "cost",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "getApproved",
    //     type: "function",
    //     inputs: [{ name: "tokenId", type: "uint256", internalType: "uint256" }],
    //     outputs: [{ name: "", type: "address", internalType: "address" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "hiddenMetadataUri",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "string", internalType: "string" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "isApprovedForAll",
    //     type: "function",
    //     inputs: [
    //       { name: "owner", type: "address", internalType: "address" },
    //       { name: "operator", type: "address", internalType: "address" },
    //     ],
    //     outputs: [{ name: "", type: "bool", internalType: "bool" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "mint",
    //     type: "function",
    //     inputs: [
    //       { name: "_mintAmount", type: "uint256", internalType: "uint256" },
    //       {
    //         name: "_affiliateWallet",
    //         type: "address",
    //         internalType: "address",
    //       },
    //     ],
    //     outputs: [],
    //     stateMutability: "payable",
    //   },
    //   {
    //     name: "name",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "string", internalType: "string" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "owner",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "address", internalType: "address" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "ownerOf",
    //     type: "function",
    //     inputs: [{ name: "tokenId", type: "uint256", internalType: "uint256" }],
    //     outputs: [{ name: "", type: "address", internalType: "address" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "pause",
    //     type: "function",
    //     inputs: [],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "paused",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "bool", internalType: "bool" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "renounceOwnership",
    //     type: "function",
    //     inputs: [],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "revealed",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "bool", internalType: "bool" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "safeTransferFrom",
    //     type: "function",
    //     inputs: [
    //       { name: "from", type: "address", internalType: "address" },
    //       { name: "to", type: "address", internalType: "address" },
    //       { name: "tokenId", type: "uint256", internalType: "uint256" },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "safeTransferFrom",
    //     type: "function",
    //     inputs: [
    //       { name: "from", type: "address", internalType: "address" },
    //       { name: "to", type: "address", internalType: "address" },
    //       { name: "tokenId", type: "uint256", internalType: "uint256" },
    //       { name: "data", type: "bytes", internalType: "bytes" },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "setApprovalForAll",
    //     type: "function",
    //     inputs: [
    //       { name: "operator", type: "address", internalType: "address" },
    //       { name: "approved", type: "bool", internalType: "bool" },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "setCost",
    //     type: "function",
    //     inputs: [{ name: "_cost", type: "uint256", internalType: "uint256" }],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "setHiddenMetadataUri",
    //     type: "function",
    //     inputs: [
    //       {
    //         name: "_hiddenMetadataUri",
    //         type: "string",
    //         internalType: "string",
    //       },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "setRevealed",
    //     type: "function",
    //     inputs: [{ name: "_state", type: "bool", internalType: "bool" }],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "setUriPrefix",
    //     type: "function",
    //     inputs: [
    //       { name: "_uriPrefix", type: "string", internalType: "string" },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "setUriSuffix",
    //     type: "function",
    //     inputs: [
    //       { name: "_uriSuffix", type: "string", internalType: "string" },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "supportsInterface",
    //     type: "function",
    //     inputs: [
    //       { name: "interfaceId", type: "bytes4", internalType: "bytes4" },
    //     ],
    //     outputs: [{ name: "", type: "bool", internalType: "bool" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "symbol",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "string", internalType: "string" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "tokenURI",
    //     type: "function",
    //     inputs: [
    //       { name: "_tokenId", type: "uint256", internalType: "uint256" },
    //     ],
    //     outputs: [{ name: "", type: "string", internalType: "string" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "totalSupply",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "transferFrom",
    //     type: "function",
    //     inputs: [
    //       { name: "from", type: "address", internalType: "address" },
    //       { name: "to", type: "address", internalType: "address" },
    //       { name: "tokenId", type: "uint256", internalType: "uint256" },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "transferOwnership",
    //     type: "function",
    //     inputs: [
    //       { name: "newOwner", type: "address", internalType: "address" },
    //     ],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "unpause",
    //     type: "function",
    //     inputs: [],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    //   {
    //     name: "uriPrefix",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "string", internalType: "string" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "uriSuffix",
    //     type: "function",
    //     inputs: [],
    //     outputs: [{ name: "", type: "string", internalType: "string" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "walletOfOwner",
    //     type: "function",
    //     inputs: [{ name: "_owner", type: "address", internalType: "address" }],
    //     outputs: [{ name: "", type: "uint256[]", internalType: "uint256[]" }],
    //     stateMutability: "view",
    //   },
    //   {
    //     name: "withdraw",
    //     type: "function",
    //     inputs: [],
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //   },
    // ],
  },

  reducers: {
    loadContractData: (_, action) => {
      return { ...action.payload };
    },
  },
});

export const { loadContractData } = contractSlice.actions;

export default contractSlice.reducer;
