import { Field } from "react-final-form";
import PropTypes from "prop-types";
import MuiTextField from "@mui/material/TextField";

export default function TextField({ name, type = "text", label, helperText }) {
  return (
    <div className="mb-2">
      <Field name={name}>
        {({ input }) => (
          <MuiTextField
            label={label}
            fullWidth
            type={type}
            {...input}
            sx={{ mb: 2 }}
            helperText={helperText}
          />
        )}
      </Field>
    </div>
  );
}

TextField.defaultProps = {
  helperText: null,
};

TextField.propTypes = {
  type: PropTypes.oneOf(["text", "password", "email"]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
