import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { saveChanges } from "../../../../config/slices/builder";
import { Elements } from "../";

const Field = ({ component, props }) => {
  const Component = Elements[component];
  return <Component {...props} />;
};

export default function FormEl() {
  const dispatch = useDispatch();
  const { editableComponent, components } = useSelector(
    (state) => state.builder
  );
  const { fields } = components[editableComponent];
  const initialValues = {};

  fields.forEach(
    ({ name }) =>
      (initialValues[name] = components[editableComponent].property[name])
  );

  return (
    <Form
      onSubmit={(values) => dispatch(saveChanges(values))}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          {fields.map(({ name, type, props }) => (
            <Field component={type} key={name} props={props} />
          ))}

          <Box
            sx={{
              display: "flex",
              margin: "1rem 0",
              justifyContent: "center",
            }}
          >
            <Button type="submit" disabled={pristine} variant="outlined">
              Save Changes
            </Button>
          </Box>
        </form>
      )}
    ></Form>
  );
}
