import { Field } from "react-final-form";
import PropTypes from "prop-types";
import MuiTextField from "@mui/material/TextField";

export default function TextArea({ name, label, helperText }) {
  return (
    <div className="mb-2">
      <Field name={name}>
        {({ input }) => (
          <MuiTextField
            label={label}
            multiline
            fullWidth
            rows={5}
            sx={{ mb: 2 }}
            {...input}
            helperText={helperText}
          />
        )}
      </Field>
    </div>
  );
}

TextArea.defaultProps = {
  helperText: null,
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
