import { useSelector } from "react-redux";
import loadable from "@loadable/component";

import { WagmiConfig, createConfig } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";

import { NETWORKS, config as appConfig } from "../../../../config";
import styles from "./styles/Scott.module.scss";
import "./styles/index.scss";

const LoadableComponent = loadable(() => import(`./components`), {
  resolveComponent: (components, props) => components[props.component],
});

export default function Scott() {
  const { blocks, components } = useSelector((store) => store.builder);
  const chains = Object.keys(NETWORKS).map((ID) => NETWORKS[ID].CHAIN);

  const config = createConfig(
    getDefaultConfig({
      infuraId: appConfig.infuraId,

      appName: "Scott CRA",
      chains,
    })
  );

  return (
    <WagmiConfig config={config}>
      <ConnectKitProvider theme="auto">
        <div id="canvas" className={styles.Scott}>
          {blocks.map(
            (block) =>
              components[block].property.show && (
                <LoadableComponent key={block} component={block} />
              )
          )}
        </div>
      </ConnectKitProvider>
    </WagmiConfig>
  );
}
