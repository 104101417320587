import { Field } from "react-final-form";
import PropTypes from "prop-types";
import MuiTextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export default function Select({ name, helperText, label, items }) {
  return (
    <>
      <Field name={name}>
        {({ input }) => (
          <>
            <MuiTextField
              {...input}
              fullWidth
              select
              label={label}
              helperText={helperText}
            >
              {items.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </MuiTextField>
          </>
        )}
      </Field>
    </>
  );
}

Select.defaultProps = {
  helperText: null,
  items: [],
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
