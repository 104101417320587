import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Image from "mui-image";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternate from "@mui/icons-material/AddPhotoAlternate";
import { deepPurple } from "@mui/material/colors";

import { removeFile, uploadFile } from "../../config/slices/user";
import { UserImagesContext } from "./context";
import { IMAGE } from "../../config/RESTAPI/";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserImages() {
  const { uploads } = useSelector((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { open, handleClose, handleSelectImage } =
    useContext(UserImagesContext);

  const handleUpload = (e) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("image", e.currentTarget.files[0]);

    IMAGE.Upload(formData)
      .then(({ filename, key }) =>
        dispatch(uploadFile({ image: filename, key }))
      )
      .then(() => setLoading(false))
      .catch((e) => console.log(e));
  };

  const handleDelete = (img) => {
    IMAGE.Remove(img)
      .then(() => dispatch(removeFile(img)))
      .catch((e) => console.log(e));
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: deepPurple[500] }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Images
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, .5)",
            zIndex: 100,
            visibility: loading ? "visible" : "hidden",
          }}
        ></Box>

        <Grid container>
          <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
            <Box
              sx={{
                background: "rgba(0,0,0,.5)",
                display: "flex",
                flexGrow: 1,
                height: "100%",
              }}
              component="label"
              htmlFor="image"
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#ffffff",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                Upload Image
                <AddPhotoAlternate sx={{ fontSize: 150 }} />
              </Box>

              <InputBase
                sx={{ display: "none" }}
                type="file"
                inputProps={{ accept: "image/*", id: "image" }}
                onChange={handleUpload}
              />
            </Box>
          </Grid>

          {uploads.map(({ image, key }) => (
            <Grid item key={key} xl={3} lg={4} md={4} sm={6} xs={12}>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover .toolbar": {
                    visibility: "visible",
                    opacity: 1,
                  },
                }}
              >
                <Image
                  height={400}
                  duration={200}
                  src={image}
                  onClick={() => handleSelectImage(image)}
                />

                <Box
                  className="toolbar"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    p: "0.2rem 1rem",
                    opacity: 0,
                    display: "flex",
                    justifyContent: "end",
                    visibility: "hidden",
                    transition: "opacity linear .3s",
                    alignItems: "center",
                    color: "#ffffff",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      mr: "auto",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {key}
                  </Typography>

                  <IconButton color="error" onClick={() => handleDelete(key)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  );
}
