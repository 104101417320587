import { createSlice } from "@reduxjs/toolkit";

export const builderSlice = createSlice({
  name: "builder",
  initialState: {
    editableComponent: null,
    blocks: [],
    componentTree: [],
    components: {},
    activeTab: "layers",
    templates: [],
    usedTemplate: false,
    savedState: true,
    campaignId: null,
  },

  reducers: {
    toogleShowComponent: (state, action) => {
      state.components[action.payload].property.show =
        !state.components[action.payload].property.show;
      state.savedState = false;
    },
    changeEditableComponent: (state, action) => {
      state.editableComponent = action.payload;
    },
    saveChanges: (state, action) => {
      state.components[state.editableComponent].property = action.payload;
      state.savedState = false;
    },
    changeActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    loadingTemplates: (state, action) => {
      state.templates = action.payload;
    },
    usedTemplate: (state, action) => {
      state.usedTemplate = action.payload;
      const { blocks, componentTree, components } =
        state.templates[action.payload];

      state.blocks = blocks;
      state.componentTree = componentTree;
      state.components = components;
    },
    toggleSavedState: (state, _) => {
      state.savedState = !state.savedState;
    },
    initBuilder: (state, action) => {
      const { campaignId, usedTemplateId, blocks } = action.payload;

      state.campaignId = campaignId;

      if (usedTemplateId) {
        state.usedTemplate = state.templates.findIndex(
          ({ id }) => id === usedTemplateId
        );

        const template = Object.assign({}, state.templates[state.usedTemplate]);

        Object.keys(template.components).forEach((component) => {
          template.components[component].property = blocks[component].property;
        });

        state.blocks = template.blocks;
        state.componentTree = template.componentTree;
        state.components = template.components;
      }
    },
  },
});

export const {
  changeEditableComponent,
  toogleShowComponent,
  saveChanges,
  changeActiveTab,
  loadingTemplates,
  usedTemplate,
  toggleSavedState,
  initBuilder,
} = builderSlice.actions;

export default builderSlice.reducer;
