import RESTAPI from "../";

export default class PROFILE {
  static async GetData() {
    try {
      const { data } = await RESTAPI.axios.get("api/profile");
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async Save(values) {
    try {
      const { data } = await RESTAPI.axios.post("api/profile", { ...values });
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }
}
