import React, { useCallback, useMemo } from "react";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";

import { Button, Toolbar } from "./components";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import SvgIcon from "@mui/material/SvgIcon";

const LIST_TYPES = ["ul", "ol"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

export default function WYSIWYG({ onChange, value, disabled, name = "" }) {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <Box
      sx={{
        position: "relative",
        "&[disabled]::after": {
          content: `""`,
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 20,
        },
      }}
      disabled={disabled}
    >
      <InputLabel
        sx={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "21px",
          ml: 4.5,
          mb: "0.9375rem",
          color: "#ADADAD",
        }}
      >
        Description
      </InputLabel>

      <Box
        sx={{
          p: "20px 36px",
          mb: "2.25rem",
          background:
            "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
          borderRadius: 14,
          position: "relative",
        }}
      >
        <SvgIcon
          xmlns="http://www.w3.org/2000/svg"
          viewBox={null}
          sx={{
            fill: "none",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            overflow: "visible",
            zIndex: 10,
          }}
        >
          <rect
            x="0.5"
            y="0.5"
            width="100%"
            height="100%"
            rx="52.5"
            stroke="white"
          />

          <linearGradient
            id="paint1_linear_1041_192"
            x1="417.341"
            y1="-2.77012"
            x2="430.937"
            y2="139.071"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </SvgIcon>

        <Slate
          editor={editor}
          value={value}
          onChange={(value) => {
            const isAstChange = editor.operations.some(
              (op) => "set_selection" !== op.type
            );

            if (isAstChange) {
              onChange(value);
            }
          }}
        >
          <Toolbar>
            <MarkButton
              format="bold"
              Icon={
                <SvgIcon
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 9 10"
                  width="9"
                  height="10"
                >
                  <path d="M6.818 4.96C7.38733 5.08133 7.84467 5.366 8.19 5.814C8.53533 6.25267 8.708 6.75667 8.708 7.326C8.708 8.14733 8.41867 8.80067 7.84 9.286C7.27067 9.762 6.47267 10 5.446 10H0.868V0.172H5.292C6.29067 0.172 7.07 0.400666 7.63 0.857999C8.19933 1.31533 8.484 1.936 8.484 2.72C8.484 3.29867 8.33 3.77933 8.022 4.162C7.72333 4.54467 7.322 4.81067 6.818 4.96ZM3.262 4.148H4.83C5.222 4.148 5.52067 4.064 5.726 3.896C5.94067 3.71867 6.048 3.462 6.048 3.126C6.048 2.79 5.94067 2.53333 5.726 2.356C5.52067 2.17867 5.222 2.09 4.83 2.09H3.262V4.148ZM5.026 8.068C5.42733 8.068 5.73533 7.97933 5.95 7.802C6.174 7.61533 6.286 7.34933 6.286 7.004C6.286 6.65867 6.16933 6.388 5.936 6.192C5.712 5.996 5.39933 5.898 4.998 5.898H3.262V8.068H5.026Z" />
                </SvgIcon>
              }
            />
            <MarkButton
              format="italic"
              Icon={
                <SvgIcon
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="10"
                  viewBox="0 0 4 10"
                >
                  <path d="M3.374 0.242L1.638 10H0.364L2.1 0.242H3.374Z" />
                </SvgIcon>
              }
            />
            <MarkButton
              format="underline"
              Icon={
                <SvgIcon width="8" height="11" viewBox="0 0 8 11">
                  <path d="M1.632 0.269999V6.472C1.632 7.20933 1.82333 7.76467 2.206 8.138C2.598 8.51133 3.13933 8.698 3.83 8.698C4.53 8.698 5.07133 8.51133 5.454 8.138C5.846 7.76467 6.042 7.20933 6.042 6.472V0.269999H7.638V6.444C7.638 7.23733 7.46533 7.90933 7.12 8.46C6.77467 9.01067 6.31267 9.42133 5.734 9.692C5.15533 9.96267 4.516 10.098 3.816 10.098C3.116 10.098 2.47667 9.96267 1.898 9.692C1.32867 9.42133 0.876 9.01067 0.54 8.46C0.204 7.90933 0.036 7.23733 0.036 6.444V0.269999H1.632Z" />
                  <path d="M-1 11.4H8.66V12.1H-1V11.4Z" />
                </SvgIcon>
              }
            />

            <MarkButton
              format="code"
              Icon={
                <SvgIcon width="14" height="8" viewBox="0 0 14 8">
                  <path d="M3.55 7.096L0.176 3.876L3.55 0.67H5.16L1.772 3.876L5.16 7.096H3.55ZM8.08163 0.67H9.69163L13.0796 3.876L9.69163 7.096H8.08163L11.4696 3.876L8.08163 0.67Z" />
                </SvgIcon>
              }
            />
            <BlockButton
              format="h1"
              Icon={
                <SvgIcon width="4" height="11" viewBox="0 0 4 11">
                  <path d="M0.532 2.04V0.877999H3.164V11H1.876V2.04H0.532Z" />
                </SvgIcon>
              }
            />
            <BlockButton
              format="h2"
              Icon={
                <SvgIcon width="8" height="11" viewBox="0 0 8 11">
                  <path d="M0.714 9.978C1.89933 9.026 2.828 8.24667 3.5 7.64C4.172 7.024 4.73667 6.38467 5.194 5.722C5.66067 5.05 5.894 4.392 5.894 3.748C5.894 3.14133 5.74467 2.66533 5.446 2.32C5.15667 1.96533 4.68533 1.788 4.032 1.788C3.39733 1.788 2.90267 1.98867 2.548 2.39C2.20267 2.782 2.016 3.30933 1.988 3.972H0.756C0.793333 2.92667 1.11067 2.11933 1.708 1.55C2.30533 0.980666 3.07533 0.695999 4.018 0.695999C4.97933 0.695999 5.74 0.961999 6.3 1.494C6.86933 2.026 7.154 2.75867 7.154 3.692C7.154 4.46667 6.92067 5.22267 6.454 5.96C5.99667 6.688 5.474 7.332 4.886 7.892C4.298 8.44267 3.54667 9.08667 2.632 9.824H7.448V10.888H0.714V9.978Z" />
                </SvgIcon>
              }
            />
            <BlockButton
              format="blockquote"
              Icon={
                <SvgIcon width="6" height="4" viewBox="0 0 6 4">
                  <path d="M1.764 3.42H0.336L1.68 0.242H2.59L1.764 3.42ZM4.3365 0.242H5.7505L4.4065 3.42H3.5105L4.3365 0.242Z" />
                </SvgIcon>
              }
            />
            <BlockButton
              format="ul"
              Icon={
                <SvgIcon width="14" height="14" viewBox="0 0 14 14">
                  <g clipPath="url(#clip0_1009_2959)">
                    <path d="M13.5898 1.64062H3.69141C3.46489 1.64062 3.28125 1.45698 3.28125 1.23047C3.28125 1.00395 3.46489 0.820312 3.69141 0.820312H13.5898C13.8164 0.820312 14 1.00395 14 1.23047C14 1.45698 13.8164 1.64062 13.5898 1.64062Z" />
                    <path d="M1.23047 2.46094C0.551988 2.46094 0 1.90895 0 1.23047C0 0.551988 0.551988 0 1.23047 0C1.90895 0 2.46094 0.551988 2.46094 1.23047C2.46094 1.90895 1.90895 2.46094 1.23047 2.46094ZM1.23047 0.820312C1.00431 0.820312 0.820312 1.00431 0.820312 1.23047C0.820312 1.45663 1.00431 1.64062 1.23047 1.64062C1.45663 1.64062 1.64062 1.45663 1.64062 1.23047C1.64062 1.00431 1.45663 0.820312 1.23047 0.820312Z" />
                    <path d="M13.5898 5.48694H3.69141C3.46489 5.48694 3.28125 5.3033 3.28125 5.07678C3.28125 4.85027 3.46489 4.66663 3.69141 4.66663H13.5898C13.8164 4.66663 14 4.85027 14 5.07678C14 5.3033 13.8164 5.48694 13.5898 5.48694Z" />
                    <path d="M1.23047 6.30725C0.551988 6.30725 0 5.75526 0 5.07678C0 4.3983 0.551988 3.84631 1.23047 3.84631C1.90895 3.84631 2.46094 4.3983 2.46094 5.07678C2.46094 5.75526 1.90895 6.30725 1.23047 6.30725ZM1.23047 4.66663C1.00431 4.66663 0.820312 4.85062 0.820312 5.07678C0.820312 5.30294 1.00431 5.48694 1.23047 5.48694C1.45663 5.48694 1.64062 5.30294 1.64062 5.07678C1.64062 4.85062 1.45663 4.66663 1.23047 4.66663Z" />
                    <path d="M13.5898 13.1797H3.69141C3.46489 13.1797 3.28125 12.996 3.28125 12.7695C3.28125 12.543 3.46489 12.3594 3.69141 12.3594H13.5898C13.8164 12.3594 14 12.543 14 12.7695C14 12.996 13.8164 13.1797 13.5898 13.1797Z" />
                    <path d="M1.23047 14C0.551988 14 0 13.448 0 12.7695C0 12.0911 0.551988 11.5391 1.23047 11.5391C1.90895 11.5391 2.46094 12.0911 2.46094 12.7695C2.46094 13.448 1.90895 14 1.23047 14ZM1.23047 12.3594C1.00431 12.3594 0.820312 12.5434 0.820312 12.7695C0.820312 12.9957 1.00431 13.1797 1.23047 13.1797C1.45663 13.1797 1.64062 12.9957 1.64062 12.7695C1.64062 12.5434 1.45663 12.3594 1.23047 12.3594Z" />
                    <path d="M13.5898 9.33337H3.69141C3.46489 9.33337 3.28125 9.14973 3.28125 8.92322C3.28125 8.6967 3.46489 8.51306 3.69141 8.51306H13.5898C13.8164 8.51306 14 8.6967 14 8.92322C14 9.14973 13.8164 9.33337 13.5898 9.33337Z" />
                    <path d="M1.23047 10.1537C0.551988 10.1537 0 9.6017 0 8.92322C0 8.24474 0.551988 7.69275 1.23047 7.69275C1.90895 7.69275 2.46094 8.24474 2.46094 8.92322C2.46094 9.6017 1.90895 10.1537 1.23047 10.1537ZM1.23047 8.51306C1.00431 8.51306 0.820312 8.69706 0.820312 8.92322C0.820312 9.14938 1.00431 9.33337 1.23047 9.33337C1.45663 9.33337 1.64062 9.14938 1.64062 8.92322C1.64062 8.69706 1.45663 8.51306 1.23047 8.51306Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1009_2959">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              }
            />
            <BlockButton
              format="ol"
              Icon={
                <SvgIcon width="14" height="14" viewBox="0 0 14 14">
                  <g clipPath="url(#clip0_1009_2985)">
                    <path d="M4.23828 2.46093H13.5898C13.8163 2.46093 13.9999 2.27729 13.9999 2.05078C13.9999 1.82426 13.8163 1.64062 13.5898 1.64062H4.23828C4.01176 1.64062 3.82812 1.82426 3.82812 2.05078C3.82812 2.27729 4.01176 2.46093 4.23828 2.46093Z" />
                    <path d="M13.5898 6.58984H4.23828C4.01176 6.58984 3.82812 6.77348 3.82812 7C3.82812 7.22651 4.01176 7.41015 4.23828 7.41015H13.5898C13.8163 7.41015 13.9999 7.22651 13.9999 7C13.9999 6.77348 13.8163 6.58984 13.5898 6.58984Z" />
                    <path d="M13.5898 11.5391H4.23828C4.01176 11.5391 3.82812 11.7227 3.82812 11.9492C3.82812 12.1757 4.01176 12.3594 4.23828 12.3594H13.5898C13.8163 12.3594 13.9999 12.1757 13.9999 11.9492C13.9999 11.7227 13.8163 11.5391 13.5898 11.5391Z" />
                    <path d="M0.710938 0.820312H0.943687V3.41797C0.943687 3.64448 1.12733 3.82812 1.35384 3.82812C1.58036 3.82812 1.764 3.64448 1.764 3.41797V0.410156C1.764 0.183641 1.58036 0 1.35384 0H0.710938C0.484422 0 0.300781 0.183641 0.300781 0.410156C0.300781 0.636672 0.484422 0.820312 0.710938 0.820312Z" />
                    <path d="M2.02571 8.08694C1.7838 8.08998 1.52909 8.09219 1.3017 8.09318C1.45644 7.88402 1.65734 7.60684 1.9136 7.24071C2.10774 6.96336 2.23462 6.69993 2.29078 6.45772C2.2939 6.44429 2.29633 6.43073 2.29808 6.41706L2.31266 6.30295C2.31487 6.28573 2.31597 6.26836 2.31597 6.25097C2.31597 5.60856 1.79334 5.08594 1.15096 5.08594C0.595496 5.08594 0.114711 5.48114 0.00776995 6.02561C-0.035898 6.24788 0.108915 6.46346 0.331192 6.5071C0.553497 6.55071 0.769047 6.40596 0.812688 6.18368C0.844243 6.02293 0.986512 5.90625 1.15093 5.90625C1.33391 5.90625 1.48406 6.04959 1.495 6.22986L1.48731 6.2901C1.46183 6.38791 1.39817 6.5465 1.24155 6.77034C0.7752 7.43668 0.496977 7.80095 0.347516 7.99668C0.15868 8.24392 0.0546645 8.38012 0.123133 8.60278C0.16393 8.73537 0.264036 8.83698 0.397883 8.88158C0.453391 8.90009 0.497879 8.91491 1.07054 8.91491C1.29788 8.91491 1.60847 8.91259 2.03605 8.9072C2.26254 8.90436 2.44385 8.71842 2.44104 8.4919C2.43814 8.26541 2.25187 8.08443 2.02571 8.08694Z" />
                    <path d="M1.2207 10.1719C0.666609 10.1719 0.187055 10.5661 0.0804141 11.1092C0.0367461 11.3315 0.181559 11.5471 0.403836 11.5907C0.626141 11.6343 0.841691 11.4896 0.885332 11.2673C0.916641 11.1079 1.05768 10.9922 1.2207 10.9922C1.40918 10.9922 1.5625 11.1455 1.5625 11.334C1.5625 11.5225 1.40918 11.6758 1.2207 11.6758C0.994187 11.6758 0.810547 11.8594 0.810547 12.0859C0.810547 12.3125 0.994187 12.4961 1.2207 12.4961C1.40918 12.4961 1.5625 12.6494 1.5625 12.8379C1.5625 13.0264 1.40918 13.1797 1.2207 13.1797C1.05112 13.1797 0.905594 13.0535 0.882242 12.8861C0.880027 12.8703 0.878906 12.8541 0.878906 12.8379C0.878906 12.6114 0.695266 12.4277 0.46875 12.4277C0.242234 12.4277 0.0585938 12.6114 0.0585938 12.8379C0.0585938 12.8918 0.0623672 12.9461 0.0697773 12.9995C0.149375 13.5699 0.644188 14 1.2207 14C1.8615 14 2.38281 13.4787 2.38281 12.8379C2.38281 12.5513 2.2783 12.2888 2.10571 12.0859C2.27833 11.8831 2.38281 11.6206 2.38281 11.334C2.38281 10.6932 1.86148 10.1719 1.2207 10.1719Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1009_2985">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              }
            />
            <BlockButton
              format="left"
              Icon={
                <SvgIcon width="14" height="14" viewBox="0 0 14 14">
                  <g clipPath="url(#clip0_1009_2993)">
                    <path d="M13.5898 0.820312H0.410156C0.183641 0.820312 0 0.636672 0 0.410156C0 0.183641 0.183641 0 0.410156 0H13.5898C13.8164 0 14 0.183641 14 0.410156C14 0.636672 13.8164 0.820312 13.5898 0.820312Z" />
                    <path d="M13.5898 14H0.410156C0.183641 14 0 13.8164 0 13.5898C0 13.3633 0.183641 13.1797 0.410156 13.1797H13.5898C13.8164 13.1797 14 13.3633 14 13.5898C14 13.8164 13.8164 14 13.5898 14Z" />
                    <path d="M10.3086 10.7051H0.410156C0.183641 10.7051 0 10.5214 0 10.2949C0 10.0684 0.183641 9.88477 0.410156 9.88477H10.3086C10.5351 9.88477 10.7188 10.0684 10.7188 10.2949C10.7188 10.5214 10.5351 10.7051 10.3086 10.7051Z" />
                    <path d="M13.5898 7.41016H0.410156C0.183641 7.41016 0 7.22652 0 7C0 6.77348 0.183641 6.58984 0.410156 6.58984H13.5898C13.8164 6.58984 14 6.77348 14 7C14 7.22652 13.8164 7.41016 13.5898 7.41016Z" />
                    <path d="M10.3086 4.11523H0.410156C0.183641 4.11523 0 3.93159 0 3.70508C0 3.47856 0.183641 3.29492 0.410156 3.29492H10.3086C10.5351 3.29492 10.7188 3.47856 10.7188 3.70508C10.7188 3.93159 10.5351 4.11523 10.3086 4.11523Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1009_2993">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              }
            />
            <BlockButton
              format="center"
              Icon={
                <SvgIcon width="14" height="14" viewBox="0 0 14 14">
                  <g clipPath="url(#clip0_1009_3005)">
                    <path d="M13.5898 0.820312H0.410156C0.183641 0.820312 0 0.636672 0 0.410156C0 0.183641 0.183641 0 0.410156 0H13.5898C13.8164 0 14 0.183641 14 0.410156C14 0.636672 13.8164 0.820312 13.5898 0.820312Z" />
                    <path d="M13.5898 14H0.410156C0.183641 14 0 13.8164 0 13.5898C0 13.3633 0.183641 13.1797 0.410156 13.1797H13.5898C13.8164 13.1797 14 13.3633 14 13.5898C14 13.8164 13.8164 14 13.5898 14Z" />
                    <path d="M13.5898 10.7051H3.74609C3.51958 10.7051 3.33594 10.5214 3.33594 10.2949C3.33594 10.0684 3.51958 9.88477 3.74609 9.88477H13.5898C13.8164 9.88477 14 10.0684 14 10.2949C14 10.5214 13.8164 10.7051 13.5898 10.7051Z" />
                    <path d="M13.5898 7.41016H0.464844C0.238328 7.41016 0.0546875 7.22652 0.0546875 7C0.0546875 6.77348 0.238328 6.58984 0.464844 6.58984H13.5898C13.8164 6.58984 14 6.77348 14 7C14 7.22652 13.8164 7.41016 13.5898 7.41016Z" />
                    <path d="M13.5898 4.11523H3.74609C3.51958 4.11523 3.33594 3.93159 3.33594 3.70508C3.33594 3.47856 3.51958 3.29492 3.74609 3.29492H13.5898C13.8164 3.29492 14 3.47856 14 3.70508C14 3.93159 13.8164 4.11523 13.5898 4.11523Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1009_3005">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              }
            />
            <BlockButton
              format="right"
              Icon={
                <SvgIcon width="14" height="14" viewBox="0 0 14 14">
                  <g clipPath="url(#clip0_1009_3017)">
                    <path d="M13.5898 0.820312H0.410156C0.183641 0.820312 0 0.636672 0 0.410156C0 0.183641 0.183641 0 0.410156 0H13.5898C13.8164 0 14 0.183641 14 0.410156C14 0.636672 13.8164 0.820312 13.5898 0.820312Z" />
                    <path d="M13.5898 14H0.410156C0.183641 14 0 13.8164 0 13.5898C0 13.3633 0.183641 13.1797 0.410156 13.1797H13.5898C13.8164 13.1797 14 13.3633 14 13.5898C14 13.8164 13.8164 14 13.5898 14Z" />
                    <path d="M11.9219 10.7051H2.07812C1.85161 10.7051 1.66797 10.5214 1.66797 10.2949C1.66797 10.0684 1.85161 9.88477 2.07812 9.88477H11.9219C12.1484 9.88477 12.332 10.0684 12.332 10.2949C12.332 10.5214 12.1484 10.7051 11.9219 10.7051Z" />
                    <path d="M13.5898 7.41016H0.410156C0.183641 7.41016 0 7.22652 0 7C0 6.77348 0.183641 6.58984 0.410156 6.58984H13.5898C13.8164 6.58984 14 6.77348 14 7C14 7.22652 13.8164 7.41016 13.5898 7.41016Z" />
                    <path d="M11.9219 4.11523H2.07812C1.85161 4.11523 1.66797 3.93159 1.66797 3.70508C1.66797 3.47856 1.85161 3.29492 2.07812 3.29492H11.9219C12.1484 3.29492 12.332 3.47856 12.332 3.70508C12.332 3.93159 12.1484 4.11523 11.9219 4.11523Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1009_3017">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              }
            />
            <BlockButton
              format="justify"
              Icon={
                <SvgIcon width="14" height="14" viewBox="0 0 14 14">
                  <g clipPath="url(#clip0_1009_3029)">
                    <path d="M13.5898 0.820312H0.410156C0.183641 0.820312 0 0.636672 0 0.410156C0 0.183641 0.183641 0 0.410156 0H13.5898C13.8164 0 14 0.183641 14 0.410156C14 0.636672 13.8164 0.820312 13.5898 0.820312Z" />
                    <path d="M13.5898 14H0.410156C0.183641 14 0 13.8164 0 13.5898C0 13.3633 0.183641 13.1797 0.410156 13.1797H13.5898C13.8164 13.1797 14 13.3633 14 13.5898C14 13.8164 13.8164 14 13.5898 14Z" />
                    <path d="M13.5898 10.7051H0.410156C0.183641 10.7051 0 10.5214 0 10.2949C0 10.0684 0.183641 9.88477 0.410156 9.88477H13.5898C13.8164 9.88477 14 10.0684 14 10.2949C14 10.5214 13.8164 10.7051 13.5898 10.7051Z" />
                    <path d="M13.5898 7.41016H0.410156C0.183641 7.41016 0 7.22652 0 7C0 6.77348 0.183641 6.58984 0.410156 6.58984H13.5898C13.8164 6.58984 14 6.77348 14 7C14 7.22652 13.8164 7.41016 13.5898 7.41016Z" />
                    <path d="M13.5898 4.11523H0.410156C0.183641 4.11523 0 3.93159 0 3.70508C0 3.47856 0.183641 3.29492 0.410156 3.29492H13.5898C13.8164 3.29492 14 3.47856 14 3.70508C14 3.93159 13.8164 4.11523 13.5898 4.11523Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1009_3029">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </SvgIcon>
              }
            />
          </Toolbar>

          <Box
            sx={{
              px: 2,
              minHeight: "12.625rem",
              color: "var(--default-text-color)",
            }}
          >
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Enter some description"
              spellCheck
              readOnly={disabled}
            />
          </Box>
        </Slate>
      </Box>
    </Box>
  );
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties;

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "li" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case "blockquote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "ul":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "h1":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "li":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "ol":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, Icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {Icon}
    </Button>
  );
};

const MarkButton = ({ format, Icon }) => {
  const editor = useSlate();

  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {Icon}
    </Button>
  );
};
