/** @jsxImportSource @emotion/react */
import { useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useLoaderData } from "react-router-dom";
import { object, string } from "yup";
import { css } from "@emotion/react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { PROFILE } from "../../config/RESTAPI";
import { UserImagesContext } from "../UserImages/context";
import Caption from "../UI/Caption";
import TextInput from "../UI/TextInput";
import { InputLabel, SvgIcon } from "@mui/material";

const Profile = () => {
  const { handleOpen } = useContext(UserImagesContext);
  const data = useLoaderData();
  const [alert, setAlert] = useState(false);
  const initialValues = { ...data };

  const handleSave = (values, actions) => {
    PROFILE.Save(values)
      .then((data) => {
        if (data.status === "success") {
          setAlert({ severity: "success", message: "Successfully saved" });
        }
        actions.setSubmitting(false);
      })
      .catch((e) => {
        setAlert({ severity: "error", message: "Try again later." });
        actions.setSubmitting(false);
      });
  };

  const schema = object().shape({
    // picture: string()
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     "Enter correct url!"
    //   )
    //   .nullable(),
    name: string(),
    email: string().email("Must be a valid email"),
  });

  return (
    <Box sx={{ px: 2, py: 3 }}>
      <Caption title="Profile Settings" />

      {alert && (
        <Alert sx={{ mb: 2 }} severity={alert.severity}>
          {alert.message}
        </Alert>
      )}

      <Formik
        onSubmit={handleSave}
        initialValues={initialValues}
        validationSchema={schema}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
            <InputLabel
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "21px",
                ml: 4.5,
                color: "#ADADAD",
              }}
            >
              Image
            </InputLabel>

            <Box
              sx={{
                mb: 4.5,
                p: "20px 36px",
                background:
                  "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                borderRadius: 14,
                position: "relative",
              }}
            >
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox={null}
                sx={{
                  fill: "none",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                  overflow: "visible",
                  zIndex: 10,
                }}
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="100%"
                  height="100%"
                  rx="52.5"
                  stroke="white"
                />

                <linearGradient
                  id="paint1_linear_1041_192"
                  x1="417.341"
                  y1="-2.77012"
                  x2="430.937"
                  y2="139.071"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
              </SvgIcon>

              <Field name="picture">
                {({ field }) =>
                  field.value && (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: 240,
                          maxWidth: "25%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                          px: 1.5,
                          mb: 2,
                        }}
                      >
                        {values.picture && (
                          <img
                            src={values.picture}
                            alt=""
                            css={css({
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: 24,
                            })}
                          />
                        )}
                      </Box>
                    </Box>
                  )
                }
              </Field>

              <Box sx={{ textAlign: "center" }}>
                <Button
                  disableRipple
                  sx={{
                    position: "relative",
                    p: 2,
                    borderRadius: 15,
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: "18px",
                    textTransform: "capitalize",
                    background: "var(--upload-button-text-color)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    "&:hover": {
                      background: "var(--upload-button-text-color)",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                    },
                  }}
                  onClick={() =>
                    handleOpen((image) => setFieldValue("picture", image))
                  }
                >
                  <SvgIcon
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    sx={{
                      m: "0 8px 0 7px",
                      path: {
                        fill: "var(--upload-button-icon-fill)",
                      },
                    }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.648 0H11.352C14.264 0 16 1.736 16 4.648V9.52V11.352C16 14.264 14.264 16 11.352 16H4.648C2.608 16 1.136 15.144 0.448 13.624C0.152 12.984 0 12.224 0 11.352V4.648C0 1.736 1.736 0 4.648 0ZM12.44 8.4C13.064 7.864 14.072 7.864 14.696 8.4L14.8 8.488V4.648C14.8 2.392 13.608 1.2 11.352 1.2H4.648C2.392 1.2 1.2 2.392 1.2 4.648V11.352C1.2 11.96 1.304 12.48 1.48 12.928L4.472 10.92C5.112 10.488 6.016 10.536 6.584 11.032L6.856 11.256C7.48 11.792 8.488 11.792 9.112 11.256L12.44 8.4ZM5.59998 6.70394C6.65153 6.70394 7.50398 5.85149 7.50398 4.79994C7.50398 3.74839 6.65153 2.89594 5.59998 2.89594C4.54843 2.89594 3.69598 3.74839 3.69598 4.79994C3.69598 5.85149 4.54843 6.70394 5.59998 6.70394Z"
                    />
                  </SvgIcon>
                  Upload Image
                  <SvgIcon
                    viewBox={null}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      overflow: "visible",
                      fill: "none",
                      stroke: "var(--upload-button-fill)",
                    }}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="100%"
                      height="100%"
                      rx="25"
                      stroke="url(#paint2_linear_1009_3444)"
                      strokeWidth="2"
                    />
                  </SvgIcon>
                </Button>
              </Box>
              {/* {values.picture && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: 240,
                      maxWidth: "25%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      px: 1.5,
                      mb: 2,
                    }}
                  >
                    {values.picture && (
                      <img
                        src={values.picture}
                        alt=""
                        css={css({
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 24,
                        })}
                      />
                    )}
                  </Box>
                </Box>
              )} */}
            </Box>

            <Field name="name">
              {({ field }) => (
                <TextInput
                  field={field}
                  labelText="Profile Name"
                  helperText="This name will be used on market.reminto.com"
                />
              )}
            </Field>

            <Field name="email">
              {({ field }) => (
                <TextInput
                  field={field}
                  labelText="Email Address"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              )}
            </Field>

            <Button type="submit" variant="default" disabled={isSubmitting}>
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Profile;
