/** @jsxImportSource @emotion/react */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { css } from "@emotion/react";
import { AiTwotoneEdit } from "react-icons/ai";

import styles from "./EditElement.module.scss";
import { changeEditableComponent, changeActiveTab } from "../../../../config/slices/builder";

export default function EditElement({ children, element }) {
  const editableOverlayRef = useRef(null);
  const elementRef = useRef();
  const dispatch = useDispatch();

  const { activeTab } = useSelector((state) => state.builder);

  const handleEdit = () => {
    dispatch(changeEditableComponent(element));
    activeTab !== "settings" && dispatch(changeActiveTab("settings"));
  };

  return (
    <>
      {React.cloneElement(children, {
        onMouseEnter: ({ currentTarget }) => {
          setTimeout(() => {
            editableOverlayRef.current.style.top = `${
              currentTarget.offsetTop -
              document.getElementById("canvas").scrollTop
            }px`;
            editableOverlayRef.current.style.left = `${currentTarget.offsetLeft}px`;
            editableOverlayRef.current.style.width = `${currentTarget.offsetWidth}px`;
            editableOverlayRef.current.style.height = `${currentTarget.offsetHeight}px`;
          }, 1);
        },
        className: classNames(
          children.props.className,
          styles["builder-edit-element"]
        ),
        ref: elementRef,
      })}

      <div
        ref={editableOverlayRef}
        css={css({
          position: "absolute",
          zIndex: 10000,
          left: 0,
          opacity: 0,
          outline: "2px solid #3b97e3",
          outlineOffset: -2,
          pointerEvents: "none",
          "&:hover": { opacity: 1 },
        })}
        className="builder-edit-layout"
        // style={{ top: 0, left: 0 }}
        data-edit-layer=""
      >
        <span
          css={css({
            pointerEvents: "initial",
            position: "absolute",
            right: 0,
            backgroundColor: "#3b97e3",
            color: "#ffffff",
          })}
        >
          <AiTwotoneEdit
            css={css({ cursor: "pointer", fontSize: 25 })}
            title="Edit"
            onClick={handleEdit}
          />
        </span>
      </div>
    </>
  );
}

EditElement.propTypes = {
  children: PropTypes.element,
};
