import axios from "axios";
import { config } from "..";

class RESTAPI {
  constructor() {
    this.apikey = window.localStorage.getItem("apikey");
    this.axios = axios.create({
      baseURL:
        process.env.NODE_ENV === "production"
          ? config.restapi_endpoint
          : config.restapi_endpoint_dev,
      headers: {
        authorization: `Bearer ${this.apikey}`,
      },
    });
  }

  static getInstance() {
    if (!this._instance) {
      this._instance = new RESTAPI();
    }

    return this._instance;
  }
}

export default RESTAPI.getInstance();

export { default as DASHBOARD } from "./Dashboard";
export { default as CATEGORY } from "./Category";
export { default as AUTH } from "./Auth";
export { default as CAMPAIGN } from "./Campaign";
export { default as STATISTIC } from "./Statistic";
export { default as TRANSACTION } from "./Transaction";
export { default as TEMPLATE } from "./Template";
export { default as BUILDER } from "./Builder";
export { default as BILLING } from "./Billing";
export { default as IMAGE } from "./Image";
export { default as PROFILE } from "./Profile";
