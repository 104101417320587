import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeModeContextProvider, router, store } from "./config";
import { UserImages } from "./components";
import { UserImagesContextProvider } from "./components/UserImages/context";
import Cookies from "js-cookie";
import { SvgIcon } from "@mui/material";

document.body.setAttribute(
  "data-theme",
  Cookies.get("reminto-theme") || "light"
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <SvgIcon viewBox={null} sx={{ position: "absolute", visibility: "hidden" }}>
      <linearGradient
        id="paint0_linear_1009_3444"
        x1="24"
        y1="26"
        x2="40"
        y2="26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDFF1D" />
        <stop offset="0.48" stopColor="#F2A4FF" />
        <stop offset="1" stopColor="#6654F1" />
      </linearGradient>

      <linearGradient
        id="paint2_linear_1009_3444"
        x1="1"
        y1="26"
        x2="157"
        y2="26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDFF1D" />
        <stop offset="0.48" stopColor="#F2A4FF" />
        <stop offset="1" stopColor="#6654F1" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_1009_3445"
        x1="0"
        y1="8"
        x2="16"
        y2="8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDFF1D" />
        <stop offset="0.48" stopColor="#F2A4FF" />
        <stop offset="1" stopColor="#6654F1" />
      </linearGradient>

      <linearGradient
        id="paint1_linear_1009_2916"
        x1="13.7283"
        y1="-1.73132"
        x2="51.4722"
        y2="18.9929"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>

      <linearGradient
        id="paint1_linear_1071_2653"
        x1="0"
        y1="32"
        x2="100%"
        y2="32"
        // gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDFF1D" />
        <stop offset="0.48" stopColor="#F2A4FF" />
        <stop offset="1" stopColor="#6654F1" />
      </linearGradient>

      <linearGradient
        id="paint1_linear_1111_3474"
        x1="72.0737"
        y1="-1.73132"
        x2="99.7757"
        y2="78.1237"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_1138_3946"
        x1="0"
        y1="0"
        x2="100%"
        y2="100%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DC70EE" />
        <stop offset="1" stopColor="#6E3EF7" />
        <stop offset="1" stopColor="#673AB7" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_1129_3322"
        x1="1"
        y1="33"
        x2="295"
        y2="33"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDFF1D" />
        <stop offset="0.48" stopColor="#F2A4FF" />
        <stop offset="1" stopColor="#6654F1" />
      </linearGradient>

      <linearGradient
        id="paint1_linear_1142_3568"
        x1="202.15"
        y1="-15.4953"
        x2="600.447"
        y2="344.313"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </SvgIcon>

    <Provider store={store}>
      <ThemeModeContextProvider>
        <UserImagesContextProvider>
          <RouterProvider router={router} />
          <UserImages />
        </UserImagesContextProvider>
      </ThemeModeContextProvider>
    </Provider>
  </StrictMode>
);
