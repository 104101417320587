import { configureStore } from "@reduxjs/toolkit";
import {
  categoryReducer,
  userReducer,
  builderReducer,
  contractReducer,
} from "./slices";

export default configureStore({
  reducer: {
    user: userReducer,
    category: categoryReducer,
    builder: builderReducer,
    contract: contractReducer,
  },
});
