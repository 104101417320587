import CountUp from "react-countup";
import PropTypes from "prop-types";
import { useId } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { SvgIcon } from "@mui/material";
import Typography from "@mui/material/Typography";


const Tile = ({ color, title, value, Icon, sx }) => {
  const id = useId();

  return (
    <Paper
      variant="elevation0"
      sx={{
        backgroundImage: `linear-gradient(118.77deg, rgba(${color}, 0.7) 20.62%, rgba(${color}, 0.3) 99.9%)`,
        ...sx,
      }}
    >
      <SvgIcon viewBox={null} xmlns="http://www.w3.org/2000/svg">
        <rect
          x="1"
          y="1"
          width="100%"
          height="100%"
          rx="23"
          stroke={`url(#linear-${id})`}
          strokeOpacity="0.3"
          strokeWidth="2"
        />
        <linearGradient
          id={`linear-${id}`}
          x1="96.0983"
          y1="-5.97306"
          x2="249.529"
          y2="164.959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={`rgb(${color})`} />
          <stop offset="1" stopColor={`rgb(${color})`} stopOpacity="0" />
        </linearGradient>
      </SvgIcon>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mr: 4,
        }}
      >
        <Typography variant="tilenumber">
          {value > 1 ? <CountUp end={value} duration={1} /> : value}
        </Typography>
        <Typography variant="tilecaption">{title}</Typography>
      </Box>

      <Box>{Icon && Icon}</Box>
    </Paper>
  );
};

Tile.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  Icon: PropTypes.object.isRequired,
  sx: PropTypes.object,
};

Tile.defaultProps = {
  color: "#fff",
  value: 0,
  sx: {},
};

export default Tile;
