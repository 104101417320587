import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

import { useTheme } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SvgIcon from "@mui/material/SvgIcon";

import CssClasses from "./MainMenu.module.scss";

const MainMenu = () => {
  const { palette } = useTheme();
  const location = useLocation();

  return (
    <List className={CssClasses.MainMenu}>
      <ListItem className={CssClasses.MainMenu__Item}>
        <NavLink
          to="/"
          className={({ isActive }) =>
            classNames(CssClasses.MainMenu__Item__Link, {
              [CssClasses.MainMenu__Item__Link__Active]: isActive,
            })
          }
          end
        >
          {({ isActive }) => (
            <>
              {isActive && (
                <SvgIcon
                  viewBox={null}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    fill: "none",
                    overflow: "visible",
                    strokeWidth: "2px",
                  }}
                >
                  <rect
                    width="100%"
                    height="100%"
                    rx="24px"
                    ry="24px"
                    stroke="url(#gradient)"
                  ></rect>
                  <linearGradient
                    id="gradient"
                    x1="0"
                    x2="1"
                    y1="0"
                    y2="0"
                    gradientTransform="rotate(30)"
                  >
                    <stop offset="0%" stopColor="hsla(0,0%,100%,.3)"></stop>
                    <stop offset="100%" stopColor="hsla(0,0%,100%,0)"></stop>
                  </linearGradient>
                </SvgIcon>
              )}

              <ListItemIcon className={CssClasses.MainMenu__Item__Link__Icon}>
                {!isActive ? (
                  <SvgIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </SvgIcon>
                ) : (
                  <SvgIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.34 2H7.24C9.42 2 10.57 3.15 10.56 5.33V7.23C10.56 9.41 9.41 10.56 7.23 10.56H5.33C3.15 10.56 2 9.41 2 7.23V5.33C2 3.15 3.15 2 5.34 2ZM16.7699 2H18.6699C20.8499 2 21.9999 3.15 21.9999 5.33V7.23C21.9999 9.41 20.8499 10.56 18.6699 10.56H16.7699C14.5899 10.56 13.4399 9.41 13.4399 7.23V5.33C13.4399 3.15 14.5899 2 16.7699 2ZM18.6699 13.4299H16.7699C14.5899 13.4299 13.4399 14.5799 13.4399 16.7599V18.6599C13.4399 20.8399 14.5899 21.9899 16.7699 21.9899H18.6699C20.8499 21.9899 21.9999 20.8399 21.9999 18.6599V16.7599C21.9999 14.5799 20.8499 13.4299 18.6699 13.4299ZM5.34 13.4299H7.24C9.42 13.4299 10.57 14.5799 10.56 16.7699V18.6699C10.56 20.8499 9.41 21.9999 7.23 21.9999H5.33C3.15 21.9999 2 20.8499 2 18.6599V16.7599C2 14.5799 3.15 13.4299 5.34 13.4299Z"
                      fill={palette.text.default}
                    />
                  </SvgIcon>
                )}
              </ListItemIcon>
              <ListItemText
                className={CssClasses.MainMenu__Item__Link__Text}
                primary="Dashboard"
              />
            </>
          )}
        </NavLink>
      </ListItem>

      <ListItem className={CssClasses.MainMenu__Item}>
        <NavLink
          to="/campaigns"
          className={({ isActive }) =>
            classNames(CssClasses.MainMenu__Item__Link, {
              [CssClasses.MainMenu__Item__Link__Active]: isActive,
            })
          }
        >
          {({ isActive }) => (
            <>
              {isActive && (
                <SvgIcon
                  viewBox={null}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    fill: "none",
                    overflow: "visible",
                    strokeWidth: "2px",
                  }}
                >
                  <rect
                    width="100%"
                    height="100%"
                    rx="24px"
                    ry="24px"
                    stroke="url(#gradient)"
                  ></rect>
                  <linearGradient
                    id="gradient"
                    x1="0"
                    x2="1"
                    y1="0"
                    y2="0"
                    gradientTransform="rotate(30)"
                  >
                    <stop offset="0%" stopColor="hsla(0,0%,100%,.3)"></stop>
                    <stop offset="100%" stopColor="hsla(0,0%,100%,0)"></stop>
                  </linearGradient>
                </SvgIcon>
              )}

              <ListItemIcon>
                {!isActive ? (
                  <SvgIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.61914 11.27C4.86914 12.81 7.40914 13.74 9.99914 14.03"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </SvgIcon>
                ) : (
                  <SvgIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.0891 6.98002C20.2391 6.04002 18.8191 5.57002 16.7591 5.57002H16.5191V5.53002C16.5191 3.85002 16.5191 1.77002 12.7591 1.77002H11.2391C7.47906 1.77002 7.47906 3.86002 7.47906 5.53002V5.58002H7.23906C5.16906 5.58002 3.75906 6.05002 2.90906 6.99002C1.91906 8.09002 1.94906 9.57002 2.04906 10.58L2.05906 10.65L2.13652 11.4633C2.15078 11.6131 2.23144 11.7484 2.35734 11.8307C2.59714 11.9877 2.99849 12.2464 3.23906 12.38C3.37906 12.47 3.52906 12.55 3.67906 12.63C5.38906 13.57 7.26906 14.2 9.17906 14.51C9.26906 15.45 9.67906 16.55 11.8691 16.55C14.0591 16.55 14.4891 15.46 14.5591 14.49C16.5991 14.16 18.5691 13.45 20.3491 12.41C20.4091 12.38 20.4491 12.35 20.4991 12.32C20.8958 12.0958 21.3074 11.8195 21.6825 11.5489C21.7955 11.4673 21.8678 11.3413 21.8832 11.2028L21.8991 11.06L21.9491 10.59C21.9591 10.53 21.9591 10.48 21.9691 10.41C22.0491 9.40002 22.0291 8.02002 21.0891 6.98002ZM13.0891 13.83C13.0891 14.89 13.0891 15.05 11.8591 15.05C10.6291 15.05 10.6291 14.86 10.6291 13.84V12.58H13.0891V13.83ZM8.90906 5.57002V5.53002C8.90906 3.83002 8.90906 3.20002 11.2391 3.20002H12.7591C15.0891 3.20002 15.0891 3.84002 15.0891 5.53002V5.58002H8.90906V5.57002Z"
                      fill={palette.text.default}
                    />
                    <path
                      d="M20.8733 13.7344C21.2269 13.5661 21.6342 13.8464 21.5988 14.2364L21.2398 18.1902C21.0298 20.1902 20.2098 22.2302 15.8098 22.2302H8.18984C3.78984 22.2302 2.96984 20.1902 2.75984 18.2002L2.41913 14.4524C2.38409 14.0669 2.78205 13.787 3.13468 13.9465C4.2741 14.462 6.37724 15.3766 7.67641 15.7169C7.84072 15.7599 7.97361 15.8775 8.04556 16.0314C8.65253 17.3295 9.96896 18.0202 11.8698 18.0202C13.752 18.0202 15.085 17.3029 15.694 16.0016C15.766 15.8476 15.8991 15.7301 16.0635 15.6868C17.443 15.3238 19.6816 14.3015 20.8733 13.7344Z"
                      fill={palette.text.default}
                    />
                  </SvgIcon>
                )}
              </ListItemIcon>
              <ListItemText primary="Campaign" />
            </>
          )}
        </NavLink>

        <List className={CssClasses.MainMenu}>
          <ListItem className={CssClasses.MainMenu__Item}>
            <NavLink
              to="/campaigns/create"
              className={({ isActive }) =>
                classNames(CssClasses.MainMenu__Item__Link, {
                  [CssClasses.MainMenu__Item__Link__Active]: isActive,
                })
              }
            >
              <ListItemText primary="Create" color="text.default" />
            </NavLink>
          </ListItem>

          <ListItem className={CssClasses.MainMenu__Item}>
            <NavLink
              to="/campaigns"
              className={({ isActive }) =>
                classNames(CssClasses.MainMenu__Item__Link, {
                  [CssClasses.MainMenu__Item__Link__Active]:
                    isActive && location.pathname !== "/campaigns/create",
                })
              }
            >
              <ListItemText primary="Manage" />
            </NavLink>
          </ListItem>
        </List>
      </ListItem>

      <ListItem className={CssClasses.MainMenu__Item}>
        <NavLink
          to="/productivity"
          className={({ isActive }) =>
            classNames(CssClasses.MainMenu__Item__Link, {
              [CssClasses.MainMenu__Item__Link__Active]: isActive,
            })
          }
        >
          {({ isActive }) => (
            <>
              {isActive && (
                <SvgIcon
                  viewBox={null}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    fill: "none",
                    overflow: "visible",
                    strokeWidth: "2px",
                  }}
                >
                  <rect
                    width="100%"
                    height="100%"
                    rx="24px"
                    ry="24px"
                    stroke="url(#gradient)"
                  ></rect>
                  <linearGradient
                    id="gradient"
                    x1="0"
                    x2="1"
                    y1="0"
                    y2="0"
                    gradientTransform="rotate(30)"
                  >
                    <stop offset="0%" stopColor="hsla(0,0%,100%,.3)"></stop>
                    <stop offset="100%" stopColor="hsla(0,0%,100%,0)"></stop>
                  </linearGradient>
                </SvgIcon>
              )}

              <ListItemIcon>
                {!isActive ? (
                  <SvgIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.1103 11.1504H7.4603C6.8303 11.1504 6.32031 11.6603 6.32031 12.2903V17.4103H10.1103V11.1504V11.1504Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.7616 6.6001H11.2415C10.6115 6.6001 10.1016 7.11011 10.1016 7.74011V17.4001H13.8916V7.74011C13.8916 7.11011 13.3916 6.6001 12.7616 6.6001Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.5484 12.8501H13.8984V17.4001H17.6884V13.9901C17.6784 13.3601 17.1684 12.8501 16.5484 12.8501Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </SvgIcon>
                ) : (
                  <SvgIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.11 16.9C9.11 17.18 8.89 17.4 8.61 17.4H5.82C5.54 17.4 5.32 17.18 5.32 16.9V12.28C5.32 11.65 5.83 11.14 6.46 11.14H8.61C8.89 11.14 9.11 11.36 9.11 11.64V16.9ZM13.89 16.9C13.89 17.18 13.67 17.4 13.39 17.4H10.6C10.32 17.4 10.1 17.18 10.1 16.9V7.74C10.1 7.11 10.61 6.6 11.24 6.6H12.76C13.39 6.6 13.9 7.11 13.9 7.74V16.9H13.89ZM18.68 16.9C18.68 17.18 18.46 17.4 18.18 17.4H15.39C15.11 17.4 14.89 17.18 14.89 16.9V13.35C14.89 13.07 15.11 12.85 15.39 12.85H17.54C18.17 12.85 18.68 13.36 18.68 13.99V16.9Z"
                      fill={palette.text.default}
                    />
                  </SvgIcon>
                )}
              </ListItemIcon>
              <ListItemText primary="Productivity" />
            </>
          )}
        </NavLink>

        <List className={CssClasses.MainMenu}>
          <ListItem className={CssClasses.MainMenu__Item}>
            <NavLink
              to="/productivity/statistics"
              className={({ isActive }) =>
                classNames(CssClasses.MainMenu__Item__Link, {
                  [CssClasses.MainMenu__Item__Link__Active]: isActive,
                })
              }
            >
              <ListItemText primary="Statistics" />
            </NavLink>
          </ListItem>

          <ListItem className={CssClasses.MainMenu__Item}>
            <NavLink
              to="/productivity/transactions"
              className={({ isActive }) =>
                classNames(CssClasses.MainMenu__Item__Link, {
                  [CssClasses.MainMenu__Item__Link__Active]: isActive,
                })
              }
            >
              <ListItemText primary="Transactions" />
            </NavLink>
          </ListItem>
        </List>
      </ListItem>

      <ListItem className={CssClasses.MainMenu__Item}>
        <NavLink
          to="/billing"
          className={({ isActive }) =>
            classNames(CssClasses.MainMenu__Item__Link, {
              [CssClasses.MainMenu__Item__Link__Active]: isActive,
            })
          }
        >
          {({ isActive }) => (
            <>
              {isActive && (
                <SvgIcon
                  viewBox={null}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    fill: "none",
                    overflow: "visible",
                    strokeWidth: "2px",
                  }}
                >
                  <rect
                    width="100%"
                    height="100%"
                    rx="24px"
                    ry="24px"
                    stroke="url(#gradient)"
                  ></rect>
                  <linearGradient
                    id="gradient"
                    x1="0"
                    x2="1"
                    y1="0"
                    y2="0"
                    gradientTransform="rotate(30)"
                  >
                    <stop offset="0%" stopColor="hsla(0,0%,100%,.3)"></stop>
                    <stop offset="100%" stopColor="hsla(0,0%,100%,0)"></stop>
                  </linearGradient>
                </SvgIcon>
              )}

              <ListItemIcon>
                {!isActive ? (
                  <SvgIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 12V17C22 20 20 22 17 22H7C4 22 2 20 2 17V12C2 9.28 3.64 7.38 6.19 7.06C6.45 7.02 6.72 7 7 7H17C17.26 7 17.51 7.00999 17.75 7.04999C20.33 7.34999 22 9.26 22 12Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.7514 7.05C17.5114 7.01 17.2614 7.00001 17.0014 7.00001H7.00141C6.72141 7.00001 6.45141 7.02001 6.19141 7.06001C6.33141 6.78001 6.53141 6.52001 6.77141 6.28001L10.0214 3.02C11.3914 1.66 13.6114 1.66 14.9814 3.02L16.7314 4.79002C17.3714 5.42002 17.7114 6.22 17.7514 7.05Z"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 12.5H19C17.9 12.5 17 13.4 17 14.5C17 15.6 17.9 16.5 19 16.5H22"
                      stroke={palette.text.default}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </SvgIcon>
                ) : (
                  <SvgIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.4499 13.6396V14.6396C21.4499 14.9096 21.2399 15.1296 20.9599 15.1396H19.4999C18.9699 15.1396 18.4899 14.7496 18.4499 14.2296C18.4199 13.9196 18.5399 13.6296 18.7399 13.4296C18.9199 13.2396 19.1699 13.1396 19.4399 13.1396H20.9499C21.2399 13.1496 21.4499 13.3696 21.4499 13.6396Z"
                      fill={palette.text.default}
                    />
                    <path
                      d="M17.9892 12.6896C17.4892 13.1796 17.2492 13.9096 17.4492 14.6696C17.7092 15.5996 18.6192 16.1896 19.5792 16.1896H20.4492C20.9992 16.1896 21.4492 16.6396 21.4492 17.1896V17.3796C21.4492 19.4496 19.7592 21.1396 17.6892 21.1396H6.20922C4.13922 21.1396 2.44922 19.4496 2.44922 17.3796V10.6496C2.44922 9.41965 3.03922 8.32965 3.94922 7.64965C4.57922 7.16965 5.35922 6.88965 6.20922 6.88965H17.6892C19.7592 6.88965 21.4492 8.57965 21.4492 10.6496V11.0896C21.4492 11.6396 20.9992 12.0896 20.4492 12.0896H19.4292C18.8692 12.0896 18.3592 12.3096 17.9892 12.6896Z"
                      fill={palette.text.default}
                    />
                    <path
                      d="M16.1991 4.82C16.4691 5.09 16.2391 5.51 15.8591 5.51L8.17908 5.5C7.73908 5.5 7.50908 4.96 7.82908 4.65L9.44908 3.02C10.8191 1.66 13.0391 1.66 14.4091 3.02L16.1591 4.79C16.1691 4.8 16.1891 4.81 16.1991 4.82Z"
                      fill={palette.text.default}
                    />
                  </SvgIcon>
                )}
              </ListItemIcon>
              <ListItemText primary="Billing" />
            </>
          )}
        </NavLink>
      </ListItem>
    </List>
  );
};

export default MainMenu;
