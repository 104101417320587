/** @jsxImportSource @emotion/react */
import { ethers } from "ethers";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { css } from "@emotion/react";
import classNames from "classnames";

import { ThemeProvider } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { AUTH } from "../../config/RESTAPI/";
import { ThemeModeContext } from "../../config";
import { Alert, Caption, Logo } from "../";
import { _web3Modal } from "../../config";
import { signIn } from "../../config/slices/user";
import IconLogin from "../../images/icon-login.png";
import Sskoo from "../../images/sskoo.png";
import Image from "../../images/image.png";
import Hand from "../../images/hand.png";

import styles from "../Layout/Layout.module.scss";
import "./index.scss";

export default function SignIn() {
  const { theme } = useContext(ThemeModeContext);
  const [alert, setAlert] = useState(false);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleConnect = async () => {
    setSubmit(true);
    setAlert(false);

    const instance = await _web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider.getSigner();
    const wallet_address = await signer.getAddress();

    const { status, data } = await AUTH.SignIn(wallet_address);

    if (status === "success") {
      setSubmit(false);
      dispatch(signIn({ auth: true, ...data }));
      navigate("/");
    } else {
      setAlert({ type: status, message: data });
      setSubmit(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className={styles.radialGradients}>
        <div
          className={classNames(styles.radialGradient, styles.radialGradient1)}
        />
        <div
          className={classNames(styles.radialGradient, styles.radialGradient2)}
        />

        <div
          className={classNames(styles.radialGradient, styles.radialGradient3)}
        />

        <div
          className={classNames(styles.radialGradient, styles.radialGradient4)}
        />

        <div
          className={classNames(styles.radialGradient, styles.radialGradient5)}
        />

        <div
          className={classNames(styles.radialGradient, styles.radialGradient6)}
        />
      </div>

      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: 30.375,
        }}
      >
        <Box
          sx={{
            display: "flex",
            transform: "translateY(-25%)",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Paper variant="elevation3">
            <SvgIcon
              viewBox={null}
              sx={{
                position: "absolute",
                fill: "none",
                overflow: "visible",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            >
              <rect
                x="1"
                y="1"
                width="100%"
                height="100%"
                rx="63"
                stroke="url(#paint1_linear_1142_3568)"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
            </SvgIcon>

            <img
              src={Sskoo}
              alt=""
              css={css({
                position: "absolute",
                top: -76,
                left: -77,
                zIndex: -2,
              })}
            />

            <Box sx={{ display: "flex", justifyContent: "center", mb: 8 }}>
              <Logo />
            </Box>

            <Typography
              component="h3"
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 48,
                lineHeight: "72px",
                textAlign: "center",
                textTransform: "capitalize",
                color: "var(--default-text-color)",
                mb: 4.5,
              }}
            >
              Hello there!
            </Typography>

            <Typography
              component="p"
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 20,
                lineHeight: "30px",
                textAlign: "center",
                textTransform: "capitalize",
                color: "var(--default-text-color)",
              }}
            >
              Welcome to REminto Platform
            </Typography>
          </Paper>

          <Paper
            variant="elevation3"
            sx={{ transform: "translate(-15px, 50%)" }}
          >
            <Caption title="Login" Icon={<img src={IconLogin} alt="" />} />

            <SvgIcon
              viewBox={null}
              sx={{
                position: "absolute",
                fill: "none",
                overflow: "visible",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            >
              <rect
                x="1"
                y="1"
                width="100%"
                height="100%"
                rx="63"
                stroke="url(#paint1_linear_1142_3568)"
                strokeOpacity="0.3"
                strokeWidth="2"
              />
            </SvgIcon>

            <img
              src={Image}
              css={css({
                position: "absolute",
                bottom: -118,
                left: -224,
                zIndex: -2,
              })}
              alt=""
            />
            <img
              src={Hand}
              css={css({
                position: "absolute",
                top: -78,
                right: -104,
                zIndex: -2,
              })}
              alt=""
            />

            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 18,
                lineHeight: "27px",
                color: "var(--default-text-color)",
              }}
            >
              Click Continue below and connect your Web3 Wallet to get secure
              access to the platform
            </Typography>

            {alert && <Alert {...alert} />}

            <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <Button
                variant="default"
                onClick={handleConnect}
                disabled={submit}
              >
                Continue
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
