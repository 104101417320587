import { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import SvgIcon from "@mui/material/SvgIcon";

import { Formik, Form, Field, FieldArray } from "formik";
import { Contract } from "../../../config";
import { useLoaderData } from "react-router-dom";

const ReadVariable = () => {
  const data = useLoaderData();
  const [output, setOutput] = useState("");

  const variables = data.abi.filter((f) => f.stateMutability === "view");

  const handleChange = (value, setValues) => {
    const variable = variables.find(({ name }) => value === name);
    const params = {};

    variable?.inputs.forEach(({ name }) => (params[name] = ""));

    setOutput("");

    setValues({
      variable: value,
      params,
    });
  };

  const handleSubmit = async (values) => {
    const { contract_address, abi, network_id } = data;

    setOutput(
      await Contract.readProp({
        contract_address,
        abi,
        network_id,
        prop: values,
      })
    );
  };

  const initialValues = {
    variable: "",
    params: {},
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ errors, values, touched, setValues }) => (
          <Form>
            <Box sx={{ display: "flex", alignItems: "end" }}>
              <Field name="variable">
                {({ field }) => (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <InputLabel
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        ml: 4.5,
                        mb: "0.9375rem",
                        color: "#ADADAD",
                      }}
                    >
                      Select Variable
                    </InputLabel>

                    <Autocomplete
                      sx={{ width: 300, mr: 1 }}
                      options={variables.map(({ name }) => name)}
                      renderInput={({ InputProps, ...params }) => (
                        <TextField
                          variant="standard"
                          placeholder="Select Variable"
                          InputProps={{
                            ...InputProps,
                            disableUnderline: true,
                            startAdornment: (
                              <SvgIcon
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox={null}
                                sx={{
                                  fill: "none",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  pointerEvents: "none",
                                  overflow: "visible",
                                  zIndex: 10,
                                  stroke: "#ffffff",
                                }}
                              >
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="100%"
                                  height="100%"
                                  rx="31.5"
                                />
                              </SvgIcon>
                            ),
                          }}
                          {...params}
                          {...field}
                        />
                      )}
                      onChange={(e, value) => handleChange(value, setValues)}
                    />
                  </Box>
                )}
              </Field>

              <FieldArray name="params">
                {() =>
                  Object.keys(values.params).map((param) => (
                    <Field
                      name={`params.${param}`}
                      key={param}
                      validate={(value) =>
                        value?.toString().trim() === "" && "Error"
                      }
                    >
                      {({ field }) => (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <InputLabel
                            sx={{
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: 14,
                              lineHeight: "21px",
                              ml: 4.5,
                              mb: "0.9375rem",
                              color: "#ADADAD",
                              textTransform: "capitalize",
                            }}
                          >
                            {param}
                          </InputLabel>

                          <TextField
                            sx={{ mr: 1 }}
                            variant="standard"
                            placeholder={param}
                            {...field}
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <SvgIcon
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox={null}
                                  sx={{
                                    fill: "none",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    pointerEvents: "none",
                                    overflow: "visible",
                                    zIndex: 10,
                                    stroke: "#ffffff",
                                  }}
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="100%"
                                    height="100%"
                                    rx="31.5"
                                  />
                                </SvgIcon>
                              ),
                            }}
                            error={
                              touched.params?.[param] &&
                              Boolean(errors.params?.[param])
                            }
                          />
                        </Box>
                      )}
                    </Field>
                  ))
                }
              </FieldArray>

              <Box>
                <Button variant="default" type="submit" size="small">
                  Query
                </Button>
              </Box>
            </Box>

            <Typography variant="helper.text">
              Note: you can manage your smart contract here. These functions are
              only available to you as a contract owner.
            </Typography>
          </Form>
        )}
      </Formik>

      {output !== "" && (
        <Typography variant="accordion.title" sx={{ ml: 2 }}>Output: {output.toString()}</Typography>
      )}
    </>
  );
};

export default ReadVariable;
