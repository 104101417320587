import RESTAPI from "../";
import store from "../../store";

export default class TEMPLATE {
  static async GetList() {
    try {
      const { data } = await RESTAPI.axios.get(`api/templates`);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async SaveChanges() {
    try {
      const { components, usedTemplate, templates, campaignId } =
        store.getState().builder;
      const postData = {};

      Object.keys(components).forEach((component) => {
        postData[component] = { property: components[component].property };
      });

      const { data } = await RESTAPI.axios.post(`api/builder/save`, {
        blocks: postData,
        TemplateId: templates[usedTemplate].id,
        CampaignId: campaignId,
      });

      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }
}
