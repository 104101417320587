import { useContext } from "react";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import { Layout } from "./components";
import { ThemeModeContext } from "./config";

function App() {
  const { theme } = useContext(ThemeModeContext);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout />
    </ThemeProvider>
  );
}

export default App;
