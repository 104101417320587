import ChartJS from "react-apexcharts";
import { useTheme } from "@mui/material";

const Chart = ({ data }) => {
  const { palette } = useTheme();

  const options = {
    options: {
      chart: {
        toolbar: { show: false },
        foreColor: palette.text.default,
        fontFamily: "Poppins",
      },
      stroke: {
        width: 6,
        
      },
      xaxis: {
        categories: data.labels,
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "12px",
            lineHeight: "18px",
          },
        },
      },
      dataLabels: {
        style: {
          fontSize: "12px",
          fontFamily: "Popins, sans-serif",
          fontWeight: 500,
        },
        background: {
          color: "#fff",
          borderColor: "none",
          borderRadius: 24,
        },
      },
      legend: { show: false },
      grid: {
        show: true,
        borderColor: "rgba(255, 255, 255, .5)",
        position: "front",
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: ["#75E9F9", "#DEFA32", "#E966FF", "#9747FF"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.5,
          opacityFrom: 1,
          opacityTo: 0.5,
          stops: [0, 90, 100],
        },
      },
    },

    series: [...data.datasets.map((d) => ({ name: d.label, data: d.data }))],
    type: "area",
    width: "100%",
  };

  return <ChartJS {...options} />;
};

export default Chart;
