import { Link, useLoaderData } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SvgIcon from "@mui/material/SvgIcon";

import { Caption, Tile } from "../";
import IconChart from "../../images/icon-chart.png";

const Statistics = () => {
  const data = useLoaderData();

  const [clicks, views, sales] =
    data.length > 0
      ? data?.reduce(
          (prev, { views: clicks, inits: views, sales }) => [
            prev[0] + clicks,
            prev[1] + views,
            prev[2] + sales,
          ],
          [0, 0, 0]
        )
      : [0, 0, 0];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 6,
          mx: -1.5,
        }}
      >
        <Box sx={{ maxWidth: "25%", width: "100%", px: 1.5 }}>
          <Tile
            color={"233, 102, 255"}
            title={"Clicks"}
            value={clicks}
            Icon={
              <SvgIcon
                width="26"
                height="36"
                viewBox="0 0 26 36"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14.8508 0.0700219C14.5574 0.0260659 14.3 0.25831 14.3 0.555031V6.42431C14.3 6.61999 14.416 6.79471 14.5849 6.8936C15.5742 7.47304 16.2413 8.57737 16.2413 9.8477V13.4549C16.2413 15.3126 14.7853 16.8457 13 16.8457C11.1973 16.8457 9.74133 15.3126 9.74133 13.4549V9.8477C9.74133 8.57725 10.4231 7.47283 11.4151 6.89343C11.584 6.79474 11.7 6.61999 11.7 6.42431V0.5557C11.7 0.25874 11.4425 0.0264036 11.1488 0.0705998C8.30234 0.499007 5.75169 1.87885 3.81333 3.89579C1.456 6.3487 0 9.73948 0 13.4549V22.4729C0 29.9218 5.84133 36 13 36C20.1587 36 26 29.9218 26 22.4729V13.4549C26 6.65424 21.1382 1.01183 14.8508 0.0700219Z" />
              </SvgIcon>
            }
          />
        </Box>

        <Box sx={{ maxWidth: "25%", width: "100%", px: 1.5 }}>
          <Tile
            color={"151, 71, 255"}
            title={"Views"}
            value={views}
            Icon={
              <SvgIcon
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 8C30.764 8 37.186 11.9043 41.575 18.6853C43.475 21.6182 43.475 26.4005 41.575 29.3147C39.371 32.7145 36.673 35.3859 33.671 37.2166C30.669 39.0286 27.382 40 24 40C17.236 40 10.814 36.1144 6.425 29.3147C4.525 26.3818 4.525 21.6182 6.425 18.6853C8.629 15.2855 11.327 12.6141 14.329 10.7834C17.331 8.9714 20.618 8 24 8ZM16.324 24.0093C16.324 28.1751 19.744 31.5563 24 31.5563C28.256 31.5563 31.676 28.1751 31.676 24.0093C31.676 19.8435 28.256 16.4623 24 16.4623C19.744 16.4623 16.324 19.8435 16.324 24.0093ZM18.5862 24.0087C18.5862 21.0571 21.0182 18.666 24.0012 18.666C26.9842 18.666 29.4352 21.0758 29.4352 24.0087C29.4352 26.9416 26.9842 29.3327 24.0012 29.3327C21.0182 29.3327 18.5862 26.9416 18.5862 24.0087Z"
                />
              </SvgIcon>
            }
          />
        </Box>

        <Box sx={{ maxWidth: "25%", width: "100%", px: 1.5 }}>
          <Tile
            color={"117, 233, 249"}
            title={"Sales"}
            value={sales}
            Icon={
              <SvgIcon
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M43.0597 21.7566L40.019 18.7121C39.4988 18.1913 39.0787 17.1698 39.0787 16.4487V12.1222C39.0787 10.3595 37.6384 8.91738 35.8779 8.91738H31.5769C30.8567 8.91738 29.8364 8.49675 29.3163 7.97596L26.2755 4.9314C25.0352 3.68953 22.9947 3.68953 21.7544 4.9314L18.6736 7.97596C18.1735 8.49675 17.1533 8.91738 16.4131 8.91738H12.112C10.3516 8.91738 8.91122 10.3595 8.91122 12.1222V16.4286C8.91122 17.1497 8.49112 18.1713 7.97099 18.692L4.93023 21.7366C3.68992 22.9785 3.68992 25.0215 4.93023 26.2634L7.97099 29.308C8.49112 29.8287 8.91122 30.8503 8.91122 31.5714V35.8778C8.91122 37.6405 10.3516 39.0826 12.112 39.0826H16.4131C17.1333 39.0826 18.1535 39.5033 18.6736 40.024L21.7144 43.0686C22.9547 44.3105 24.9952 44.3105 26.2355 43.0686L29.2763 40.024C29.7964 39.5033 30.8167 39.0826 31.5368 39.0826H35.8379C37.5984 39.0826 39.0387 37.6405 39.0387 35.8778V31.5714C39.0387 30.8503 39.4588 29.8287 39.9789 29.308L43.0197 26.2634C44.32 25.0416 44.32 22.9985 43.0597 21.7566ZM15.993 18.011C15.993 16.9094 16.8932 16.008 17.9935 16.008C19.0938 16.008 19.994 16.9094 19.994 18.011C19.994 19.1127 19.1138 20.014 17.9935 20.014C16.8932 20.014 15.993 19.1127 15.993 18.011ZM19.0537 31.0906C18.7537 31.3911 18.3736 31.5313 17.9935 31.5313C17.6134 31.5313 17.2333 31.3911 16.9332 31.0906C16.3531 30.5098 16.3531 29.5483 16.9332 28.9675L28.9362 16.9494C29.5163 16.3686 30.4766 16.3686 31.0567 16.9494C31.6369 17.5303 31.6369 18.4917 31.0567 19.0726L19.0537 31.0906ZM29.9965 32.032C28.8762 32.032 27.976 31.1307 27.976 30.029C27.976 28.9274 28.8762 28.026 29.9765 28.026C31.0767 28.026 31.977 28.9274 31.977 30.029C31.977 31.1307 31.0967 32.032 29.9965 32.032Z" />
              </SvgIcon>
            }
          />
        </Box>

        <Box sx={{ maxWidth: "25%", width: "100%", px: 1.5 }}>
          <Tile
            color={"222, 250, 50"}
            title={"ACR"}
            value={+(+sales / +views).toFixed(2) || 0}
            Icon={
              <SvgIcon
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.2319 38.4497V10.4C20.2319 8.53 20.9854 7 23.2459 7H24.7528C27.0133 7 27.7668 8.53 27.7668 10.4V38.4497H32.3721V29.0997C32.3721 27.2297 33.0419 25.6997 35.0512 25.6997H36.3907C38.4 25.6997 39.0698 27.2297 39.0698 29.0997V38.4497H40.7442C41.4307 38.4497 42 39.0277 42 39.7247C42 40.4217 41.4307 40.9997 40.7442 40.9997H39.0698V40.9997H32.3721V40.9997H27.7668V41H20.2319V40.9997H15.6273V41.0002H8.92958V40.9997H7.25581C6.5693 40.9997 6 40.4217 6 39.7247C6 39.0277 6.5693 38.4497 7.25581 38.4497H8.92958V20.6002C8.92958 18.7302 9.59935 17.2002 11.6086 17.2002H12.9482C14.9575 17.2002 15.6273 18.7302 15.6273 20.6002V38.4497H20.2319Z"
                />
              </SvgIcon>
            }
          />
        </Box>
      </Box>

      <Box>
        <Caption title={"Most Visited"} Icon={<img src={IconChart} alt="" />} />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campaign Name</TableCell>
              <TableCell>Clicks</TableCell>
              <TableCell>Views</TableCell>
              <TableCell>Sales</TableCell>
              <TableCell>Conversion Rate</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length > 0 &&
              data.map(({ id, name, views: clicks, inits: views, sales }) => (
                <TableRow
                  key={id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{name}</TableCell>
                  <TableCell>{clicks}</TableCell>
                  <TableCell>{views}</TableCell>
                  <TableCell>{sales}</TableCell>
                  <TableCell>{+(+sales / +views).toFixed(2) || 0}</TableCell>
                  <TableCell>
                    <IconButton
                      component={Link}
                      to={`/productivity/statistics/${id}`}
                    >
                      <SvgIcon
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        sx={{ fill: "var(--default-text-color)" }}
                      >
                        <path d="M18.3346 18.9587H4.16797C2.44297 18.9587 1.04297 17.5587 1.04297 15.8337V1.66699C1.04297 1.32533 1.3263 1.04199 1.66797 1.04199C2.00964 1.04199 2.29297 1.32533 2.29297 1.66699V15.8337C2.29297 16.867 3.13464 17.7087 4.16797 17.7087H18.3346C18.6763 17.7087 18.9596 17.992 18.9596 18.3337C18.9596 18.6753 18.6763 18.9587 18.3346 18.9587Z" />
                        <path d="M4.16782 14.7922C4.02615 14.7922 3.87615 14.7422 3.75949 14.6422C3.50115 14.4172 3.46782 14.0255 3.69282 13.7588L7.51782 9.29217C7.93449 8.80884 8.53449 8.51717 9.16782 8.49217C9.80115 8.4755 10.4262 8.70884 10.8762 9.15884L11.6678 9.9505C11.8762 10.1588 12.1428 10.2588 12.4428 10.2588C12.7345 10.2505 13.0012 10.1172 13.1928 9.89217L17.0178 5.4255C17.2428 5.16717 17.6345 5.13384 17.9012 5.35884C18.1595 5.58384 18.1928 5.9755 17.9678 6.24217L14.1428 10.7088C13.7262 11.1922 13.1262 11.4838 12.4928 11.5088C11.8512 11.5255 11.2345 11.2922 10.7845 10.8422L10.0012 10.0505C9.79282 9.84217 9.51782 9.73384 9.22615 9.74217C8.93449 9.7505 8.66782 9.88384 8.47615 10.1088L4.65115 14.5755C4.51782 14.7172 4.34282 14.7922 4.16782 14.7922Z" />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Statistics;
