import { useLoaderData } from "react-router-dom";

import { Caption, FieldContract, Steps } from "../";
import IconBag from "../../images/icon-bag.png";

const ManageContract = () => {
  const { flg_publish, flg_verified } = useLoaderData();

  return (
    <>
      <Caption
        title={"Manage Smart Contract"}
        Icon={<img src={IconBag} alt="" />}
      />

      {!flg_publish || !flg_verified ? <Steps /> : <FieldContract />}
    </>
  );
};

export default ManageContract;
