import { Field } from "react-final-form";
import PropTypes from "prop-types";

import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";

export default function Checkbox({
  name,
  label,
  type = "checkbox",
  helperText,
}) {
  return (
    <Box sx={{ mb: 1 }}>
      <Field name={name} type={type}>
        {({ input }) => (
          <FormControlLabel
            control={
              <MuiCheckbox defaultChecked={input.value} id={name} {...input} />
            }
            label={label}
          />
        )}
      </Field>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
}

Checkbox.defaultProps = {
  helperText: null,
};

Checkbox.propTypes = {
  type: PropTypes.oneOf(["checkbox", "radio"]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
