import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { usedTemplate } from "../../../../config/slices/builder";

export default function ChooseTemplate() {
  const { templates } = useSelector((state) => state.builder);
  const dispatch = useDispatch();

  const handleUseTemplate = (templateId) => dispatch(usedTemplate(templateId));

  return (
    <Dialog open fullWidth maxWidth="xl">
      <DialogTitle>Choose your template</DialogTitle>

      <DialogContent sx={{ display: "flex", px: 2 }}>
        {templates.map(({ id, thumb, name, description }, index) => (
          <Card
            sx={{ maxWidth: 345, width: "100%", m: 1 }}
            key={`Template.${name}.${id}`}
          >
            <CardMedia sx={{ height: 140 }} image={thumb} title={name} />

            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {name}
              </Typography>

              {description && (
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              )}
            </CardContent>

            <CardActions sx={{ justifyContent: "center", mb: 2 }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleUseTemplate(index)}
              >
                Select
              </Button>

              <Button
                size="small"
                variant="contained"
                component="a"
                href={`https://nftulla.com/builder/${name.toLowerCase()}/preview`}
                target="_blank"
              >
                Preview
              </Button>
            </CardActions>
          </Card>
        ))}
      </DialogContent>
    </Dialog>
  );
}
