import { useSelector } from "react-redux";
import { AiTwotoneEdit } from "react-icons/ai";
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

import { FormEl } from "../";

const TemplateVariables = () => {
  const { editableComponent } = useSelector((state) => state.builder);

  if (!editableComponent) {
    return (
      <Typography>
        <AiTwotoneEdit
          css={css({ color: "#3b97e3", fontSize: 18, marginRight: 10 })}
        />
        Select Component to Editing
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="button" component={"h5"} className="my-3">
        {editableComponent}
      </Typography>

      <FormEl />
    </>
  );
};

export default TemplateVariables;
