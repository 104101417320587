import React from "react";

export const UserImagesContext = React.createContext({});

export class UserImagesContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      cb: null,
      handleOpen: this.handleOpen.bind(this),
      handleSelectImage: (image) => {
        this.handleClose();
        typeof this.state.cb === "function" && this.state.cb(image);
      },
      handleClose: this.handleClose.bind(this),
    };
  }

  handleOpen(cb = null) {
    this.setState({ open: true, cb });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <UserImagesContext.Provider value={this.state}>
        {this.props.children}
      </UserImagesContext.Provider>
    );
  }
}
