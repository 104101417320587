import { useState } from "react";
// import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
// import Alert from "@mui/material/Alert";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// import { store } from "../../config";
import { Caption } from "..";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import IconBag from "../../images/icon-bag.png";

export default function CreateCampaign() {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});

  // const { billing } = store.getState().user;

  return (
    <>
      <Caption title="Create Campaign" Icon={<img src={IconBag} alt="" />} />

      {/* {(+billing.widget || 0) + (+billing.hosting || 0) === 0 && (
        <Alert severity="warning">
          Oups, it looks like you can't create campaigns right now. Select which
          type of a campaign you would like to create, and proceed to{" "}
          <Link to="/billing">Billing</Link> to pay for it.
        </Alert>
      )} */}

      <Box>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>Step</StepLabel>
          </Step>
          <Step>
            <StepLabel>Step</StepLabel>
          </Step>
          <Step>
            <StepLabel>Step</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 ? (
          <Step1
            nextStepCallback={(values) => {
              setFormData({ ...formData, ...values });
              setActiveStep(activeStep + 1);
            }}
          />
        ) : activeStep === 1 ? (
          <Step2
            backStepCallback={() => setActiveStep(activeStep - 1)}
            nextStepCallback={(values) => {
              setFormData({ ...formData, ...values });
              setActiveStep(activeStep + 1);
            }}
            formData={formData}
          />
        ) : (
          <Step3
            backStepCallback={() => setActiveStep(activeStep - 1)}
            nextStepCallback={() => setActiveStep(activeStep + 1)}
            formData={formData}
          />
        )}
      </Box>
    </>
  );
}
