import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";

import { Footer, Header, MainMenu } from "..";
import styles from "./Layout.module.scss";
import classNames from "classnames";

const Layout = () => {
  return (
    <>
      <div className={styles.radialGradients}>
        <div
          className={classNames(styles.radialGradient, styles.radialGradient1)}
        />
        <div
          className={classNames(styles.radialGradient, styles.radialGradient2)}
        />

        <div
          className={classNames(styles.radialGradient, styles.radialGradient3)}
        />

        <div
          className={classNames(styles.radialGradient, styles.radialGradient4)}
        />

        <div
          className={classNames(styles.radialGradient, styles.radialGradient5)}
        />

        <div
          className={classNames(styles.radialGradient, styles.radialGradient6)}
        />
      </div>

      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Header />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            px: 8,
            flexGrow: 1,
          }}
        >
          <Box sx={{ mr: 12.375 }}>
            <MainMenu />
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Outlet />
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  );
};

export default Layout;
