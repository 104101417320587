import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";

import { GiGears } from "react-icons/gi";
import { FaLayerGroup } from "react-icons/fa";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  changeActiveTab,
  toggleSavedState,
} from "../../../../config/slices/builder";
import { TemplateLayers, TemplateVariables } from "../";
import { Templates } from "../../templates";
import { Alert, Snackbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { TEMPLATE } from "../../../../config/RESTAPI/";

const Template = () => {
  const { usedTemplate, templates } = useSelector((state) => state.builder);
  const { name } = templates[usedTemplate];

  return Templates[name]();
};

export default function LoadTemplate() {
  const { activeTab, savedState } = useSelector((state) => state.builder);
  const dispatch = useDispatch();

  const [isSaving, setSaving] = useState(false);
  const [snackbarShow, setSnackbarShow] = useState(false);

  const handleSaveChanges = () => {
    setSaving(true);

    TEMPLATE.SaveChanges().then((data) => {
      if (data === "OK") {
        setSaving(false);
        setSnackbarShow(true);
        dispatch(toggleSavedState());
      }
    });
  };

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarShow(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Snackbar
        autoHideDuration={2000}
        open={snackbarShow}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert security="success">Successfully saved</Alert>
      </Snackbar>

      <Box
        sx={{
          py: 1,
          display: "flex",
          justifyContent: "space-between",
          px: 2,
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            "& > a": {
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              color: "#000",
            },
          }}
        >
          <Link to="/campaigns">
            <ArrowBackIcon /> Back
          </Link>
        </Typography>

        <Button
          variant="contained"
          disabled={savedState || isSaving}
          onClick={handleSaveChanges}
        >
          {isSaving ? "Saving changes..." : "Save Changes"}
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xl={10} sx={{ position: "relative", overflowY: "auto" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
            >
              <Template />
            </Box>
          </Grid>

          <Grid item xl={2} sx={{ position: "relative", overflowY: "auto" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
            >
              <TabContext value={activeTab}>
                <TabList
                  onChange={(_, activeTab) =>
                    dispatch(changeActiveTab(activeTab))
                  }
                >
                  <Tab icon={<GiGears />} value="settings" />
                  <Tab icon={<FaLayerGroup />} value="layers" />
                </TabList>

                <TabPanel
                  value="settings"
                  sx={{ flexGrow: 1, overflowY: "auto" }}
                >
                  <TemplateVariables />
                </TabPanel>
                <TabPanel
                  value="layers"
                  sx={{ flexGrow: 1, overflowY: "auto" }}
                >
                  <TemplateLayers />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
