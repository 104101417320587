import RESTAPI from "../";

export default class BUILDER {
  static async GetConfig(campaignId) {
    try {
      const { data } = await RESTAPI.axios.get(
        `api/builder/${campaignId}/config`
      );
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }
}
