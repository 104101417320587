import RESTAPI from "../";

export default class CATEGORY {
  static async GetList() {
    try {
      const { data } = await RESTAPI.axios.get(`api/categories`);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }
}
