import { useState } from "react";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

export default function ImagePanel({ isMain = false, onSetMain, onDelete }) {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        p: 1,
        position: "absolute",
        top: 12,
        right: 24,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        background:
          "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
        backdropFilter: "blur(10px)",
        borderRadius: 3,
      }}
    >
      <SvgIcon
        viewBox={null}
        sx={{
          fill: "none",
          position: "absolute",
          pointerEvents: "none",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <rect
          x="0.5"
          y="0.5"
          width="100%"
          height="100%"
          rx="11.5"
          stroke="url(#paint1_linear_1009_2916)"
          strokeOpacity="0.3"
        />
      </SvgIcon>

      <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => setOpen(!open)}
        sx={{
          fill: "none",
          path: {
            fill: open ? "var(--default-text-color)" : undefined,
            stroke: !open ? "var(--default-text-color)" : undefined,
            strokeWidth: 1.5,
          },
        }}
      >
        <path d="M20.091 9.702C18.2819 9.702 17.5422 8.41372 18.4418 6.83356C18.9615 5.91767 18.6517 4.75016 17.7421 4.22679L16.013 3.23038C15.2234 2.75734 14.2039 3.03915 13.7341 3.83427L13.6242 4.0255C12.7246 5.60566 11.2454 5.60566 10.3358 4.0255L10.2259 3.83427C9.77611 3.03915 8.75662 2.75734 7.96702 3.23038L6.23788 4.22679C5.32834 4.75016 5.01849 5.92773 5.53823 6.84362C6.44778 8.41372 5.70815 9.702 3.89905 9.702C2.85957 9.702 2 10.5575 2 11.6143V13.3857C2 14.4324 2.84958 15.298 3.89905 15.298C5.70815 15.298 6.44778 16.5863 5.53823 18.1664C5.01849 19.0823 5.32834 20.2498 6.23788 20.7732L7.96702 21.7696C8.75662 22.2427 9.77611 21.9608 10.2459 21.1657L10.3558 20.9745C11.2554 19.3943 12.7346 19.3943 13.6442 20.9745L13.7541 21.1657C14.2239 21.9608 15.2434 22.2427 16.033 21.7696L17.7621 20.7732C18.6717 20.2498 18.9815 19.0723 18.4618 18.1664C17.5522 16.5863 18.2919 15.298 20.101 15.298C21.1404 15.298 22 14.4425 22 13.3857V11.6143C21.99 10.5676 21.1404 9.702 20.091 9.702ZM11.995 15.771C10.2059 15.771 8.74663 14.3016 8.74663 12.5C8.74663 10.6984 10.2059 9.22896 11.995 9.22896C13.7841 9.22896 15.2434 10.6984 15.2434 12.5C15.2434 14.3016 13.7841 15.771 11.995 15.771Z" />
      </SvgIcon>

      {open && (
        <>
          <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
              fill: "none",
              mt: 1,
              path: {
                fill: isMain ? "var(--default-text-color)" : undefined,
                stroke: !isMain ? "var(--default-text-color)" : undefined,
                strokeWidth: 1.5,
              },
            }}
            onClick={!isMain ? onSetMain : undefined}
          >
            <path d="M13.7276 3.37197L15.4874 6.74323C15.7274 7.21253 16.3673 7.66267 16.9073 7.74886L20.0969 8.25647C22.1367 8.5821 22.6167 9.99956 21.1468 11.3979L18.6671 13.7731C18.2471 14.1753 18.0172 14.9511 18.1471 15.5066L18.8571 18.4469C19.417 20.7742 18.1271 21.6745 15.9774 20.4581L12.9877 18.7629C12.4478 18.4564 11.5579 18.4564 11.0079 18.7629L8.01827 20.4581C5.8785 21.6745 4.57865 20.7646 5.13859 18.4469L5.84851 15.5066C5.97849 14.9511 5.74852 14.1753 5.32856 13.7731L2.84884 11.3979C1.389 9.99956 1.85895 8.5821 3.89872 8.25647L7.08837 7.74886C7.61831 7.66267 8.25824 7.21253 8.49821 6.74323L10.258 3.37197C11.2179 1.54268 12.7777 1.54268 13.7276 3.37197Z" />
          </SvgIcon>

          <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ stroke: "var(--default-text-color)", fill: "none", mt: 1 }}
            onClick={onDelete}
          >
            <path
              d="M20.5 6.50004C20.5 6.50004 13.6911 6 10.5367 6C8.66667 6 3.5 6.5 3.5 6.5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 5.5L8.72 3.67677C8.88 2.71717 9 2 10.69 2H13.31C15 2 15.13 2.75758 15.28 3.68687L15.5 6"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 9L17.4307 18.3966C17.3343 19.8616 17.2555 21 14.8117 21H9.18832C6.74453 21 6.66569 19.8616 6.56934 18.3966L6 9"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 17H13.5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.5 13H14.5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </SvgIcon>
        </>
      )}
    </Box>
  );
}
