import { Field, Form, Formik } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import SvgIcon from "@mui/material/SvgIcon";

import { store } from "../../../config";
import IconLamp from "../../../images/icon-lamp.png";

const Step1 = ({ nextStepCallback }) => {
  const { billing } = store.getState().user;
  const initialValues = {
    type_campaign: "1",
    // type_campaign: +billing.widget > 0 ? "1" : +billing.hosting > 0 ? "2" : "0",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => nextStepCallback(values)}
    >
      <Form>
        <Paper
          variant="elevation2"
          sx={{
            maxWidth: 682,
            width: "100%",
            m: "auto",
            mb: 8,
          }}
        >
          <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={null}
            sx={{
              fill: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "visible",
              pointerEvents: "none",
            }}
          >
            <rect
              x="0.5"
              y="0.5"
              width="100%"
              height="100%"
              rx="55.5"
              stroke="url(#paint1_linear_979_1988)"
              strokeOpacity="0.3"
            />
            <linearGradient
              id="paint1_linear_979_1988"
              x1="234.068"
              y1="-16.1879"
              x2="652.316"
              y2="402.585"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </SvgIcon>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography variant="h6" sx={{ mr: 5.5 }}>
              I would like to create an affiliate campaign
            </Typography>

            <img src={IconLamp} alt="" />
          </Box>

          <FormControl
            sx={{ maxWidth: 442, width: "100%", m: "auto", display: "flex" }}
          >
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              defaultValue={1}
              // defaultValue={
              //   +billing.widget > 0 ? 1 : +billing.hosting > 0 ? 2 : 0
              // }
            >
              <Box sx={{ mb: 4.5 }}>
                <Field name="type_campaign">
                  {({ field }) => (
                    <FormControlLabel
                      {...field}
                      value={1}
                      // disabled={(+billing.widget || 0) === 0}
                      control={<Radio />}
                      label="and run it on REminto Marketplace"
                    />
                  )}
                </Field>

                <Typography variant="caption" component={"p"}>
                  Note: this will let you create a smart contract for your
                  NFT(s) and promote your campaign on REminto marketplace. You
                  will also get a Mint widget you could optionally host on your
                  own website.
                </Typography>
              </Box>

              <Box>
                <Field name="type_campaign">
                  {({ field }) => (
                    <FormControlLabel
                      {...field}
                      value={2}
                      disabled={(+billing.hosting || 0) === 0}
                      control={<Radio />}
                      label="and create a custom hosted Mint page"
                    />
                  )}
                </Field>

                <Typography variant="caption" component="p">
                  Note: you will be able to show your campaign on REminto
                  marketplace and create a custom Mint page with REminto
                  Builder. Your page will be hosted at{" "}
                  <Typography
                    component="span"
                    sx={{ color: "#9747FF", fontSize: "inherit" }}
                  >
                    https://nftulla.com/
                  </Typography>
                </Typography>
              </Box>
            </RadioGroup>
          </FormControl>
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="default"
            size="large"
            type="submit"
            disabled={true}
            sx={{ fontSize: 20, lineHeight: "30px", mx: 1.75 }}
          >
            Back
          </Button>

          <Button
            variant="default"
            // disabled={(+billing.widget || 0) + (+billing.hosting || 0) === 0}
            type="submit"
            sx={{ fontSize: 20, lineHeight: "30px", mx: 1.75 }}
          >
            Next
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};

export default Step1;
