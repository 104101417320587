import TextField from "./TextField";
import Checkbox from "./Checkbox";
import Select from "./Select";
import List from "./List";
import TextArea from "./TextArea";
import CustomField from "./CustomField";
import Image from "./Image";

const Elements = {
  TextField,
  Checkbox,
  Select,
  List,
  TextArea,
  CustomField,
  Image,
};

export default Elements;
export { TextField, Checkbox, Select, List, TextArea, CustomField, Image };
