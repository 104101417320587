import { ethers, providers, utils } from "ethers";
import { abi, config, _web3Modal } from "./";
import { BILLING } from "./RESTAPI/";

const TYPE = {
  WIDGET: 1,
  HOSTING: 2,
};

export default class Billing {
  static async getCosts(developer_mode = false) {
    const {
      contract_address: { devmode, prodmode },
      etherscan_api,
    } = config;

    const contract = new ethers.Contract(
      developer_mode ? devmode : prodmode,
      abi,
      new providers.EtherscanProvider(developer_mode ? 5 : 1, etherscan_api)
    );

    const costs = {};

    (await contract.getCosts(Object.values(TYPE))).forEach(
      (cost, i) => (costs[i + 1] = cost)
    );

    return costs;
  }

  /**
   * Sending request to change networkID
   *
   * @param {int} networkVersion
   * @returns <Promise>
   */
  static switchNetwork(networkVersion) {
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: utils.hexValue(networkVersion) }],
    });
  }

  static async pay({ order, amount }) {
    if (+window.ethereum.networkVersion !== 1) {
      try {
        await Billing.switchNetwork(1);
      } catch (e) {
        return { result: false, message: e.message };
      }
    }

    const _order = [];
    const { widget, hosting, widget_count, hosting_count } = order;

    if (widget) {
      _order.push([1, widget_count]);
    }

    if (hosting) {
      _order.push([2, hosting_count]);
    }

    const instance = await _web3Modal.connect();
    const provider = new providers.Web3Provider(instance);

    const contract = new ethers.Contract(
      config.contract_address.devmode,
      abi,
      provider.getSigner()
    );

    try {
      const transaction = await contract.buy(_order, { value: amount });
      const response = await transaction.wait();

      await BILLING.Add({ hash: transaction.hash });

      if (response.status === 1) {
        return true;
      }

      return { result: false, message: "" };
    } catch (e) {
      return { result: false, message: e?.reason };
    }
  }
}
