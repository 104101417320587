import loadable from "@loadable/component";
import PropTypes from "prop-types";

import { FieldArray } from "react-final-form-arrays";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const LoadableComponent = loadable(() => import("../../Elements"), {
  resolveComponent: (components, props) => components[props.component],
});

export default function List({ name, item, label, helperText }) {
  const _fields = Object.keys(item);

  const onDragEnd = (fields) => (result) => {
    if (!result.destination) {
      return;
    }

    fields.move(result.source.index, result.destination.index);
  };

  return (
    <>
      <Typography variant="caption" sx={{ mb: 2 }} component={"h5"}>
        {label}
      </Typography>

      <FieldArray name={name}>
        {({ fields }) => (
          <DragDropContext onDragEnd={onDragEnd(fields)}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field_name, index) => (
                    <Draggable
                      key={`${name}.${index}`}
                      draggableId={`${name}.${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          sx={{
                            position: "relative",
                            border: "1px dashed #ccc",
                            padding: "0.5rem",
                            pt: 2,
                            mb: 3,
                            "&:before": {
                              display: "block",
                              position: "absolute",
                              top: -12,
                              padding: "0 .5rem",
                              background: "#ffffff",
                              content: `'#${index + 1}'`,
                            },
                          }}
                          key={`${field_name}.${index}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <IconButton
                            size="small"
                            color="error"
                            sx={{
                              position: "absolute",
                              right: 0,
                              top: -18,
                            }}
                            onClick={() => fields.remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>

                          {_fields.map((field) => (
                            <LoadableComponent
                              key={`${field}.${index}`}
                              name={`${field_name}.${field}`}
                              {...item[field]}
                            />
                          ))}
                        </Box>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => fields.push()} variant="contained">
                      Add New Item
                    </Button>
                  </Box>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </FieldArray>

      {helperText && <Typography>{helperText}</Typography>}
    </>
  );
}

List.defaultProps = {
  helperText: null,
};

List.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
