import { useLoaderData } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import ReadVariable from "./ReadVariable";
import WriteVariable from "./WriteVariable";
import { NETWORKS } from "../../config";

export default function FieldContract() {
  const { contract_address, network_id } = useLoaderData();
  const { URL } = NETWORKS?.[network_id];

  return (
    <>
      <Box>
        <Alert severity="success">
          Smart Contract Address:{" "}
          <a
            href={`${URL}address/${contract_address}`}
            target="_blank"
            rel="noreferrer"
          >
            {contract_address}
          </a>
        </Alert>
      </Box>

      <Box>
        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            <Typography variant="accordion.title">Read Contract</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: "flex", alignItems: "center" }}>
            <ReadVariable />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            <Typography variant="accordion.title">Write Contract</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <WriteVariable />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
