import { Link, useLoaderData } from "react-router-dom";
import { BigNumber, utils } from "ethers";
import moment from "moment";

import SvgIcon from "@mui/material/SvgIcon";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Chart, IconCounter, Tile } from "..";

const Dashboard = () => {
  const data = useLoaderData();

  const sales =
    data.sales?.reduce(
      (accumulator, { value }) => accumulator.add(value),
      BigNumber.from(0)
    ) || 0;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 8,
          mx: -1.5,
        }}
      >
        <Box sx={{ maxWidth: "25%", width: "100%", px: 1.5 }}>
          <Tile
            color={"233, 102, 255"}
            title={"Campaigns"}
            value={data.campaigns?.length || 0}
            Icon={
              <SvgIcon
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M42.1801 13.96C40.4801 12.08 37.6401 11.14 33.5201 11.14H33.0401V11.06C33.0401 7.70004 33.0401 3.54004 25.5201 3.54004H22.4801C14.9601 3.54004 14.9601 7.72004 14.9601 11.06V11.16H14.4801C10.3401 11.16 7.52008 12.1 5.82008 13.98C3.84008 16.18 3.90007 19.14 4.10007 21.16L4.12007 21.3L4.29753 23.1633C4.31179 23.3131 4.3922 23.4481 4.51782 23.5309C4.93737 23.8074 5.92369 24.4509 6.48007 24.76C6.76007 24.94 7.06008 25.1 7.36007 25.26C10.7801 27.14 14.5401 28.4 18.3601 29.02C18.5401 30.9 19.3601 33.1 23.7401 33.1C28.1201 33.1 28.9801 30.92 29.1201 28.98C33.2001 28.32 37.1401 26.9 40.7001 24.82C40.8201 24.76 40.9001 24.7 41.0001 24.64C41.8564 24.156 42.7473 23.5508 43.5439 22.9694C43.6565 22.8872 43.7288 22.7613 43.7442 22.6228L43.8001 22.12L43.9001 21.18C43.9201 21.06 43.9201 20.96 43.9401 20.82C44.1001 18.8 44.0601 16.04 42.1801 13.96ZM26.1801 27.66C26.1801 29.78 26.1801 30.1 23.7201 30.1C21.2601 30.1 21.2601 29.72 21.2601 27.68V25.16H26.1801V27.66ZM17.8201 11.14V11.06C17.8201 7.66004 17.8201 6.40004 22.4801 6.40004H25.5201C30.1801 6.40004 30.1801 7.68004 30.1801 11.06V11.16H17.8201V11.14Z" />
                <path d="M42.5564 27.0796C42.9088 26.9088 43.314 27.1892 43.2786 27.5792L42.4797 36.3799C42.0597 40.3799 40.4197 44.4599 31.6197 44.4599H16.3797C7.57969 44.4599 5.93969 40.3799 5.51969 36.3999L4.75898 28.0321C4.72393 27.6466 5.11919 27.3663 5.47078 27.5281C7.45987 28.4438 12.7173 30.8005 15.6163 31.4996C15.7814 31.5395 15.9147 31.6566 15.9806 31.8132C17.1403 34.57 19.8181 36.0399 23.7397 36.0399C27.6224 36.0399 30.3368 34.5135 31.4987 31.7534C31.5646 31.5967 31.6977 31.4796 31.863 31.4398C34.9332 30.6989 40.4892 28.0809 42.5564 27.0796Z" />
              </SvgIcon>
            }
          />
        </Box>

        <Box sx={{ maxWidth: "25%", width: "100%", px: 1.5 }}>
          <Tile
            color={"151, 71, 255"}
            title={"Affiliates"}
            value={data.affiliates?.length || 0}
            Icon={
              <SvgIcon
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M35.0601 15.54C34.9201 15.52 34.7801 15.52 34.6401 15.54C31.5401 15.44 29.0801 12.9 29.0801 9.78C29.0801 6.6 31.6601 4 34.8601 4C38.0401 4 40.6401 6.58 40.6401 9.78C40.6201 12.9 38.1601 15.44 35.0601 15.54Z" />
                <path d="M41.5792 29.3998C39.3392 30.8998 36.1992 31.4598 33.2992 31.0798C34.0592 29.4398 34.4592 27.6198 34.4792 25.6998C34.4792 23.6998 34.0392 21.7998 33.1992 20.1398C36.1592 19.7398 39.2992 20.2998 41.5592 21.7998C44.7192 23.8798 44.7192 27.2998 41.5792 29.3998Z" />
                <path d="M12.8803 15.54C13.0203 15.52 13.1603 15.52 13.3003 15.54C16.4003 15.44 18.8603 12.9 18.8603 9.78C18.8603 6.58 16.2803 4 13.0803 4C9.90031 4 7.32031 6.58 7.32031 9.78C7.32031 12.9 9.78031 15.44 12.8803 15.54Z" />
                <path d="M13.1002 25.7002C13.1002 27.6402 13.5202 29.4802 14.2802 31.1402C11.4602 31.4402 8.52023 30.8402 6.36023 29.4202C3.20023 27.3202 3.20023 23.9002 6.36023 21.8002C8.50023 20.3602 11.5202 19.7802 14.3602 20.1002C13.5402 21.7802 13.1002 23.6802 13.1002 25.7002Z" />
                <path d="M24.2396 31.74C24.0796 31.72 23.8996 31.72 23.7196 31.74C20.0396 31.62 17.0996 28.6 17.0996 24.88C17.1196 21.08 20.1796 18 23.9996 18C27.7996 18 30.8796 21.08 30.8796 24.88C30.8596 28.6 27.9396 31.62 24.2396 31.74Z" />
                <path d="M17.7396 35.8803C14.7196 37.9003 14.7196 41.2203 17.7396 43.2203C21.1796 45.5203 26.8196 45.5203 30.2596 43.2203C33.2796 41.2003 33.2796 37.8803 30.2596 35.8803C26.8396 33.5803 21.1996 33.5803 17.7396 35.8803Z" />
              </SvgIcon>
            }
          />
        </Box>

        <Box sx={{ maxWidth: "25%", width: "100%", px: 1.5 }}>
          <Tile
            color={"117, 233, 249"}
            title={"Sales, ETH"}
            value={+utils.formatEther(sales)}
            Icon={
              <SvgIcon
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M43.0598 21.7398L40.0198 18.6998C39.4998 18.1798 39.0798 17.1598 39.0798 16.4398V12.1198C39.0798 10.3598 37.6398 8.91976 35.8798 8.91976H31.5798C30.8598 8.91976 29.8398 8.49976 29.3198 7.97977L26.2798 4.93977C25.0398 3.69977 22.9998 3.69977 21.7598 4.93977L18.6798 7.97977C18.1798 8.49976 17.1598 8.91976 16.4198 8.91976H12.1198C10.3598 8.91976 8.91977 10.3598 8.91977 12.1198V16.4198C8.91977 17.1398 8.49977 18.1598 7.97977 18.6798L4.93977 21.7198C3.69977 22.9598 3.69977 24.9998 4.93977 26.2398L7.97977 29.2798C8.49977 29.7998 8.91977 30.8198 8.91977 31.5398V35.8398C8.91977 37.5998 10.3598 39.0398 12.1198 39.0398H16.4198C17.1398 39.0398 18.1598 39.4598 18.6798 39.9798L21.7198 43.0198C22.9598 44.2598 24.9998 44.2598 26.2398 43.0198L29.2798 39.9798C29.7998 39.4598 30.8198 39.0398 31.5398 39.0398H35.8398C37.5998 39.0398 39.0398 37.5998 39.0398 35.8398V31.5398C39.0398 30.8198 39.4598 29.7998 39.9798 29.2798L43.0198 26.2398C44.3198 25.0198 44.3198 22.9798 43.0598 21.7398ZM15.9998 17.9998C15.9998 16.8998 16.8998 15.9998 17.9998 15.9998C19.0998 15.9998 19.9998 16.8998 19.9998 17.9998C19.9998 19.0998 19.1198 19.9998 17.9998 19.9998C16.8998 19.9998 15.9998 19.0998 15.9998 17.9998ZM19.0598 31.0598C18.7598 31.3598 18.3798 31.4998 17.9998 31.4998C17.6198 31.4998 17.2398 31.3598 16.9398 31.0598C16.3598 30.4798 16.3598 29.5198 16.9398 28.9398L28.9398 16.9398C29.5198 16.3598 30.4798 16.3598 31.0598 16.9398C31.6398 17.5198 31.6398 18.4798 31.0598 19.0598L19.0598 31.0598ZM29.9998 31.9998C28.8798 31.9998 27.9798 31.0998 27.9798 29.9998C27.9798 28.8998 28.8798 27.9998 29.9798 27.9998C31.0798 27.9998 31.9798 28.8998 31.9798 29.9998C31.9798 31.0998 31.0998 31.9998 29.9998 31.9998Z" />
              </SvgIcon>
            }
          />
        </Box>

        <Box sx={{ maxWidth: "25%", width: "100%", px: 1.5 }}>
          <Tile
            color={"222, 250, 50"}
            title={"NFTs Minted"}
            value={data.sales?.length || 0}
            Icon={
              <SvgIcon
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.65961 38.0196L5.61961 38.0596C5.07961 36.8796 4.73961 35.5396 4.59961 34.0596C4.73961 35.5196 5.11961 36.8396 5.65961 38.0196Z" />
                <path d="M18.5002 20.7602C21.1291 20.7602 23.2602 18.6291 23.2602 16.0002C23.2602 13.3714 21.1291 11.2402 18.5002 11.2402C15.8714 11.2402 13.7402 13.3714 13.7402 16.0002C13.7402 18.6291 15.8714 20.7602 18.5002 20.7602Z" />
                <path d="M32.88 4H16.12C8.84 4 4.5 8.34 4.5 15.62V32.38C4.5 34.56 4.88 36.46 5.62 38.06C7.34 41.86 11.02 44 16.12 44H32.88C40.16 44 44.5 39.66 44.5 32.38V27.8V15.62C44.5 8.34 40.16 4 32.88 4ZM41.24 25C39.68 23.66 37.16 23.66 35.6 25L27.28 32.14C25.72 33.48 23.2 33.48 21.64 32.14L20.96 31.58C19.54 30.34 17.28 30.22 15.68 31.3L8.2 36.32C7.76 35.2 7.5 33.9 7.5 32.38V15.62C7.5 9.98 10.48 7 16.12 7H32.88C38.52 7 41.5 9.98 41.5 15.62V25.22L41.24 25Z" />
              </SvgIcon>
            }
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Paper
          variant="elevation1"
          sx={{ flexGrow: 1, p: "48px 85.42px 48px 26px" }}
        >
          <SvgIcon
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              fill: "none",
              pointerEvents: "none",
              overflow: "visible",
            }}
            viewBox={null}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="100%"
              height="100%"
              rx="55.5"
              stroke="url(#paint17_linear_938_1665)"
              strokeOpacity="0.3"
            />
            <linearGradient
              id="paint17_linear_938_1665"
              x1="295.159"
              y1="-32.9817"
              x2="1058.26"
              y2="439.905"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </SvgIcon>

          <Typography variant="papercaption">
            <SvgIcon
              width="22"
              height="22"
              sx={({ mode }) => ({
                fill: "none",
                "& > path": {
                  stroke: `url(#${
                    mode === "light"
                      ? "paint0_linear_938_1667"
                      : "paint3_linear_938_2365"
                  })`,
                },
              })}
            >
              <defs>
                <linearGradient
                  y2="0.01296"
                  x2="1.03372"
                  y1="0"
                  x1="0"
                  id="paint0_linear_938_1667"
                >
                  <stop stopColor="#DC70EE" />
                  <stop stopColor="#6E3EF7" offset="1" />
                  <stop stopColor="#673AB7" offset="1" />
                </linearGradient>

                <linearGradient
                  id="paint3_linear_938_2365"
                  x1="2"
                  y1="12"
                  x2="22"
                  y2="12"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#EDFF1D" />
                  <stop offset="0.48" stopColor="#F2A4FF" />
                  <stop offset="1" stopColor="#6654F1" />
                </linearGradient>
              </defs>
              <path
                id="svg_1"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="m9.01551,10.1501l-2.65,0c-0.63,0 -1.13999,0.51 -1.13999,1.14l0,5.12l3.78999,0l0,-6.26l0,0z"
              />
              <path
                id="svg_2"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="m11.66681,5.6001l-1.5201,0c-0.63,0 -1.1399,0.51001 -1.1399,1.14001l0,9.65999l3.79,0l0,-9.65999c0,-0.63 -0.5,-1.14001 -1.13,-1.14001z"
              />
              <path
                id="svg_3"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="m15.45361,11.8501l-2.65,0l0,4.55l3.79,0l0,-3.41c-0.01,-0.63 -0.52,-1.14 -1.14,-1.14z"
              />
              <path
                id="svg_4"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="m8,21l6,0c5,0 7,-2 7,-7l0,-6c0,-5 -2,-7 -7,-7l-6,0c-5,0 -7,2 -7,7l0,6c0,5 2,7 7,7z"
              />
            </SvgIcon>

            <Typography
              component="span"
              sx={({ mode }) => ({
                background:
                  mode === "light"
                    ? "linear-gradient(91.96deg, #DC70EE 0%, #6E3EF7 100%, #673AB7 100%)"
                    : "linear-gradient(90deg, #EDFF1D 0%, #F2A4FF 48%, #6654F1 100%)",
                textFillColor: "transparent",
                backgroundClip: "text",
                fontSize: "inherit",
                fontWeight: "inherit",
                lineHeight: "inherit",
              })}
            >
              Performance
            </Typography>
          </Typography>

          <Chart data={data.chart} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <IconCounter
                Icon={
                  <SvgIcon
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.119 12.6816L23.3457 10.9083C23.0423 10.6049 22.7973 10.0099 22.7973 9.58994V7.06994C22.7973 6.04328 21.9573 5.20328 20.9307 5.20328H18.4223C18.0023 5.20328 17.4073 4.95828 17.104 4.65494L15.3307 2.88161C14.6073 2.15828 13.4173 2.15828 12.694 2.88161L10.8973 4.65494C10.6057 4.95828 10.0107 5.20328 9.57901 5.20328H7.07068C6.04401 5.20328 5.20401 6.04328 5.20401 7.06994V9.57828C5.20401 9.99828 4.95901 10.5933 4.65568 10.8966L2.88234 12.6699C2.15901 13.3933 2.15901 14.5833 2.88234 15.3066L4.65568 17.0799C4.95901 17.3833 5.20401 17.9783 5.20401 18.3983V20.9066C5.20401 21.9333 6.04401 22.7733 7.07068 22.7733H9.57901C9.99901 22.7733 10.594 23.0183 10.8973 23.3216L12.6707 25.0949C13.394 25.8183 14.584 25.8183 15.3073 25.0949L17.0807 23.3216C17.384 23.0183 17.979 22.7733 18.399 22.7733H20.9073C21.934 22.7733 22.774 21.9333 22.774 20.9066V18.3983C22.774 17.9783 23.019 17.3833 23.3223 17.0799L25.0957 15.3066C25.854 14.5949 25.854 13.4049 25.119 12.6816ZM9.33401 10.4999C9.33401 9.85828 9.85901 9.33328 10.5007 9.33328C11.1423 9.33328 11.6673 9.85828 11.6673 10.4999C11.6673 11.1416 11.154 11.6666 10.5007 11.6666C9.85901 11.6666 9.33401 11.1416 9.33401 10.4999ZM11.119 18.1183C10.944 18.2933 10.7223 18.3749 10.5007 18.3749C10.279 18.3749 10.0573 18.2933 9.88234 18.1183C9.54401 17.7799 9.54401 17.2199 9.88234 16.8816L16.8823 9.88161C17.2207 9.54328 17.7807 9.54328 18.119 9.88161C18.4573 10.2199 18.4573 10.7799 18.119 11.1183L11.119 18.1183ZM17.5007 18.6666C16.8473 18.6666 16.3223 18.1416 16.3223 17.4999C16.3223 16.8583 16.8473 16.3333 17.489 16.3333C18.1307 16.3333 18.6557 16.8583 18.6557 17.4999C18.6557 18.1416 18.1423 18.6666 17.5007 18.6666Z"
                      fill="white"
                    />
                  </SvgIcon>
                }
                color={"117, 233, 249"}
                value={data.legends.sales}
                caption="Sales, ETH"
              />
            </Box>

            <Box>
              <IconCounter
                Icon={
                  <SvgIcon
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.01091 22.178L2.98758 22.2014C2.67258 21.513 2.47424 20.7314 2.39258 19.868C2.47424 20.7197 2.69591 21.4897 3.01091 22.178Z"
                      fill="white"
                    />
                    <path
                      d="M10.4993 12.1101C12.0328 12.1101 13.276 10.8669 13.276 9.33343C13.276 7.79992 12.0328 6.55676 10.4993 6.55676C8.96581 6.55676 7.72266 7.79992 7.72266 9.33343C7.72266 10.8669 8.96581 12.1101 10.4993 12.1101Z"
                      fill="white"
                    />
                    <path
                      d="M18.889 2.33337H9.11232C4.86565 2.33337 2.33398 4.86504 2.33398 9.11171V18.8884C2.33398 20.16 2.55565 21.2684 2.98732 22.2017C3.99065 24.4184 6.13732 25.6667 9.11232 25.6667H18.889C23.1357 25.6667 25.6673 23.135 25.6673 18.8884V16.2167V9.11171C25.6673 4.86504 23.1357 2.33337 18.889 2.33337ZM23.7657 14.5834C22.8557 13.8017 21.3857 13.8017 20.4757 14.5834L15.6223 18.7484C14.7123 19.53 13.2423 19.53 12.3323 18.7484L11.9357 18.4217C11.1073 17.6984 9.78899 17.6284 8.85565 18.2584L4.49232 21.1867C4.23565 20.5334 4.08398 19.775 4.08398 18.8884V9.11171C4.08398 5.82171 5.82232 4.08337 9.11232 4.08337H18.889C22.179 4.08337 23.9173 5.82171 23.9173 9.11171V14.7117L23.7657 14.5834Z"
                      fill="white"
                    />
                  </SvgIcon>
                }
                color="203, 229, 37"
                value={data.legends.nfts}
                caption="NFTs Minted"
              />
            </Box>

            <Box>
              <IconCounter
                Icon={
                  <SvgIcon
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.6042 8.14327C23.6126 7.04661 21.9559 6.49827 19.5526 6.49827H19.2726V6.45161C19.2726 4.49161 19.2726 2.06494 14.8859 2.06494H13.1126C8.7259 2.06494 8.7259 4.50327 8.7259 6.45161V6.50994H8.4459C6.0309 6.50994 4.3859 7.05827 3.39423 8.15494C2.23923 9.43827 2.27423 11.1649 2.3909 12.3433L2.40256 12.4249L2.49669 13.4132C2.51095 13.563 2.59162 13.6983 2.71744 13.7808C2.992 13.9608 3.48716 14.281 3.77923 14.4433C3.94256 14.5483 4.11756 14.6416 4.29256 14.7349C6.28756 15.8316 8.4809 16.5666 10.7092 16.9283C10.8142 18.0249 11.2926 19.3083 13.8476 19.3083C16.4026 19.3083 16.9042 18.0366 16.9859 16.9049C19.3659 16.5199 21.6642 15.6916 23.7409 14.4783C23.8109 14.4433 23.8576 14.4083 23.9159 14.3733C24.3892 14.1058 24.8806 13.7748 25.3261 13.4523C25.439 13.3705 25.5113 13.2445 25.5267 13.106L25.5492 12.9033L25.6076 12.3549C25.6192 12.2849 25.6192 12.2266 25.6309 12.1449C25.7242 10.9666 25.7009 9.35661 24.6042 8.14327ZM15.2709 16.1349C15.2709 17.3716 15.2709 17.5583 13.8359 17.5583C12.4009 17.5583 12.4009 17.3366 12.4009 16.1466V14.6766H15.2709V16.1349ZM10.3942 6.49827V6.45161C10.3942 4.46827 10.3942 3.73327 13.1126 3.73327H14.8859C17.6042 3.73327 17.6042 4.47994 17.6042 6.45161V6.50994H10.3942V6.49827Z"
                      fill="white"
                    />
                    <path
                      d="M24.4887 15.9584C24.8419 15.7895 25.2481 16.07 25.2127 16.46L24.7805 21.2217C24.5355 23.555 23.5788 25.935 18.4455 25.935H9.55547C4.42213 25.935 3.46547 23.555 3.22047 21.2333L2.80976 16.7155C2.77472 16.33 3.17136 16.0497 3.52371 16.2099C4.82798 16.8028 7.43644 17.9465 9.00018 18.3474C9.16471 18.3896 9.29799 18.5072 9.36819 18.6619C10.0676 20.2029 11.6111 21.0233 13.8488 21.0233C16.0644 21.0233 17.6278 20.1713 18.3293 18.6271C18.3996 18.4723 18.5332 18.3547 18.6978 18.3123C20.3568 17.8854 23.1272 16.6095 24.4887 15.9584Z"
                      fill="white"
                    />
                  </SvgIcon>
                }
                color="233, 102, 255"
                value={data.legends.campaigns}
                caption="Campaigns"
              />
            </Box>

            <Box>
              <IconCounter
                Icon={
                  <SvgIcon
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.95797 8.90963C8.0343 8.89823 8.11063 8.89823 8.18696 8.90963C9.87716 8.85264 11.2184 7.40518 11.2184 5.6272C11.2184 3.80363 9.81173 2.33337 8.06701 2.33337C6.3332 2.33337 4.92652 3.80363 4.92652 5.6272C4.92652 7.40518 6.26777 8.85264 7.95797 8.90963ZM19.8223 8.90963C19.8987 8.89823 19.975 8.89823 20.0513 8.90963C21.7415 8.85264 23.0828 7.40518 23.0937 5.6272C23.0937 3.80363 21.6761 2.33337 19.9423 2.33337C18.1976 2.33337 16.7909 3.81503 16.7909 5.6272C16.7909 7.40518 18.1321 8.85264 19.8223 8.90963ZM19.092 17.7652C20.6731 17.9818 22.3851 17.6626 23.6064 16.8078C25.3184 15.6111 25.3184 13.6622 23.5955 12.4769C22.3633 11.6221 20.6513 11.3029 19.0375 11.5309C19.4955 12.4769 19.7353 13.5596 19.7353 14.6993C19.7244 15.7935 19.5064 16.8306 19.092 17.7652ZM8.72169 17.7996C8.30732 16.8537 8.07832 15.8051 8.07832 14.6996C8.07832 13.5484 8.31822 12.4657 8.7653 11.5083C7.21687 11.326 5.57029 11.6565 4.40351 12.4771C2.6806 13.6738 2.6806 15.6227 4.40351 16.8195C5.58119 17.6287 7.18415 17.9706 8.72169 17.7996ZM14.152 18.1415C14.0647 18.1301 13.9666 18.1301 13.8684 18.1415C11.862 18.0732 10.2591 16.3522 10.2591 14.2323C10.27 12.0668 11.9383 10.3116 14.0211 10.3116C16.093 10.3116 17.7722 12.0668 17.7722 14.2323C17.7613 16.3522 16.1693 18.0732 14.152 18.1415ZM10.6081 24.6837C8.96155 23.544 8.96155 21.652 10.6081 20.5009C12.4946 19.1902 15.5697 19.1902 17.4343 20.5009C19.0809 21.6406 19.0809 23.5326 17.4343 24.6837C15.5588 25.9944 12.4837 25.9944 10.6081 24.6837Z"
                      fill="white"
                    />
                  </SvgIcon>
                }
                color="151, 71, 255"
                value={data.legends.affiliates}
                caption="Affiliates"
              />
            </Box>
          </Box>
        </Paper>

        <Box sx={{ ml: 4.625, minWidth: 434 }}>
          <Paper variant="elevation1" sx={{ borderRadius: 6, mb: 3.25 }}>
            <SvgIcon
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                fill: "none",
                pointerEvents: "none",
                overflow: "visible",
              }}
              viewBox={null}
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="100%"
                height="100%"
                rx="23.5"
                stroke="url(#paint17_linear_938_1665)"
                strokeOpacity="0.3"
              />
              <linearGradient
                id="paint17_linear_938_1665"
                x1="295.159"
                y1="-32.9817"
                x2="1058.26"
                y2="439.905"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </SvgIcon>

            <Typography variant="papercaption">
              <SvgIcon
                width="22"
                height="22"
                sx={({ mode }) => ({
                  fill: "none",
                  "& > path": {
                    stroke: `url(#${
                      mode === "light"
                        ? "paint0_linear_938_1667"
                        : "paint3_linear_938_2365"
                    })`,
                  },
                })}
              >
                <defs>
                  <linearGradient
                    y2="0.01296"
                    x2="1.03372"
                    y1="0"
                    x1="0"
                    id="paint0_linear_938_1667"
                  >
                    <stop stopColor="#DC70EE" />
                    <stop stopColor="#6E3EF7" offset="1" />
                    <stop stopColor="#673AB7" offset="1" />
                  </linearGradient>

                  <linearGradient
                    id="paint3_linear_938_2365"
                    x1="2"
                    y1="12"
                    x2="22"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#EDFF1D" />
                    <stop offset="0.48" stopColor="#F2A4FF" />
                    <stop offset="1" stopColor="#6654F1" />
                  </linearGradient>
                </defs>
                <path
                  id="svg_1"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="1.5"
                  d="m9.01551,10.1501l-2.65,0c-0.63,0 -1.13999,0.51 -1.13999,1.14l0,5.12l3.78999,0l0,-6.26l0,0z"
                />
                <path
                  id="svg_2"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="1.5"
                  d="m11.66681,5.6001l-1.5201,0c-0.63,0 -1.1399,0.51001 -1.1399,1.14001l0,9.65999l3.79,0l0,-9.65999c0,-0.63 -0.5,-1.14001 -1.13,-1.14001z"
                />
                <path
                  id="svg_3"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="1.5"
                  d="m15.45361,11.8501l-2.65,0l0,4.55l3.79,0l0,-3.41c-0.01,-0.63 -0.52,-1.14 -1.14,-1.14z"
                />
                <path
                  id="svg_4"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="m8,21l6,0c5,0 7,-2 7,-7l0,-6c0,-5 -2,-7 -7,-7l-6,0c-5,0 -7,2 -7,7l0,6c0,5 2,7 7,7z"
                />
              </SvgIcon>

              <Typography
                component="span"
                sx={({ mode }) => ({
                  background:
                    mode === "light"
                      ? "linear-gradient(91.96deg, #DC70EE 0%, #6E3EF7 100%, #673AB7 100%)"
                      : "linear-gradient(90deg, #EDFF1D 0%, #F2A4FF 48%, #6654F1 100%)",
                  textFillColor: "transparent",
                  backgroundClip: "text",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                  lineHeight: "inherit",
                })}
              >
                Latest Transtactions
              </Typography>
            </Typography>

            <Box sx={{ mb: 4 }}>
              {data.sales?.slice(0, 4).map(({ createdAt, value }, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                    "&:last-child": { mb: 0 },
                    "& > .MuiBox-root": {
                      maxWidth: "33.333%",
                      width: "100%",
                    },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="paper.campaign.name"
                      color="text.default"
                    >
                      Ethereum
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="paper.campaign.time"
                      color="text.default"
                    >
                      {utils.formatEther(BigNumber.from(value))} ETH
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "right" }}>
                    <Typography variant="paper.campaign.date">
                      {moment(createdAt).format("LL")}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            <Button
              variant="default"
              component={Link}
              to="/productivity/transactions"
              sx={{ width: "100%" }}
            >
              View All History
            </Button>
          </Paper>

          <Paper variant="elevation1" sx={{ borderRadius: 6 }}>
            <SvgIcon
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                fill: "none",
                pointerEvents: "none",
                overflow: "visible",
              }}
              viewBox={null}
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="100%"
                height="100%"
                rx="23.5"
                stroke="url(#paint17_linear_938_1665)"
                strokeOpacity="0.3"
              />
              <linearGradient
                id="paint17_linear_938_1665"
                x1="295.159"
                y1="-32.9817"
                x2="1058.26"
                y2="439.905"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </SvgIcon>

            <Typography variant="papercaption">
              <SvgIcon
                width="22"
                height="22"
                sx={({ mode }) => ({
                  fill: "none",
                  "& > path": {
                    stroke: `url(#${
                      mode === "light"
                        ? "paint0_linear_938_1667"
                        : "paint3_linear_938_2365"
                    })`,
                  },
                })}
              >
                <defs>
                  <linearGradient
                    y2="0.01296"
                    x2="1.03372"
                    y1="0"
                    x1="0"
                    id="paint0_linear_938_1667"
                  >
                    <stop stopColor="#DC70EE" />
                    <stop stopColor="#6E3EF7" offset="1" />
                    <stop stopColor="#673AB7" offset="1" />
                  </linearGradient>

                  <linearGradient
                    id="paint3_linear_938_2365"
                    x1="2"
                    y1="12"
                    x2="22"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#EDFF1D" />
                    <stop offset="0.48" stopColor="#F2A4FF" />
                    <stop offset="1" stopColor="#6654F1" />
                  </linearGradient>
                </defs>
                <path
                  id="svg_1"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="1.5"
                  d="m9.01551,10.1501l-2.65,0c-0.63,0 -1.13999,0.51 -1.13999,1.14l0,5.12l3.78999,0l0,-6.26l0,0z"
                />
                <path
                  id="svg_2"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="1.5"
                  d="m11.66681,5.6001l-1.5201,0c-0.63,0 -1.1399,0.51001 -1.1399,1.14001l0,9.65999l3.79,0l0,-9.65999c0,-0.63 -0.5,-1.14001 -1.13,-1.14001z"
                />
                <path
                  id="svg_3"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="1.5"
                  d="m15.45361,11.8501l-2.65,0l0,4.55l3.79,0l0,-3.41c-0.01,-0.63 -0.52,-1.14 -1.14,-1.14z"
                />
                <path
                  id="svg_4"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="m8,21l6,0c5,0 7,-2 7,-7l0,-6c0,-5 -2,-7 -7,-7l-6,0c-5,0 -7,2 -7,7l0,6c0,5 2,7 7,7z"
                />
              </SvgIcon>

              <Typography
                component="span"
                sx={({ mode }) => ({
                  background:
                    mode === "light"
                      ? "linear-gradient(91.96deg, #DC70EE 0%, #6E3EF7 100%, #673AB7 100%)"
                      : "linear-gradient(90deg, #EDFF1D 0%, #F2A4FF 48%, #6654F1 100%)",
                  textFillColor: "transparent",
                  backgroundClip: "text",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                  lineHeight: "inherit",
                })}
              >
                Latest Campaigns
              </Typography>
            </Typography>

            <Box sx={{ mb: 4 }}>
              {data.campaigns?.slice(0, 5).map(({ name, createdAt }, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                    "&:last-child": { mb: 0 },
                    "& > .MuiBox-root": {
                      maxWidth: "33.333%",
                      width: "100%",
                    },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="paper.campaign.name"
                      color="text.default"
                    >
                      {name}
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="paper.campaign.time"
                      color="text.default"
                    >
                      {moment(createdAt).format("LT")}
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "right" }}>
                    <Typography variant="paper.campaign.date">
                      {moment(createdAt).format("LL")}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            <Button
              variant="default"
              component={Link}
              to="/campaigns"
              sx={{ width: "100%" }}
            >
              See All Campaigns
            </Button>
          </Paper>
        </Box>
      </Box>
      {/* 
      <Box sx={{ my: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper sx={{ p: 3 }}>
              <Typography
                variant="h4"
                sx={{
                  display: "flex",
                  fontSize: 24,
                  fontWeight: 500,
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    display: "inline-flex",
                    p: 0.5,
                    borderRadius: 1,
                    backgroundColor: purple[50],
                    mr: 1,
                  }}
                >
                  <Campaign sx={{ fontSize: 30, color: deepPurple[500] }} />
                </Typography>
                Latest 10 Campaigns
              </Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.campaigns
                    ?.slice(0, 10)
                    .map(({ name, createdAt }, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{name}</TableCell>
                        <TableCell>{moment(createdAt).format("LLL")}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 3 }}>
              <Typography
                variant="h4"
                sx={{
                  display: "flex",
                  fontSize: 24,
                  fontWeight: 500,
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    display: "inline-flex",
                    p: 0.5,
                    borderRadius: 1,
                    backgroundColor: purple[50],
                    mr: 1,
                  }}
                >
                  <AccessTimeOutlined
                    sx={{ fontSize: 30, color: deepPurple[500] }}
                  />
                </Typography>
                Latest 10 Transactions
              </Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Coin</TableCell>
                    <TableCell>Total, ETH</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.sales
                    ?.slice(0, 10)
                    .map(({ createdAt, value }, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{moment(createdAt).format("LLL")}</TableCell>
                        <TableCell>ETH</TableCell>
                        <TableCell>
                          {utils.formatEther(BigNumber.from(value))}
                        </TableCell>
                      </TableRow>
                    )) || (
                    <TableRow>
                      <TableCell>Empty</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default Dashboard;
