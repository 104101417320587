import { useReducer } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SvgIcon from "@mui/material/SvgIcon";

import { Logs } from "../";
import { Contract } from "../../config";

const reducer = (state, action) => {
  switch (action.type) {
    case "alert":
      return { ...state, alert: action.payload };
    case "submit":
      return { ...state, isSubmitting: action.payload };
    case "next_step":
      return { ...state, publish: action.payload };
    case "add_log":
      return {
        ...state,
        logs: [
          ...state.logs,
          {
            message: action.payload,
            time: new Date().toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }),
          },
        ],
      };
    case "clear_logs":
      return { ...state, logs: [] };
    case "switch_network":
      return { ...state, networkVersion: action.payload };
    default:
      throw new Error();
  }
};

const PublishContract = ({ nextStepCallback }) => {
  const { campaignid } = useParams();
  const [state, dispatch] = useReducer(reducer, {
    alert: false,
    isSubmitting: false,
    publish: false,
    logs: [],
    networkVersion: 1,
  });

  const handlePublish = async () => {
    dispatch({ type: "clear_logs" });
    dispatch({ type: "submit", payload: true });

    const { networkVersion } = state;

    try {
      const result = await Contract.publish(
        { networkVersion, campaignid },
        (payload) => {
          dispatch({ type: "add_log", payload });
        }
      );

      dispatch({ type: "submit", payload: result });

      if (result) {
        dispatch({ type: "next_step", payload: true });
      }
    } catch (e) {
      dispatch({ type: "add_log", payload: e.message });
    }
  };

  const handleChange = (e) =>
    dispatch({ type: "switch_network", payload: +e.target.value });

  const { developer_mode } = useSelector((store) => store.user);

  return (
    <Box>
      <Alert
        severity="warning"
        sx={{
          p: "13.5px 28px",
          mb: 5.5,
          ".MuiAlert-icon": {
            mr: 2.5,
            ".MuiSvgIcon-root": { width: 24, height: 24 },
          },
          ".MuiAlert-message": {
            color: "var(--default-text-color)",
            fontWeight: 500,
          },
        }}
      >
        Please make sure the selected network is correct before deploying the
        smart contract.
      </Alert>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          mb: 8,
        }}
      >
        <Box sx={{ maxWidth: 526, width: "100%" }}>
          <InputLabel
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 14,
              lineHeight: "21px",
              ml: 4.5,
              color: "#ADADAD",
            }}
          >
            Network
          </InputLabel>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              position: "relative",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
              p: "20px 36px",
              background:
                "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
              color: "#ADADAD",
              borderRadius: 14,
              ".MuiInputBase-input": {
                p: 0,
                "&::placeholder": {
                  color: "#ADADAD",
                },
                "&.Mui-disabled": {
                  textFillColor: "#ADADAD",
                },
              },
              ".MuiInputAdornment-root": {
                mr: 0,
                color: "var(--default-text-color)",
                ".MuiTypography-root": {
                  fontSize: "inherit",
                  color: "inherit",
                },
              },
            }}
          >
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={null}
              sx={{
                fill: "none",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
                overflow: "visible",
                zIndex: 10,
              }}
            >
              <rect
                x="0.5"
                y="0.5"
                width="100%"
                height="100%"
                rx="31.5"
                stroke="white"
              />
            </SvgIcon>

            <Select
              IconComponent={KeyboardArrowDownIcon}
              value={state.networkVersion}
              onChange={handleChange}
              disabled={state.isSubmitting}
              sx={{
                width: "100%",
                "& > fieldset": {
                  display: "none",
                },
                ".MuiSelect-select": {
                  color: "#ADADAD",
                  fontSize: 16,
                  lineHeight: "24px",
                },
                ".MuiSvgIcon-root": {
                  width: "1em",
                  height: "1em",
                  color: "#ADADAD",
                },
              }}
            >
              <MenuItem value={1}>Ethereum Mainnet</MenuItem>
              <MenuItem value={137} key="NETWORK.137">
                Polygon
              </MenuItem>
              {developer_mode && [
                <MenuItem value={5} key="NETWORK.5">
                  Goerli
                </MenuItem>,
                <MenuItem value={80001} key="NETWORK.80001">
                  Mumbai
                </MenuItem>,
                <MenuItem value={11155111} key="NETWORK.11155111">
                  Sepolia
                </MenuItem>
              ]}
            </Select>
          </Box>
        </Box>

        <FormControl sx={{ ml: 4 }}>
          <Button
            variant="gradient"
            onClick={handlePublish}
            disableRipple
            disabled={state.isSubmitting}
          >
            <SvgIcon
              viewBox={null}
              sx={{
                fill: "none",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "visible",
              }}
            >
              <rect
                x="1"
                y="1"
                width="100%"
                height="100%"
                rx="32"
                stroke="url(#paint0_linear_1129_3322)"
                strokeWidth="2"
              />
            </SvgIcon>
            Publish Smart Contract
          </Button>
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Logs list={state.logs} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button
          variant="default"
          onClick={nextStepCallback}
          disabled={!state.publish}
        >
          Next Step
        </Button>
      </Box>
    </Box>
  );
};

PublishContract.propTypes = {
  nextStepCallback: PropTypes.func,
};

export default PublishContract;
