import { useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ChooseTemplate, LoadTemplate } from "./components";

import "./scss/global.scss";

export default function Builder() {
  const { usedTemplate } = useSelector((state) => state.builder);

  return (
    <>
      <CssBaseline />
      {usedTemplate === false ? <ChooseTemplate /> : <LoadTemplate />}
    </>
  );
}
