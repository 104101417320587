import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import { SvgIcon } from "@mui/material";
import classNames from "classnames";

export default function TextInput({ labelText, error = false, helperText = false, field, sx = {}, InputProps: { startAdornment, ...InputProps } = {} }) {
  return (
    <Box sx={{ mb: 4.5, ...sx }}>
      <InputLabel
        error={error}
        sx={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "21px",
          ml: 4.5,
          color: "#ADADAD",
          mb: "0.9375rem",
        }}
      >
        {labelText}
      </InputLabel>

      <Input
        {...field}
        {...InputProps}
        error={error}
        startAdornment={
          startAdornment ? (
            <>
              {startAdornment}
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox={null}
                sx={{
                  fill: "none",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                  overflow: "visible",
                  zIndex: 10,
                }}
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="100%"
                  height="100%"
                  rx={field.multiline ? "54" : "31.5"}
                  // stroke="white"
                />
              </SvgIcon>
            </>
          ) : (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={null}
              sx={{
                fill: "none",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
                overflow: "visible",
                zIndex: 10,
              }}
            >
              <rect x="0.5" y="0.5" width="100%" height="100%" rx={field.multiline ? "54" : "31.5"} />
            </SvgIcon>
          )
        }
        sx={{
          width: "100%",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "24px",
          p: "20px 36px",
          background: "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
          color: "#ADADAD",
          borderRadius: 14,
          ".MuiSvgIcon-root": {
            stroke: "#ffffff",
          },
          "&.Mui-error": {
            ".MuiSvgIcon-root": {
              stroke: "#FF2E6C",
            },
          },
          "&.Mui-focused": {
            ".MuiSvgIcon-root": {
              stroke: "url(#paint1_linear_1071_2653)",
            },
          },
          ".MuiInputBase-input": {
            p: 0,
            color: "var(--default-text-color)",
            "&::placeholder": {
              color: "#ADADAD",
            },
            "&.Mui-disabled": {
              textFillColor: "#ADADAD",
            },
          },
          ".MuiInputAdornment-root": {
            mr: 0,
            color: "var(--default-text-color)",
            ".MuiTypography-root": {
              fontSize: "inherit",
              color: "inherit",
            },
          },
        }}
        placeholder={labelText}
        disableUnderline
      />

      {helperText && (
        <Typography variant="helper.text" className={classNames({ "Mui-error": error })}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
}
