import classNames from "classnames";

const Alert = ({ type, message }) => {
  return (
    <article
      className={classNames("message", "is-small", {
        "is-danger": type === "fail",
        "is-success": type === "success",
        "is-warning": type === "warning"
      })}
    >
      <div
        className="message-body"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </article>
  );
};

export default Alert;
