import { useId } from "react";
import CountUp from "react-countup";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SvgIcon } from "@mui/material";

const IconCounter = ({ Icon, color, value, caption }) => {
  const id = useId();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          p: 1.5,
          background: `linear-gradient(118.77deg, rgba(${color}, 0.7) 20.62%, rgba(${color}, 0.3) 99.9%)`,
          borderRadius: 3,
          mr: 1.5,
        }}
      >
        <SvgIcon
          viewBox={null}
          xmlns="http://www.w3.org/2000/svg"
          sx={{
            fill: "none",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            overflow: "visible",
          }}
        >
          <rect
            x="1"
            y="1"
            width="100%"
            height="100%"
            rx="11"
            stroke={`url(#linear-${id})`}
            strokeOpacity="0.3"
            strokeWidth="2"
          />
          <linearGradient
            id={`linear-${id}`}
            x1="17.8468"
            y1="-2.25072"
            x2="66.9138"
            y2="24.6907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={`rgb(${color})`} />
            <stop offset="1" stopColor={`rgb(${color})`} stopOpacity="0" />
          </linearGradient>
        </SvgIcon>

        {Icon && Icon}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="iconcounternumber" sx={{ color: `rgb(${color})` }}>
          {value > 1 ? <CountUp end={value} duration={1} /> : value}
        </Typography>

        <Typography variant="iconcountertitle" color="text.default">
          {caption}
        </Typography>
      </Box>
    </Box>
  );
};

export default IconCounter;
