import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Footer = () => {
  return (
    <Box sx={{ pt: 22.375, pb: 6, textAlign: "center" }}>
      <Typography variant="copyright" color="text.default">
        Copyright © {new Date().getFullYear()} Reminto.com. All rights reserved
      </Typography>
    </Box>
  );
};

export default Footer;
