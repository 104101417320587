import RESTAPI from "../";

export default class AUTH {
  static async CheckAuth() {
    try {
      const { data } = await RESTAPI.axios.get("api/signin");
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async SignIn(wallet_address) {
    try {
      const { data } = await RESTAPI.axios.get(`api/signin/${wallet_address}`);
      window.localStorage.setItem("apikey", data.apikey);

      // Update API Key in header
      RESTAPI.axios.defaults.headers.authorization = `Bearer ${data.apikey}`;
      return { status: "success", data };
    } catch (e) {
      throw Error(e.message);
    }
  }
}
