import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { ConfirmContract, PublishContract, VerifyContract } from "../";

const Steps = () => {
  const { flg_publish, flg_verified } = useLoaderData();
  const [activeStep, setActiveStep] = useState(
    !flg_publish ? 0 : !flg_verified ? 2 : 0
  );
  const stepLables = [
    "Confirm Contract Settings",
    // "Confirm Smart Contract Settings",
    "Publish Contract",
    // "Publish Smart Contract",
    "Verify Contract",
    // "Verify Smart Contract",
  ];

  return (
    <>
      <Stepper activeStep={activeStep}>
        {stepLables.map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconProps={{ sx: { fontSize: 35 } }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box>
        {activeStep === 0 ? (
          <ConfirmContract
            nextStepCallback={() =>
              setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
          />
        ) : activeStep === 1 ? (
          <PublishContract
            nextStepCallback={() =>
              setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
          />
        ) : (
          <VerifyContract
            nextStepCallback={() =>
              setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
          />
        )}
      </Box>
    </>
  );
};

export default Steps;
