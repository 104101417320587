import React from "react";
import ReactDOM from "react-dom";
import { cx, css } from "@emotion/css";

export const Button = ({ className, active, reversed, ...props }) => (
  <span
    {...props}
    className={cx(
      className,
      css`
        cursor: pointer;
        min-width: 26px;
        height: 24px;
        padding: 5px;
        display: inline-flex;
        color: ${active ? "var(--default-text-color)" : "#ADADAD"};
        & > svg {
          width: 100%;
          height: 100%;
        }
      `
    )}
  />
);

export const EditorValue = ({ className, value, ...props }) => {
  const textLines = value.document.nodes
    .map((node) => node.text)
    .toArray()
    .join("\n");
  return (
    <div
      {...props}
      className={cx(
        className,
        css`
          margin: 30px -20px 0;
        `
      )}
    >
      <div
        className={css`
          font-size: 14px;
          padding: 5px 20px;
          color: #404040;
          border-top: 2px solid #eeeeee;
          background: #f8f8f8;
        `}
      >
        Slate's value as text
      </div>
      <div
        className={css`
          color: #404040;
          font: 12px monospace;
          white-space: pre-wrap;
          padding: 10px 20px;
          div {
            margin: 0 0 0.5em;
          }
        `}
      >
        {textLines}
      </div>
    </div>
  );
};

export const Instruction = ({ className, ...props }) => (
  <div
    {...props}
    className={cx(
      className,
      css`
        white-space: pre-wrap;
        margin: 0 -20px 10px;
        padding: 10px 20px;
        font-size: 14px;
        background: #f8f8e8;
      `
    )}
  />
);

export const Menu = ({ className, ...props }) => (
  <div
    {...props}
    data-test-id="menu"
    className={cx(
      className,
      css`
        & > * + * {
          margin-left: 28px;
        }
      `
    )}
  />
);

export const Portal = ({ children }) => {
  return typeof document === "object"
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

export const Toolbar = ({ className, ...props }) => (
  <Menu
    {...props}
    className={cx(
      className,
      css`
        position: relative;
        padding: 17px 18px;
        border-bottom: 1px solid #adadad;
        margin-bottom: 16px;
      `
    )}
  />
);
