import Box from "@mui/material/Box";
import { useLoaderData } from "react-router-dom";
import { Caption, Code } from "../";

const GenerateSnippet = () => {
  const { data } = useLoaderData();

  return (
    <Box sx={{ px: 2, py: 3 }}>
      <Caption title={"Affiliate Link Widget"} sx={{ mb: 2 }} />

      <Code
        codeType="js"
        code={`<script defer="defer" src="${window.location.origin}/widget/${data.key}/widget.js"></script>`}
      />

      <Caption title={"Mint Widget"} sx={{ mb: 2, mt: 5 }} />

      <Code
        codeType="js"
        code={`<script defer="defer" src="${window.location.origin}/mint/${data.key}/widget.js"></script>`}
      />
    </Box>
  );
};

export default GenerateSnippet;
