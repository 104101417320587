import { Navigate, createBrowserRouter, redirect } from "react-router-dom";
import App from "../App";
import {
  CampaignList,
  CreateCampaign,
  EditCampaign,
  SignIn,
  ManageContract,
  GenerateSnippet,
  Dashboard,
  Transactions,
  Statistics,
  Profile,
  Billing,
  Builder,
} from "../components";
import ErrorPage from "../components/NotFound";
import { store } from "./";
import { signIn } from "./slices/user";
import { setCategory } from "./slices/category";
import { initBuilder, loadingTemplates } from "./slices/builder";
import { loadContractData } from "./slices/contract";

import { AUTH, BUILDER, CAMPAIGN, CATEGORY, DASHBOARD, STATISTIC, TEMPLATE, TRANSACTION, PROFILE } from "./RESTAPI/";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    loader: async () => {
      const { user } = store.getState();

      if (user.auth) {
        store.dispatch(setCategory(await CATEGORY.GetList()));
        return;
      }

      try {
        const { response } = await AUTH.CheckAuth();
        store.dispatch(setCategory(await CATEGORY.GetList()));
        store.dispatch(signIn({ auth: true, ...response }));
      } catch {
        window.localStorage.removeItem("apikey");
        return redirect("/signin");
      }
    },
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Dashboard />,
            loader: async () => await DASHBOARD.GetData(),
          },
          {
            element: <Profile />,
            path: "/profile",
            loader: async () => await PROFILE.GetData(),
          },
          {
            path: "/campaigns",
            loader: ({ params }) => CAMPAIGN.GetList(+params?.page || 1),
            element: <CampaignList />,
          },
          {
            path: "/campaigns/edit/:id",
            loader: async ({ params }) => {
              const response = await CAMPAIGN.GetCampaignById(params.id);

              if (response.status === "fail") {
                throw new Response(response.data, { status: 404 });
              }

              const { slugs, marketplace_slugs } = await CAMPAIGN.GetSlugList(response.data.mint_url, response.data.Marketplace?.slug || "");

              return { data: response.data, slugs, marketplace_slugs };
            },
            element: <EditCampaign />,
          },
          {
            path: "/campaigns/create",
            loader: async () => CAMPAIGN.GetSlugList(),
            element: <CreateCampaign />,
          },
          {
            path: "/campaigns/:campaignid/contract",
            loader: async ({ params }) => CAMPAIGN.GetContractByCampaignId(params.campaignid),
            element: <ManageContract />,
          },
          {
            path: "/campaigns/:campaignid/widget",
            loader: ({ params }) => CAMPAIGN.GetCampaignById(params.campaignid),
            element: <GenerateSnippet />,
          },
          {
            path: "/campaigns/:page",
            loader: ({ params }) => CAMPAIGN.GetList(+params?.page || 1),
            element: <CampaignList />,
          },
          {
            path: "/productivity",
            element: <Navigate to="/productivity/transactions" />,
          },
          // Statistics Route
          {
            path: "/productivity/statistics/:campaignid",
            loader: ({ params }) => STATISTIC.GetStats(params.campaignid),
            element: <Statistics />,
          },
          {
            path: "/productivity/statistics",
            loader: () => STATISTIC.GetStats(),
            element: <Statistics />,
            children: [
              {
                path: ":campaignid",
                loader: ({ params }) => STATISTIC.GetStats(params.campaignid),
                element: <Statistics />,
              },
            ],
          },
          {
            path: "/productivity/transactions",
            loader: () => TRANSACTION.GetList(),
            element: <Transactions />,
          },
          {
            path: "/billing",
            element: <Billing />,
          },
        ],
      },
    ],
  },
  {
    path: "/signin",
    loader: async () => {
      if (window.localStorage.getItem("apikey")) {
        return redirect("/");
      }
    },
    element: <SignIn />,
  },
  {
    path: "/builder",
    loader: async () => {
      const { user } = store.getState();

      if (user.auth) {
        return;
      }

      const { status, response } = await AUTH.CheckAuth();

      if (status === "success") {
        store.dispatch(signIn({ auth: true, ...response }));
      } else {
        window.localStorage.removeItem("apikey");
        return redirect("/signin");
      }
    },
    children: [
      {
        index: true,
        element: <>BUILDER 1</>,
      },
      {
        path: ":campaignId",
        loader: async ({ params }) => {
          const templates = await TEMPLATE.GetList();
          store.dispatch(loadingTemplates(templates));

          const { status, data } = await BUILDER.GetConfig(params.campaignId);

          if (status !== "success") {
            return redirect("/campaigns");
          }

          store.dispatch(
            initBuilder({
              campaignId: data.id,
              usedTemplateId: data.Site?.TemplateId || null,
              blocks: data.Site?.blocks || [],
            })
          );

          store.dispatch(
            loadContractData({
              campaign_name: data.name,
              ...data.contract,
              flg_test: !data.flg_publish,
            })
          );
        },
        element: <Builder />,
      },
    ],
  },
]);

export default router;
