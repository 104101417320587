import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { Layer } from "../";

export default function TemplateLayers() {
  const { componentTree } = useSelector((state) => state.builder);

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {componentTree.map((layer) => (
        <Layer key={`Layer.${layer.component}`} {...layer} />
      ))}
    </TreeView>
  );
}
