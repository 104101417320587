import { Field, FieldArray, Form, Formik } from "formik";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import InputLabel from "@mui/material/InputLabel";

import { Contract } from "../../../config";

const WriteVariable = () => {
  const data = useLoaderData();
  const [disabled, setDisabled] = useState(false);
  const [alert, setAlert] = useState(false);

  const methods = data.abi.filter(
    ({ stateMutability, type, name }) =>
      ["payable", "nonpayable"].includes(stateMutability) &&
      type === "function" &&
      name !== "safeTransferFrom"
  );

  const initialValues = {
    method: "",
    params: {},
  };

  const handleChange = (value, setValues) => {
    const method = methods.find(({ name }) => value === name);
    const params = {};

    method?.inputs.forEach(({ name }) => (params[name] = ""));

    setValues({
      method: value,
      params,
    });

    setAlert(false);
  };

  const handleSubmit = async (values) => {
    const { contract_address, abi, network_id } = data;

    setDisabled(true);

    await Contract.writeProp(
      {
        contract_address,
        abi,
        network_id,
        prop: values,
      },
      (result, message) =>
        setAlert({ severity: result ? "success" : "error", message })
    );

    setDisabled(false);
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setValues, touched, errors }) => (
          <Form>
            <Box sx={{ display: "flex", alignItems: "end" }}>
              <Field name="method">
                {({ field }) => (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <InputLabel
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        ml: 4.5,
                        mb: "0.9375rem",
                        color: "#ADADAD",
                      }}
                    >
                      Select Variable
                    </InputLabel>

                    <Autocomplete
                      sx={{ width: 300, mr: 1 }}
                      options={methods.map(({ name }) => name)}
                      disabled={disabled}
                      renderInput={({ InputProps, ...params }) => (
                        <TextField
                          variant="standard"
                          placeholder="Select Variable"
                          InputProps={{
                            ...InputProps,
                            disableUnderline: true,
                            startAdornment: (
                              <SvgIcon
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox={null}
                                sx={{
                                  fill: "none",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  pointerEvents: "none",
                                  overflow: "visible",
                                  zIndex: 10,
                                  stroke: "#ffffff",
                                }}
                              >
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="100%"
                                  height="100%"
                                  rx="31.5"
                                />
                              </SvgIcon>
                            ),
                          }}
                          {...params}
                          {...field}
                        />
                      )}
                      onChange={(e, value) => handleChange(value, setValues)}
                    />
                  </Box>
                )}
              </Field>

              <FieldArray name="params">
                {() =>
                  Object.keys(values.params).map((param, i) => (
                    <Field
                      name={`params.${param}`}
                      key={`${param}-${i}`}
                      validate={(value) =>
                        value?.toString().trim() === "" && "Error"
                      }
                    >
                      {({ field }) => (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <InputLabel
                            sx={{
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: 14,
                              lineHeight: "21px",
                              ml: 4.5,
                              mb: "0.9375rem",
                              color: "#ADADAD",
                              textTransform: "capitalize",
                            }}
                          >
                            {param}
                          </InputLabel>

                          <TextField
                            sx={{ mr: 1 }}
                            variant="standard"
                            placeholder={param}
                            {...field}
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <SvgIcon
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox={null}
                                  sx={{
                                    fill: "none",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    pointerEvents: "none",
                                    overflow: "visible",
                                    zIndex: 10,
                                    stroke: "#ffffff",
                                  }}
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="100%"
                                    height="100%"
                                    rx="31.5"
                                  />
                                </SvgIcon>
                              ),
                            }}
                            disabled={disabled}
                            error={
                              touched.params?.[param] &&
                              Boolean(errors.params?.[param])
                            }
                          />
                        </Box>
                      )}
                    </Field>
                  ))
                }
              </FieldArray>

              <Box>
                <Button
                  variant="default"
                  type="submit"
                  size="small"
                  disabled={disabled}
                >
                  Query
                </Button>
              </Box>
            </Box>

            <Typography variant="helper.text">
              Note: you can manage your smart contract here. These functions are
              only available to you as a contract owner.
            </Typography>
          </Form>
        )}
      </Formik>

      {alert && (
        <Alert sx={{ mt: 2 }} severity={alert.severity}>
          {alert.message}
        </Alert>
      )}
    </>
  );
};

export default WriteVariable;
