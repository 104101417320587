/** @jsxImportSource @emotion/react */
import { useState, useContext } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { useLoaderData } from "react-router-dom";
import slug from "slug";
import { object, string } from "yup";
import { css } from "@emotion/react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { store } from "../../../config";
import { UserImagesContext } from "../../UserImages/context";
import { WYSIWYG, TextInput, ImagePanel } from "../../";
import { Select, SvgIcon } from "@mui/material";

const Step2 = ({ backStepCallback, nextStepCallback, formData }) => {
  const { slugs, marketplace_slugs } = useLoaderData();

  const initialValues = {
    type_campaign: formData.type_campaign,
    campaign_name: formData?.campaign_name || "",
    images: formData?.images || [],
    mint_url: formData?.mint_url || "",
    flg_marketplace: "flg_marketplace" in formData ? formData.flg_marketplace : true,
    marketplace: {
      category: formData?.marketplace?.category || "1",
      slug: formData?.marketplace?.slug || "",
      meta_title: formData?.marketplace?.meta_title || "",
      meta_keywords: formData?.marketplace?.meta_keywords || "",
      meta_description: formData?.marketplace?.meta_description || "",
      description: formData?.marketplace?.description || [{ type: "paragraph", children: [{ text: "" }] }],
    },
  };

  const validationSchema = object().shape({
    campaign_name: string().required("Field is required"),
    mint_url: string()
      .when("type_campaign", {
        is: (type_campaign) => +type_campaign === 2,
        then: (schema) =>
          schema
            .min(3)
            .matches(/^[a-z0-9-]+$/, "Only letters [a-z] or [0-9] or [-]")
            .isUniqueSlug(slugs, "The slug is already in use by another campaign"),
        otherwise: (schema) => schema.url(({ value }) => `${value} must be a valid URL`),
      })
      .required("Field is required"),
    marketplace: object().when("flg_marketplace", {
      is: true,
      then: object({
        slug: string()
          .min(3)
          .matches(/^[a-z0-9-]+$/, "Only letters [a-z] or [0-9] or [-]")
          .isUniqueSlugMarketplace(marketplace_slugs, "The slug is already in use by another campaign")
          .required("Field is required"),
        meta_title: string().min(3),
        meta_keywords: string().min(3),
        meta_description: string().min(3),
      }),
    }),
  });

  if (formData.type_campaign === "2") {
    initialValues["domain_id"] = 1;
  }

  const categories = store.getState().category;
  const [changed, setChanged] = useState(false);
  const [copied, setCopied] = useState(false);
  const [marketSlugChanged, setChangedMarketSlug] = useState(false);
  const { handleOpen } = useContext(UserImagesContext);

  const handleCopy = (values) => {
    setCopied(true);

    const {
      marketplace: { slug },
    } = values;

    navigator.clipboard.writeText(`https://market.reminto.com/explore/${slug}`);

    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Box
      sx={{
        my: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Formik initialValues={initialValues} onSubmit={(values) => nextStepCallback(values)} validationSchema={validationSchema}>
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Field name="campaign_name">
              {({ field: { onChange, ...field } }) => (
                <TextInput
                  labelText="Campaign Name *"
                  error={touched.campaign_name && Boolean(errors.campaign_name)}
                  helperText={touched.campaign_name && errors.campaign_name}
                  field={{
                    ...field,
                    onChange: (e) => {
                      onChange(e);

                      if (formData.type_campaign === "2" && !changed) {
                        setFieldValue("mint_url", slug(e.currentTarget.value));
                      }

                      if (values.flg_marketplace && !marketSlugChanged) {
                        setFieldValue("marketplace[slug]", slug(e.currentTarget.value));
                      }
                    },
                  }}
                />
              )}
            </Field>

            <FieldArray name="images">
              {({ remove, push }) => (
                <>
                  <InputLabel
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: "21px",
                      ml: 4.5,
                      color: "#ADADAD",
                    }}
                  >
                    Image
                  </InputLabel>

                  <Box
                    sx={{
                      mb: 4.5,
                      p: "20px 36px",
                      background: "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                      borderRadius: 14,
                      position: "relative",
                    }}
                  >
                    <SvgIcon
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={null}
                      sx={{
                        fill: "none",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        overflow: "visible",
                        zIndex: 10,
                      }}
                    >
                      <rect x="0.5" y="0.5" width="100%" height="100%" rx="52.5" stroke="white" />

                      <linearGradient id="paint1_linear_1041_192" x1="417.341" y1="-2.77012" x2="430.937" y2="139.071" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                      </linearGradient>
                    </SvgIcon>

                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {values.images.length > 0 &&
                        values.images.map(({ image, main }, index) => (
                          <Box
                            key={`Images.Item.${index}`}
                            sx={{
                              height: 240,
                              maxWidth: "25%",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                              px: 1.5,
                              mb: 2,
                              "&:before": main
                                ? {
                                    content: '"Main"',
                                    position: "absolute",
                                    left: 24,
                                    top: 18,
                                    lineHeight: "18px",
                                    fontSize: 12,
                                    fontWeight: 500,
                                    px: 1.625,
                                    py: 0.625,
                                    borderRadius: 2.5,
                                    backgroundColor: "#6654F1",
                                    color: "#ffffff",
                                  }
                                : {},
                            }}
                          >
                            <ImagePanel
                              isMain={main}
                              onSetMain={() =>
                                setFieldValue(
                                  "images",
                                  values.images.map((item, i) => ({
                                    ...item,
                                    main: i === index,
                                  }))
                                )
                              }
                              onDelete={() => remove(index)}
                            />

                            {image && (
                              <img
                                src={image}
                                alt=""
                                css={css({
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: 24,
                                })}
                              />
                            )}
                          </Box>
                        ))}
                    </Box>

                    <Box sx={{ textAlign: "center" }}>
                      <Button
                        disableRipple
                        sx={{
                          position: "relative",
                          p: 2,
                          borderRadius: 15,
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: "18px",
                          textTransform: "capitalize",
                          background: "var(--upload-button-text-color)",
                          backgroundClip: "text",
                          textFillColor: "transparent",
                          "&:hover": {
                            background: "var(--upload-button-text-color)",
                            backgroundClip: "text",
                            textFillColor: "transparent",
                          },
                        }}
                        onClick={() => handleOpen((image) => push({ image, main: values.images.length === 0 }))}
                      >
                        <SvgIcon
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          sx={{
                            m: "0 8px 0 7px",
                            path: {
                              fill: "var(--upload-button-icon-fill)",
                            },
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.648 0H11.352C14.264 0 16 1.736 16 4.648V9.52V11.352C16 14.264 14.264 16 11.352 16H4.648C2.608 16 1.136 15.144 0.448 13.624C0.152 12.984 0 12.224 0 11.352V4.648C0 1.736 1.736 0 4.648 0ZM12.44 8.4C13.064 7.864 14.072 7.864 14.696 8.4L14.8 8.488V4.648C14.8 2.392 13.608 1.2 11.352 1.2H4.648C2.392 1.2 1.2 2.392 1.2 4.648V11.352C1.2 11.96 1.304 12.48 1.48 12.928L4.472 10.92C5.112 10.488 6.016 10.536 6.584 11.032L6.856 11.256C7.48 11.792 8.488 11.792 9.112 11.256L12.44 8.4ZM5.59998 6.70394C6.65153 6.70394 7.50398 5.85149 7.50398 4.79994C7.50398 3.74839 6.65153 2.89594 5.59998 2.89594C4.54843 2.89594 3.69598 3.74839 3.69598 4.79994C3.69598 5.85149 4.54843 6.70394 5.59998 6.70394Z"
                          />
                        </SvgIcon>
                        Upload Image
                        <SvgIcon
                          viewBox={null}
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            overflow: "visible",
                            fill: "none",
                            stroke: "var(--upload-button-fill)",
                          }}
                        >
                          <rect x="1" y="1" width="100%" height="100%" rx="25" stroke="url(#paint2_linear_1009_3444)" strokeWidth="2" />
                        </SvgIcon>
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
            </FieldArray>

            <Field name="flg_marketplace">
              {({ field }) => (
                <Box sx={{ display: "flex", alignItems: "center", mb: 4.5 }}>
                  <Checkbox
                    {...field}
                    id="flg_marketplace"
                    defaultChecked={"flg_marketplace" in formData ? formData.flg_marketplace : true}
                    onChange={(e) => {
                      field.onChange(e);
                      setFieldValue("marketplace[slug]", e.currentTarget.checked ? slug(values.campaign_name) : "");
                    }}
                  />
                  <InputLabel htmlFor="flg_marketplace" sx={{ color: "var(--default-text-color)" }}>
                    Show Campaign on Affiliate Marketplace
                  </InputLabel>
                </Box>
              )}
            </Field>

            {values.flg_marketplace && (
              <>
                <Field name="marketplace[category]">
                  {({ field }) => (
                    <Box sx={{ mb: 4.5 }}>
                      <InputLabel
                        sx={{
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: 14,
                          lineHeight: "21px",
                          ml: 4.5,
                          mb: "0.9375rem",
                          color: "#ADADAD",
                        }}
                      >
                        Category
                      </InputLabel>

                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          position: "relative",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: 16,
                          lineHeight: "24px",
                          p: "20px 36px",
                          background: "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                          color: "#ADADAD",
                          borderRadius: 14,
                          ".MuiInputBase-input": {
                            p: 0,
                            "&::placeholder": {
                              color: "#ADADAD",
                            },
                            "&.Mui-disabled": {
                              textFillColor: "#ADADAD",
                            },
                          },
                          ".MuiInputAdornment-root": {
                            mr: 0,
                            color: "var(--default-text-color)",
                            ".MuiTypography-root": {
                              fontSize: "inherit",
                              color: "inherit",
                            },
                          },
                        }}
                      >
                        <Select
                          {...field}
                          disabled={!values.flg_marketplace}
                          IconComponent={KeyboardArrowDownIcon}
                          sx={{
                            width: "100%",
                            "& > fieldset": {
                              display: "none",
                            },
                            ".MuiSelect-select": {
                              color: "#ADADAD",
                              fontSize: 16,
                              lineHeight: "24px",
                            },
                            ".MuiSvgIcon-root": {
                              width: "1em",
                              height: "1em",
                              color: "#ADADAD",
                            },
                          }}
                        >
                          {categories.map(({ id, name }) => (
                            <MenuItem value={id} key={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>

                        <SvgIcon
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={null}
                          sx={{
                            fill: "none",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            pointerEvents: "none",
                            overflow: "visible",
                            zIndex: 10,
                          }}
                        >
                          <rect x="0.5" y="0.5" width="100%" height="100%" rx="31.5" stroke="white" />
                        </SvgIcon>
                      </Box>
                    </Box>
                  )}
                </Field>

                <Field name="marketplace[slug]">
                  {({ field: { onChange, ...field } }) => (
                    <TextInput
                      field={{
                        ...field,
                        onChange: (e) => {
                          onChange(e);
                          if (e.currentTarget.value === "") {
                            setChangedMarketSlug(false);
                          } else {
                            setChangedMarketSlug(true);
                          }
                        },
                        disabled: !values.flg_marketplace,
                      }}
                      labelText="Slug"
                      error={touched?.marketplace?.slug && Boolean(errors?.marketplace?.slug)}
                      helperText={touched?.marketplace?.slug && errors?.marketplace?.slug}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">https://market.reminto.com/explore/</InputAdornment>,
                        endAdornment: (
                          <InputAdornment position="end">
                            {!copied ? (
                              <SvgIcon
                                viewBox="0 0 14 16"
                                sx={{
                                  fill: "var(--default-text-color)",
                                  width: 18,
                                  height: 18,
                                  stroke: "none !important",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleCopy(values)}
                                titleAccess="Copy URL"
                              >
                                <path d="M12.5263 13.0909H4.42105C4.01579 13.0909 3.66874 12.9484 3.3799 12.6633C3.09105 12.3782 2.94688 12.0359 2.94737 11.6364V1.45455C2.94737 1.05455 3.09179 0.712001 3.38063 0.42691C3.66947 0.141819 4.01628 -0.000483614 4.42105 1.23476e-06H8.97105C9.16754 1.23476e-06 9.35495 0.0363648 9.53326 0.109092C9.71158 0.181819 9.86804 0.28485 10.0026 0.418183L13.5763 3.94545C13.7114 4.07879 13.8158 4.23345 13.8895 4.40945C13.9632 4.58545 14 4.77018 14 4.96364V11.6364C14 12.0364 13.8556 12.3789 13.5667 12.664C13.2779 12.9491 12.9311 13.0914 12.5263 13.0909ZM8.84211 1.45455H4.42105V11.6364H12.5263V5.09091H9.57895C9.37018 5.09091 9.19505 5.02109 9.05358 4.88145C8.91211 4.74182 8.84161 4.56921 8.84211 4.36364V1.45455ZM1.47369 16C1.06842 16 0.72137 15.8575 0.432527 15.5724C0.143685 15.2873 -0.000489977 14.945 1.25101e-06 14.5455V5.09091C1.25101e-06 4.88485 0.0707382 4.712 0.212212 4.57236C0.353685 4.43273 0.528563 4.36315 0.736843 4.36364C0.945615 4.36364 1.12074 4.43345 1.26221 4.57309C1.40369 4.71273 1.47418 4.88533 1.47369 5.09091V14.5455H8.84211C9.05088 14.5455 9.226 14.6153 9.36747 14.7549C9.50895 14.8945 9.57944 15.0671 9.57895 15.2727C9.57895 15.4788 9.50821 15.6516 9.36674 15.7913C9.22526 15.9309 9.05039 16.0005 8.84211 16H1.47369Z" />
                              </SvgIcon>
                            ) : (
                              <SvgIcon
                                viewBox="0 0 24 24"
                                sx={{
                                  fill: "var(--default-text-color)",
                                  width: 18,
                                  height: 18,
                                  stroke: "none !important",
                                  cursor: "pointer",
                                }}
                              >
                                <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4l8-8l-1.41-1.42Z" />
                              </SvgIcon>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>

                <Field name="marketplace[description]">
                  {({ field }) => (
                    <WYSIWYG
                      onChange={(content) => setFieldValue("marketplace[description]", content)}
                      value={field.value}
                      disabled={!values.flg_marketplace}
                      name="Description"
                    />
                  )}
                </Field>

                <Field name="marketplace[meta_title]">
                  {({ field }) => (
                    <TextInput
                      field={{ ...field, disabled: !values.flg_marketplace }}
                      labelText="Meta Title"
                      error={touched?.marketplace?.meta_title && Boolean(errors?.marketplace?.meta_title)}
                      helperText={touched?.marketplace?.meta_title && errors?.marketplace?.meta_title}
                    />
                  )}
                </Field>

                <Field name="marketplace[meta_keywords]">
                  {({ field }) => (
                    <TextInput
                      field={{ ...field, disabled: !values.flg_marketplace }}
                      labelText="Meta Keywords"
                      error={touched?.marketplace?.meta_keywords && Boolean(errors?.marketplace?.meta_keywords)}
                      helperText={touched?.marketplace?.meta_keywords && errors?.marketplace?.meta_keywords}
                    />
                  )}
                </Field>

                <Field name="marketplace[meta_description]">
                  {({ field }) => (
                    <TextInput
                      field={{
                        ...field,
                        multiline: true,
                        rows: 4,
                        disabled: !values.flg_marketplace,
                      }}
                      labelText="Meta Description"
                      error={touched?.marketplace?.meta_description && Boolean(errors?.marketplace?.meta_description)}
                      helperText={touched?.marketplace?.meta_description && errors?.marketplace?.meta_description}
                    />
                  )}
                </Field>
              </>
            )}

            <Box sx={{ display: "flex" }}>
              {formData.type_campaign === "2" && (
                <Box sx={{ mr: 2 }}>
                  <InputLabel
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: "21px",
                      ml: 4.5,
                      color: "#ADADAD",
                    }}
                  >
                    Domain
                  </InputLabel>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      position: "relative",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 16,
                      lineHeight: "24px",
                      p: "20px 36px",
                      background: "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                      color: "#ADADAD",
                      borderRadius: 14,
                      ".MuiInputBase-input": {
                        p: 0,
                        "&::placeholder": {
                          color: "#ADADAD",
                        },
                        "&.Mui-disabled": {
                          textFillColor: "#ADADAD",
                        },
                      },
                      ".MuiInputAdornment-root": {
                        mr: 0,
                        color: "var(--default-text-color)",
                        ".MuiTypography-root": {
                          fontSize: "inherit",
                          color: "inherit",
                        },
                      },
                    }}
                  >
                    <SvgIcon
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={null}
                      sx={{
                        fill: "none",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        overflow: "visible",
                        zIndex: 10,
                      }}
                    >
                      <rect x="0.5" y="0.5" width="100%" height="100%" rx="31.5" stroke="white" />
                    </SvgIcon>

                    <Field name="domain_id">
                      {({ field }) => (
                        <Select
                          {...field}
                          IconComponent={KeyboardArrowDownIcon}
                          sx={{
                            width: "100%",
                            "& > fieldset": {
                              display: "none",
                            },
                            ".MuiSelect-select": {
                              color: "#ADADAD",
                              fontSize: 16,
                              lineHeight: "24px",
                            },
                            ".MuiSvgIcon-root": {
                              width: "1em",
                              height: "1em",
                              color: "#ADADAD",
                            },
                          }}
                        >
                          <MenuItem value={1}>nftulla.com</MenuItem>
                        </Select>
                      )}
                    </Field>
                  </Box>
                </Box>
              )}

              <Box sx={{ flexGrow: 1 }}>
                <Field name="mint_url">
                  {({ field: { onChange, ...field } }) => (
                    <TextInput
                      field={{
                        ...field,
                        onChange: (e) => {
                          onChange(e);

                          if (e.currentTarget.value === "") {
                            setChanged(false);
                          } else {
                            setChanged(true);
                          }
                        },
                      }}
                      labelText="Mint URL *"
                      error={touched.mint_url && Boolean(errors.mint_url)}
                      helperText={
                        (touched.mint_url && errors.mint_url) ||
                        (formData.type_campaign !== "2" &&
                          "Note: you can insert your REminto marketplace campaign URL here, or a link to a mint page on your external website.")
                      }
                    />
                  )}
                </Field>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 6.875 }}>
              <Button variant="default" onClick={() => backStepCallback()} sx={{ mx: 1.75 }}>
                Back
              </Button>

              <Button type="submit" variant="default" sx={{ mx: 1.75 }}>
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Step2;
