import { useState } from "react";
import hljs from "highlight.js";
import "highlight.js/scss/devibeans.scss";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import "./index.scss";

const Code = ({ codeType, code }) => {
  const [coped, setState] = useState(false);

  const handlerClipboard = () => {
    navigator.clipboard.writeText(code);

    setState(true);
    setTimeout(() => setState(false), 2000);
  };

  return (
    <Box className="snippet-source" sx={{ mb: 6 }}>
      <Typography className="snippet-title">
        <Typography className="snippet-tag" component="span">
          {codeType}
        </Typography>
      </Typography>

      <Box className="snippet-code">
        <code
          dangerouslySetInnerHTML={{ __html: hljs.highlightAuto(code).value }}
        ></code>

        <Button
          variant="default"
          onClick={handlerClipboard}
          sx={{
            p: "8px 16px",
            minWidth: 0
          }}
        >
          {coped ? "Copied" : "Copy"}
        </Button>
      </Box>
    </Box>
  );
};

export default Code;
