import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import PropTypes from "prop-types";

const Logs = ({ list }) => {
  if (list?.length === 0) {
    return null;
  }

  return (
    <Timeline
      sx={{
        maxWidth: 828,
        width: "100%",
      }}
    >
      {list.map(({ time, message }, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>{time}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            {index < list.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>{message}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

Logs.propTypes = {
  list: PropTypes.array,
};

Logs.defaultProps = {
  list: [],
};

export default Logs;
