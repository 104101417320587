import { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Contract } from "../../config";
import { Logs } from "../";

const reducer = (state, action) => {
  switch (action.type) {
    case "disabledFinish":
      return { ...state, disabled: action.payload };
    case "add_log":
      return {
        ...state,
        logs: [
          ...state.logs,
          {
            message: action.payload,
            time: new Date().toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }),
          },
        ],
      };
    case "clear_logs":
      return { ...state, logs: [] };
    default:
      throw new Error();
  }
};

const VerifyContract = ({ nextStepCallback }) => {
  const { campaignid: campaignId } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, {
    logs: [],
    disabled: false,
  });

  const handleFinish = () => {
    nextStepCallback();
    setTimeout(() => navigate(), 1000);
  };

  useEffect(() => {
    dispatch({ type: "clear_logs" });

    Contract.verify({ campaignId }, (payload) =>
      dispatch({ type: "add_log", payload })
    ).then((result) => dispatch({ type: "disabledFinish", payload: result }));
  }, [campaignId]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Logs list={state.logs} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button
          variant="default"
          onClick={handleFinish}
          disabled={!state.disabled}
        >
          Finish
        </Button>
      </Box>
    </>
  );
};

VerifyContract.propTypes = {
  nextStepCallback: PropTypes.func,
};

export default VerifyContract;
