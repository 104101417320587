import { useContext } from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";

import { UserImagesContext } from "../../../../UserImages/context";

export default function Image({ name, label, helperText }) {
  const { handleOpen } = useContext(UserImagesContext);

  return (
    <div className="mb-2">
      <Field name={name}>
        {({ input: { value, onChange } }) => (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& > img": {
                  height: "auto",
                  maxHeight: "200px",
                  objectFit: "contain",
                  maxWidth: "100%",
                },
              }}
            >
              {label && <Typography variant="subtitle2">{label}</Typography>}

              <img src={value} alt="" />
              
              {helperText && (
                <Typography variant="caption">{helperText}</Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <Button
                variant="contained"
                size="medium"
                onClick={() => handleOpen((image) => onChange(image))}
              >
                Choose Image
              </Button>
            </Box>
          </>
        )}
      </Field>
    </div>
  );
}

Image.defaultProps = {
  helperText: null,
};

Image.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
