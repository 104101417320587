import RESTAPI from "../";

export default class IMAGE {
  static async Upload(formData) {
    try {
      const { data } = await RESTAPI.axios.post(`api/images/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async Remove(image) {
    try {
      return await RESTAPI.axios.delete(`api/images/remove`, {
        data: { file: image },
      });
    } catch (e) {
      throw Error(e.message);
    }
  }
}
