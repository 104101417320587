import RESTAPI from "../";

export default class DASHBOARD {
  static async GetData() {
    try {
      const { data } = await RESTAPI.axios.get("api/dashboard");
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }
}
