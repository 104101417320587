import { useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { validationSchema } from "../../config";
import { CAMPAIGN } from "../../config/RESTAPI/";
import { TextInput } from "../";

const ConfirmContract = ({ nextStepCallback }) => {
  const [activeNextStep, setActiveNextStep] = useState(true);
  const [alert, setAlert] = useState(false);
  const data = useLoaderData();
  const { campaignid } = useParams();

  const handleSubmit = (values, actions) => {
    if (!values.contract.limit_enable || values.contract.nft_type === "single") {
      values.contract.max_mint_amount = 0;
    }

    if (values.contract.nft_type === "single" && values.contract.single_type !== "limited") {
      values.contract.total_number = 0;
    }

    CAMPAIGN.SetContractDataByCampaignId(campaignid, { ...values.contract })
      .then((data) => {
        if (data.status === "success") {
          setActiveNextStep(true);
          setAlert({ type: "success", message: "The smart contract has been updated." });
        }
      })
      .catch((e) => {
        setAlert({
          type: "error",
          message: "Something went wrong when saving the updates. Please try again later.",
        });

        actions.setSubmitting(false);
      });
  };

  const overrideHandleChange = (e, handleChange) => {
    setActiveNextStep(false);
    handleChange(e);
  };

  const initialValues = {
    contract: {
      nft_type: data.nft_type,
      nft_name: data.nft_name,
      nft_symbol: data.nft_symbol,
      hidden_nft: data.hidden_nft,
      cid: data.cid,
      nft_price: data.nft_price,
      affiliate_percent: data.affiliate_percent,
      limit_enable: data.limit_enable,
      max_mint_amount: data.limit_enable ? data.max_mint_amount : "",
      single_type: data.single_type ?? "one",
      total_number: data.total_number ?? "",
    },
  };

  return (
    <>
      {alert && (
        <Alert severity={alert.type} sx={{ my: 2 }}>
          {alert.message}
        </Alert>
      )}

      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema.contract}>
        {({ values, errors, touched, isSubmitting, handleChange }) => (
          <Form>
            <Field name="contract[nft_type]">
              {({ field }) => (
                <Box sx={{ mb: 4.5 }}>
                  <InputLabel
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: "21px",
                      ml: 4.5,
                      color: "#ADADAD",
                    }}
                  >
                    NFT Type
                  </InputLabel>

                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      position: "relative",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 16,
                      lineHeight: "24px",
                      p: "20px 36px",
                      background: "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                      color: "#ADADAD",
                      borderRadius: 14,
                      ".MuiInputBase-input": {
                        p: 0,
                        "&::placeholder": {
                          color: "#ADADAD",
                        },
                        "&.Mui-disabled": {
                          textFillColor: "#ADADAD",
                        },
                      },
                      ".MuiInputAdornment-root": {
                        mr: 0,
                        color: "var(--default-text-color)",
                        ".MuiTypography-root": {
                          fontSize: "inherit",
                          color: "inherit",
                        },
                      },
                    }}
                  >
                    <SvgIcon
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={null}
                      sx={{
                        fill: "none",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        overflow: "visible",
                        zIndex: 10,
                      }}
                    >
                      <rect x="0.5" y="0.5" width="100%" height="100%" rx="31.5" stroke="white" />
                    </SvgIcon>

                    <Select
                      {...field}
                      disabled={isSubmitting}
                      IconComponent={KeyboardArrowDownIcon}
                      onChange={(e) => overrideHandleChange(e, handleChange)}
                      sx={{
                        width: "100%",
                        "& > fieldset": {
                          display: "none",
                        },
                        ".MuiSelect-select": {
                          color: "#ADADAD",
                          fontSize: 16,
                          lineHeight: "24px",
                        },
                        ".MuiSvgIcon-root": {
                          width: "1em",
                          height: "1em",
                          color: "#ADADAD",
                        },
                      }}
                    >
                      <MenuItem value="single">Single</MenuItem>
                      <MenuItem value="collection">Collection</MenuItem>
                    </Select>
                  </Box>

                  <Typography variant="helper.text" component="p" sx={{ mt: 1 }}>
                    Note: if you have one source file that will be tokenized, select Single. If you have multiple source files, select Collection.
                  </Typography>
                </Box>
              )}
            </Field>

            <Field name="contract[nft_name]">
              {({ field: { onChange, ...field } }) => (
                <TextInput
                  field={{
                    ...field,
                    onChange: (e) => overrideHandleChange(e, handleChange),
                    disabled: isSubmitting,
                  }}
                  labelText="NFT Name"
                  error={touched.contract?.nft_name && Boolean(errors.contract?.nft_name)}
                  helperText={touched.contract?.nft_name && errors.contract?.nft_name}
                />
              )}
            </Field>

            <Field name="contract[nft_symbol]">
              {({ field: { onChange, ...field } }) => (
                <TextInput
                  field={{
                    ...field,
                    onChange: (e) => overrideHandleChange(e, handleChange),
                    disabled: isSubmitting,
                  }}
                  labelText="Symbol"
                  error={touched.contract?.nft_symbol && Boolean(errors.contract?.nft_symbol)}
                  helperText={touched.contract?.nft_symbol && errors.contract?.nft_symbol}
                />
              )}
            </Field>

            <Field name="contract[hidden_nft]">
              {({ field }) => (
                <Box sx={{ display: "flex", alignItems: "center", mb: 4.5 }}>
                  <Checkbox
                    {...field}
                    id="hidden_nft"
                    defaultChecked={values.contract.hidden_nft}
                    disabled={isSubmitting}
                    onChange={(e) => overrideHandleChange(e, handleChange)}
                  />
                  <InputLabel htmlFor="hidden_nft" sx={{ color: "var(--default-text-color)" }}>
                    Delayed Reveal
                  </InputLabel>
                </Box>
              )}
            </Field>

            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              {values.contract.hidden_nft ? (
                <span>
                  Provide a link to your hidden NFT Metadata. If it's stored on IPFS, the URL will be like this:
                  ipfs://QmXtCxaLpmz8gmWZ6M1NcuWMyaC75hv4SYF1J7o9YA82Qx where the last part is your CID. If the metadata is stored in the Cloud, provide a full
                  URL to the JSON file or folder (i.e. https://example-domain.com/folder/hidden.json)
                </span>
              ) : values.contract.nft_type === "single" ? (
                <span>
                  Provide a link to your NFT Metadata. If it's stored on IPFS, the URL will be like this: ipfs://QmXtCxaLpmz8gmWZ6M1NcuWMyaC75hv4SYF1J7o9YA82Qx
                  where the last part is your CID. If the metadata is stored in the Cloud, provide a full URL to the JSON file (i.e.
                  https://example-domain.com/folder/1.json).
                </span>
              ) : (
                <span>
                  Provide a link to your NFT Metadata folder. If it's stored on IPFS, the URL will be like this:
                  ipfs://QmXtCxaLpmz8gmWZ6M1NcuWMyaC75hv4SYF1J7o9YA82Qx where the last part is your CID. If the metadata is stored in the Cloud, provide a full
                  URL to the folder with JSON files (i.e. https://example-domain.com/folder/). Important: Make sure your JSON files start with number 1.
                  Example: 1.json, 2.json, etc.
                </span>
              )}
            </Alert>

            <Field name="contract[cid]">
              {({ field: { onChange, ...field } }) => (
                <TextInput
                  field={{
                    ...field,
                    onChange: (e) => overrideHandleChange(e, handleChange),
                    disabled: isSubmitting,
                  }}
                  labelText="CID"
                  error={touched.contract?.cid && Boolean(errors.contract?.cid)}
                  helperText={
                    values.contract.hidden_nft &&
                    "Note: You will be able to further reveal your NFTs while managing your smart contract through REminto dashboard."
                  }
                />
              )}
            </Field>

            <Field name="contract[nft_price]">
              {({ field: { onChange, ...field } }) => (
                <TextInput
                  field={{
                    ...field,
                    onChange: (e) => overrideHandleChange(e, handleChange),
                    disabled: isSubmitting,
                  }}
                  labelText="NFT Price (ETH)"
                  error={touched.contract?.nft_price && Boolean(errors.contract?.nft_price)}
                  helperText={
                    <>
                      Note: You can use this{" "}
                      <a href="https://eth-converter.com/" target="_blank" rel="noreferrer">
                        Converter
                      </a>{" "}
                      to correctly calculate price in ETH.
                    </>
                  }
                />
              )}
            </Field>

            <Field name="contract[affiliate_percent]">
              {({ field: { onChange, ...field } }) => (
                <TextInput
                  field={{
                    ...field,
                    onChange: (e) => overrideHandleChange(e, handleChange),
                    disabled: isSubmitting,
                  }}
                  labelText="Affiliate Percent"
                  error={touched.contract?.affiliate_percent && Boolean(errors.contract?.affiliate_percent)}
                  helperText={touched.contract?.affiliate_percent && errors.contract?.affiliate_percent}
                />
              )}
            </Field>

            {values.contract.nft_type === "collection" && (
              <>
                <Box sx={{ mb: 4.5 }}>
                  <Field name="contract[limit_enable]">
                    {({ field }) => (
                      <FormControlLabel
                        {...field}
                        disabled={isSubmitting}
                        control={<Checkbox />}
                        checked={values.contract.limit_enable}
                        onChange={(e) => overrideHandleChange(e, handleChange)}
                        label="Mint Limit per Wallet"
                      />
                    )}
                  </Field>

                  {values.contract.limit_enable && (
                    <Field name="contract[max_mint_amount]">
                      {({ field: { onChange, ...field } }) => (
                        <TextInput
                          field={{
                            ...field,
                            onChange: (e) => overrideHandleChange(e, handleChange),
                            disabled: isSubmitting,
                          }}
                          sx={{ mb: 1 }}
                          error={touched?.contract?.max_mint_amount && Boolean(errors?.contract?.max_mint_amount)}
                          helperText={touched?.contract?.max_mint_amount && errors?.contract?.max_mint_amount}
                        />
                      )}
                    </Field>
                  )}

                  <Typography variant="helper.text" component="p">
                    Note: This lets you limit the quantity of NFTs that one wallet address can mint.
                  </Typography>
                </Box>

                <Box>
                  <Field name="contract[total_number]">
                    {({ field: { onChange, ...field } }) => (
                      <TextInput
                        labelText="Total Number of NFTs"
                        field={{
                          ...field,
                          onChange: (e) => overrideHandleChange(e, handleChange),
                          disabled: isSubmitting,
                        }}
                        error={touched?.contract?.total_number && Boolean(errors?.contract?.total_number)}
                        helperText="Note: Input the total number of NFTs in the collection, for example, 1000."
                      />
                    )}
                  </Field>
                </Box>
              </>
            )}

            {values.contract.nft_type === "single" && (
              <Field name="contract[single_type]">
                {({ field }) => (
                  <Box sx={{ mb: 4.5 }}>
                    <InputLabel
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        ml: 4.5,
                        color: "#ADADAD",
                      }}
                    >
                      Number of Tokens
                    </InputLabel>

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        position: "relative",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "24px",
                        p: "20px 36px",
                        background: "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                        color: "#ADADAD",
                        borderRadius: 14,
                        ".MuiInputBase-input": {
                          p: 0,
                          "&::placeholder": {
                            color: "#ADADAD",
                          },
                          "&.Mui-disabled": {
                            textFillColor: "#ADADAD",
                          },
                        },
                        ".MuiInputAdornment-root": {
                          mr: 0,
                          color: "var(--default-text-color)",
                          ".MuiTypography-root": {
                            fontSize: "inherit",
                            color: "inherit",
                          },
                        },
                      }}
                    >
                      <SvgIcon
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={null}
                        sx={{
                          fill: "none",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          pointerEvents: "none",
                          overflow: "visible",
                          zIndex: 10,
                        }}
                      >
                        <rect x="0.5" y="0.5" width="100%" height="100%" rx="31.5" stroke="white" />
                      </SvgIcon>

                      <Select
                        {...field}
                        disabled={isSubmitting}
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          width: "100%",
                          "& > fieldset": {
                            display: "none",
                          },
                          ".MuiSelect-select": {
                            color: "#ADADAD",
                            fontSize: 16,
                            lineHeight: "24px",
                          },
                          ".MuiSvgIcon-root": {
                            width: "1em",
                            height: "1em",
                            color: "#ADADAD",
                          },
                        }}
                        onChange={(e) => overrideHandleChange(e, handleChange)}
                      >
                        <MenuItem value="one">One</MenuItem>
                        <MenuItem value="unlimited">Unlimited</MenuItem>
                        <MenuItem value="limited">Limited</MenuItem>
                      </Select>
                    </Box>

                    <Typography variant="helper.text" component="p" sx={{ mt: 1 }}>
                      Note: <strong>One</strong> - NFT can be minted just once. <strong>Unlimited</strong> - unlimited number of tokens can be created.{" "}
                      <strong>Limited</strong> - limit the number of times NFT can be minted.
                    </Typography>
                  </Box>
                )}
              </Field>
            )}

            {values.contract.nft_type === "single" && values.contract.single_type === "limited" && (
              <Box>
                <Field name="contract[total_number]">
                  {({ field }) => (
                    <TextInput
                      labelText="Total Number of NFTs"
                      field={{ ...field, disabled: isSubmitting, onChange: (e) => overrideHandleChange(e, handleChange) }}
                      error={touched?.contract?.total_number && Boolean(errors?.contract?.total_number)}
                      helperText="Note: Input the total number of NFTs that can be created, for example, 1000."
                    />
                  )}
                </Field>
              </Box>
            )}

            <Box sx={{ display: "flex", justifyContent: "center", mt: 6.875 }}>
              <Button variant="default" type="submit" disabled={activeNextStep || isSubmitting} sx={{ mx: 1.75 }}>
                Save
              </Button>

              <Button variant="default" onClick={nextStepCallback} disabled={!activeNextStep} sx={{ mx: 1.75 }}>
                Next Step
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConfirmContract;
