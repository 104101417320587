import React from "react";
import { createTheme } from "@mui/material";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Cookies from "js-cookie";

export const ThemeModeContext = React.createContext({});

export class ThemeModeContextProvider extends React.Component {
  constructor(props) {
    super(props);

    const mode = Cookies.get("reminto-theme") || "light";

    this.theme = {
      mode,
      palette: {
        background: {
          default: "var(--theme-background)",
        },
        text: {
          default: "var(--default-text-color)",
          primary: "#673ab7",
        },
      },
      typography: {
        fontFamily: ["Poppins"],
        fontSize: 16,
        header_user: {
          fontSize: "1.25rem",
          fontWeight: 600,
          lineHeight: "1.875rem",
          marginRight: 20,
        },
        menuitem: {
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 20,
          lineHeight: "30px",
        },
        tilenumber: {
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 36,
          lineHeight: "54px",
          marginBottom: 4,
        },
        tilecaption: {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "24px",
        },
        papercaption: {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 24,
          lineHeight: "36px",
          display: "flex",
          alignItems: "center",
          marginBottom: 42,
          "& > svg": {
            marginRight: 20,
          },
        },
        iconcounternumber: {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 24,
          lineHeight: "36px",
        },
        iconcountertitle: {
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "24px",
        },
        "paper.campaign.name": {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "24px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        "paper.campaign.time": {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "24px",
        },
        "paper.campaign.date": {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "24px",
          color: "#ADADAD",
        },
        copyright: {
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "20px",
          opacity: 0.4,
        },
        title: {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 24,

          "& > span": {
            background: "var(--text-gradient)",
            backgroundClip: "text",
            textFillColor: "transparent",
          },
        },
        h6: {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 22,
          lineHeight: "33px",
          color: "var(--default-text-color)",
        },
        caption: {
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 12,
          lineHeight: "18px",
          color: "var(--default-text-color)",
        },
        "helper.text": {
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 12,
          lineHeight: "18px",
          color: "var(--default-text-color)",
          marginLeft: 36,
          "&.Mui-error": {
            color: "#FF004D",
          },
        },
        "billing.text": {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 18,
          lineHeight: "27px",
          color: "var(--defautl-text-color)",
          display: "inline-flex",
          alignItems: "center",
        },
        "success.round": {
          display: "inline-flex",
          width: 8,
          height: 8,
          borderRadius: "50%",
          background: "#15EB1D",
          marginLeft: 5,
        },
        "error.round": {
          display: "inline-flex",
          width: 8,
          height: 8,
          borderRadius: "50%",
          background: "#FF2424",
          marginLeft: 5,
        },
        "accordion.title": {
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 18,
          lineHeight: "22px",
          color: "var(--default-text-color)",
        },
        "menu.item": {
          color: "var(--default-text-color)",
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            [`[data-theme="light"]`]: {
              "--theme-background": "rgba(102, 84, 241, 0.03)",
              "--default-text-color": "#202020",
              "--step-label-text-color": "#ADADAD",
              "--step-icon-active-color": "#673AB7",
              "--step-icon-comleted-color": "#8BD244",
              "--text-gradient":
                "linear-gradient(91.96deg, #DC70EE 0%, #6E3EF7 100%, #673AB7 100%)",
              "--warning-text-color": "#FFCD1D",
              "--warning-background-color":
                "linear-gradient(118.77deg, rgba(250, 255, 0, 0.15) 20.62%, rgba(250, 255, 0, 0.075) 99.9%)",
              "--error-background-color":
                "linear-gradient(118.77deg, rgba(255, 54, 78, 0.15) 20.62%, rgba(255, 54, 78, 0.075) 99.9%)",
              "--success-background-color":
                "linear-gradient(118.77deg, rgba(143, 255, 0, 0.15) 20.62%, rgba(143, 255, 0, 0.075) 99.9%)",
              "--error-text-color": "#FF004D",
              "--success-text-color": "#8FFF00",
              "--alert-text-color": "rgba(32, 32, 32, 0.8)",
              "--upload-button-fill": "url(#paint2_linear_1009_3444)",
              "--upload-button-text-color": "#6654F1",
              "--upload-button-icon-fill": "#6654F1",
              "--tooltip-background-color": "#673AB7",
              "--tooltip-text-color": "#ffffff",
              "--gradient-button-background": "#6654F1",
            },
            [`[data-theme="dark"]`]: {
              "--theme-background": "#000000",
              "--default-text-color": "#ffffff",
              "--step-label-text-color": "rgba(255, 255, 255, 0.4)",
              "--step-icon-active-color": "#673AB7",
              "--step-icon-comleted-color": "#8BD244",
              "--text-gradient":
                "linear-gradient(90deg, #EDFF1D -27.05%, #F2A4FF 33.94%, #6654F1 100%)",
              "--warning-text-color": "#EDFF1D",
              "--warning-background-color":
                "linear-gradient(118.77deg, rgba(252, 255, 117, 0.15) 20.62%, rgba(252, 255, 117, 0.075) 99.9%)",
              "--error-background-color":
                "linear-gradient(118.77deg, rgba(255, 128, 143, 0.15) 20.62%, rgba(255, 128, 143, 0.075) 99.9%)",
              "--success-background-color":
                "linear-gradient(118.77deg, rgba(190, 255, 106, 0.15) 20.62%, rgba(190, 255, 106, 0.075) 99.9%)",
              "--error-text-color": "#FF0002",
              "--success-text-color": "#42FF00",
              "--alert-text-color": "rgb(255, 255, 255)",
              "--upload-button-fill": "url(#paint2_linear_1009_3444)",
              "--upload-button-text-color":
                "linear-gradient(90deg, #EDFF1D 0%, #F2A4FF 48%, #6654F1 100%)",
              "--upload-button-icon-fill": "url(#paint0_linear_1009_3445)",
              "--tooltip-background-color": "#FFFFFF",
              "--tooltip-text-color": "#673AB7",
              "--gradient-button-background":
                "linear-gradient(90deg, #EDFF1D 0%, #F2A4FF 48%, #6654F1 100%)",
            },
            body: {
              position: "relative",
              overflowX: "hidden",
            },
            a: {
              color: "#9747FF",
              textDecoration: "none",
              "&:hover": {
                color: "#9747FF",
                textDecoration: "underline",
              },
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              position: "static",
              boxShadow: "none",
              backgroundColor: "transparent",
              paddingTop: 64,
              paddingBottom: 84,
              paddingLeft: 77,
              paddingRight: "77px!important",
              flexDirection: "row",
            },
          },
        },
        MuiButton: {
          variants: [
            {
              props: { variant: "default" },
              style: {
                background:
                  "linear-gradient(91.96deg, #DC70EE 0%, #6E3EF7 100%)",
                color: "#ffffff",
                textTransform: "capitalize",
                fontStyle: "normal",
                fontSize: 18,
                lineHeight: "27px",
                borderRadius: 60,
                padding: 16,
                minWidth: 172,
                fontWeight: 400,
                transition: "background .3s ease",
                "&:hover": {
                  background:
                    "linear-gradient(91.96deg, #673AB7 0%, #DC70EE 100%)",
                  color: "#ffffff",
                },
                "&:disabled": {
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(91.96deg, rgba(220, 112, 238, 0.5) 0%, rgba(110, 62, 247, 0.5) 100%, rgba(103, 58, 183, 0.5) 100%)",
                  color: "rgba(255, 255, 255, 0.5)",
                },
              },
            },
            {
              props: { variant: "gradient" },
              style: {
                padding: "24px 40px",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 18,
                lineHeight: "13px",
                background: "var(--gradient-button-background)",
                backgroundClip: "text",
                textFillColor: "transparent",
                textTransform: "capitalize",
                position: "relative",
                "&:hover": {
                  backgroundColor: "var(--gradient-button-background)",
                },
                "&:disabled": {
                  opacity: 0.5,
                },
              },
            },
          ],
          defaultProps: {
            // disableRipple: true,
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                opacity: 0.5,
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                opacity: 0.5,
                "& + .MuiSvgIcon-root": {
                  opacity: 0.5,
                },
              },
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              width: "auto",
              height: "auto",
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              padding: 0,
              width: "100%",
            },
          },
        },
        MuiListItem: {
          defaultProps: {
            disablePadding: true,
          },
          styleOverrides: {
            root: {
              marginBottom: 28,
              display: "flex",
              flexDirection: "column",
              "&:last-child": {
                marginBottom: 0,
              },
            },
          },
        },
        MuiListItemButton: {
          defaultProps: {
            disableRipple: true,
          },
          styleOverrides: {
            root: {
              padding: "20px 36px",
              position: "relative",
              width: "100%",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: 0,
              color: "transparent",
              marginRight: 18,
            },
          },
        },
        MuiListItemText: {
          defaultProps: {
            primaryTypographyProps: {
              variant: "menuitem",
              color: "text.default",
            },
            secondaryTypographyProps: {
              color: "text.default",
              opacity: 0.6,
            },
          },
          styleOverrides: {
            root: {
              margin: 0,
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            elevation0: {
              borderRadius: 24,
              padding: "28px 36px",
              color: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backdropFilter: "blur(10px)",
              width: "100%",
              position: "relative",
              backgroundColor: "transparent",
              "& > svg": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                fill: "none",
                pointerEvents: "none",
                strokeWidth: "2px",
                overflow: "visible",
              },
            },

            elevation1: {
              borderRadius: 56,
              padding: "32px 20px",
              background:
                "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
              backdropFilter: "blur(10px)",
              position: "relative",

              ".MuiList-root": {
                ".MuiMenuItem-root": {
                  color: "var(--default-text-color)",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                },
              },
            },

            elevation2: {
              background:
                "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
              backdropFilter: "blur(10px)",
              borderRadius: 56,
              padding: 48,
              position: "relative",
            },

            elevation3: {
              position: "relative",
              borderRadius: 64,
              padding: 64,
              maxWidth: 589,
              width: "100%",
              background: "none",
              "&::before": {
                background:
                  "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                backdropFilter: "blur(25px)",
                borderRadius: 64,
                position: "absolute",
                content: '""',
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                zIndex: -1,
              },
            },
          },
        },
        MuiStepper: {
          defaultProps: {
            connector: (
              <Box sx={{ flex: "1 1 auto", mx: 3.125 }}>
                <SvgIcon
                  viewBox={null}
                  sx={{ width: "100%", height: 4, stroke: "#ADADAD" }}
                >
                  <line
                    x1="6"
                    y1="2"
                    x2="100%"
                    y2="2"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeDasharray="6 24"
                  />
                </SvgIcon>
              </Box>
            ),
          },
          styleOverrides: {
            root: {
              marginBottom: 68,
            },
          },
        },
        MuiStep: {
          styleOverrides: {
            root: {
              padding: 0,
              "&.Mui-completed + .MuiBox-root > svg": {
                stroke: "#9747FF",
              },
            },
          },
        },
        MuiStepLabel: {
          styleOverrides: {
            root: {},
            iconContainer: {
              width: 32,
              height: 32,
              paddingRight: 0,
              marginRight: 12,
              ".MuiStepIcon-root": {
                color: "var(--step-label-text-color)",
                "&.Mui-active": {
                  color: "var(--step-icon-active-color)",
                },
                "&.Mui-completed": {
                  color: "var(--step-icon-comleted-color)",
                },
              },
              // "& > svg.Mui-active": {
              //   color: "#673AB7",
              // },
              // "& > svg.Mui-completed": {
              //   color: "#8BD244",
              // },
              ".MuiStepIcon-text": {
                fontSize: 16,
                fontWeight: 600,
              },
            },
            labelContainer: {
              ".MuiStepLabel-label": {
                color: "var(--step-label-text-color)",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 20,
                lineHeight: "30px",
                "&.Mui-active": {
                  fontWeight: 600,
                  color: "var(--default-text-color)",
                },
                "&.Mui-completed": {
                  color: "var(--default-text-color)",
                },
              },
            },
          },
        },
        MuiAlert: {
          defaultProps: {
            iconMapping: {
              error: (
                <SvgIcon
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    width: 36,
                    height: 36,
                    color: "var(--error-text-color)",
                  }}
                >
                  <path
                    d="M0 18C0 21.5601 1.05568 25.0402 3.03355 28.0003C5.01141 30.9603 7.82263 33.2674 11.1117 34.6298C14.4008 35.9922 18.02 36.3487 21.5116 35.6541C25.0033 34.9596 28.2106 33.2453 30.7279 30.7279C33.2453 28.2106 34.9596 25.0033 35.6541 21.5116C36.3487 18.02 35.9922 14.4008 34.6298 11.1117C33.2674 7.82263 30.9603 5.01141 28.0003 3.03355C25.0402 1.05568 21.5601 0 18 0C13.2261 0 8.64773 1.89642 5.27208 5.27208C1.89642 8.64773 0 13.2261 0 18ZM29.7643 27.9643L8.03571 6.23571C10.9937 3.77357 14.765 2.50583 18.6096 2.68131C22.4542 2.85679 26.0944 4.46282 28.8158 7.18418C31.5372 9.90555 33.1432 13.5458 33.3187 17.3904C33.4942 21.2349 32.2264 25.0063 29.7643 27.9643ZM8.02285 29.7771C4.90927 27.1331 2.97129 23.3622 2.63386 19.2914C2.29643 15.2205 3.5871 11.182 6.22286 8.06143L27.9386 29.7771C25.154 32.13 21.6262 33.4209 17.9807 33.4209C14.3352 33.4209 10.8074 32.13 8.02285 29.7771Z"
                    fill="#FF004D"
                  />
                </SvgIcon>
              ),
              warning: (
                <SvgIcon
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    width: 36,
                    height: 36,
                    color: "var(--warning-text-color)",
                  }}
                >
                  <path d="M18 0C20.3638 -3.52233e-08 22.7044 0.465584 24.8883 1.37017C27.0722 2.27475 29.0565 3.60062 30.7279 5.27208C32.3994 6.94353 33.7253 8.92784 34.6298 11.1117C35.5344 13.2956 36 15.6362 36 18C36 20.3638 35.5344 22.7044 34.6298 24.8883C33.7253 27.0722 32.3994 29.0565 30.7279 30.7279C29.0565 32.3994 27.0722 33.7252 24.8883 34.6298C22.7044 35.5344 20.3638 36 18 36C13.2261 36 8.64773 34.1036 5.27208 30.7279C1.89642 27.3523 0 22.7739 0 18C0 13.2261 1.89642 8.64773 5.27208 5.27208C8.64773 1.89642 13.2261 7.11366e-08 18 0ZM18 3C14.0218 3 10.2064 4.58035 7.3934 7.3934C4.58035 10.2064 3 14.0218 3 18C3 21.9782 4.58035 25.7936 7.3934 28.6066C10.2064 31.4196 14.0218 33 18 33C21.9782 33 25.7936 31.4196 28.6066 28.6066C31.4196 25.7936 33 21.9782 33 18C33 14.0218 31.4196 10.2064 28.6066 7.3934C25.7936 4.58035 21.9782 3 18 3ZM18 24C18.5967 24 19.169 24.2371 19.591 24.659C20.0129 25.081 20.25 25.6533 20.25 26.25C20.25 26.8467 20.0129 27.419 19.591 27.841C19.169 28.2629 18.5967 28.5 18 28.5C17.4033 28.5 16.831 28.2629 16.409 27.841C15.9871 27.419 15.75 26.8467 15.75 26.25C15.75 25.6533 15.9871 25.081 16.409 24.659C16.831 24.2371 17.4033 24 18 24ZM18 7.5C18.3511 7.49988 18.6911 7.62291 18.9608 7.84767C19.2305 8.07242 19.4128 8.38466 19.476 8.73L19.5 9V19.5C19.5007 19.8748 19.361 20.2363 19.1085 20.5133C18.8559 20.7903 18.5088 20.9628 18.1355 20.9966C17.7622 21.0305 17.3898 20.9234 17.0915 20.6963C16.7932 20.4693 16.5908 20.1388 16.524 19.77L16.5 19.5V9C16.5 8.60218 16.658 8.22064 16.9393 7.93934C17.2206 7.65804 17.6022 7.5 18 7.5Z" />
                </SvgIcon>
              ),
              success: (
                <SvgIcon
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    width: 36,
                    height: 36,
                    color: "var(--success-text-color)",
                  }}
                >
                  <path d="m29.09701,10.43935c-0.2082,-0.20695 -0.4898,-0.32311 -0.7833,-0.32311c-0.2936,0 -0.5752,0.11616 -0.7834,0.32311l-12.33331,12.27781l-6.66666,-6.6667c-0.20334,-0.21954 -0.48555,-0.34932 -0.78457,-0.36078c-0.29901,-0.01146 -0.59034,0.09633 -0.80988,0.29966c-0.21954,0.20334 -0.34931,0.48556 -0.36077,0.78457c-0.01146,0.29902 0.09633,0.59034 0.29966,0.80988l8.32222,8.29997l13.90001,-13.86664c0.1041,-0.10329 0.1868,-0.22618 0.2432,-0.36158c0.0564,-0.1354 0.0855,-0.28063 0.0855,-0.42731c0,-0.14667 -0.0291,-0.2919 -0.0855,-0.4273c-0.0564,-0.1354 -0.1391,-0.25829 -0.2432,-0.36158z" />
                  <path d="m18,0.22217c-3.5162,0 -6.9533,1.04265 -9.87685,2.99609c-2.92354,1.95345 -5.20216,4.72996 -6.54772,7.97844c-1.34556,3.2484 -1.69762,6.823 -1.01166,10.2715c0.68596,3.4486 2.37913,6.6163 4.8654,9.1025c2.48626,2.4863 5.65393,4.1795 9.10253,4.8654c3.4485,0.686 7.0231,0.3339 10.2715,-1.0116c3.2485,-1.3456 6.025,-3.6242 7.9784,-6.5477c1.9535,-2.9236 2.9961,-6.3607 2.9961,-9.8769c0,-4.7149 -1.873,-9.23676 -5.207,-12.57074c-3.3339,-3.33398 -7.8558,-5.20699 -12.5707,-5.20699zm0,33.33333c-3.0766,0 -6.0842,-0.9123 -8.64225,-2.6216c-2.5581,-1.7092 -4.55189,-4.1387 -5.72926,-6.9811c-1.17736,-2.8424 -1.48541,-5.9701 -0.8852,-8.9876c0.60022,-3.0175 2.08174,-5.78921 4.25722,-7.96469c2.17549,-2.17549 4.94719,-3.65701 7.96469,-4.25722c3.0175,-0.60022 6.1452,-0.29217 8.9876,0.8852c2.8424,1.17736 5.2719,3.17115 6.9811,5.72925c1.7093,2.55806 2.6216,5.56556 2.6216,8.64216c0,4.1256 -1.6389,8.0823 -4.5561,10.9995c-2.9172,2.9172 -6.8739,4.5561 -10.9994,4.5561z" />
                </SvgIcon>
              ),
            },
          },
          styleOverrides: {
            root: {
              justifyContent: "initial",
              padding: "28px 20px 28px 28px",
              border: "1px solid rgba(255, 255, 255, 0.3)",
              borderRadius: 20,
              marginBottom: 45,
            },
            icon: {
              margin: 0,
              padding: 0,
              marginRight: 24,
            },
            standardWarning: {
              background: "var(--warning-background-color)",
            },
            standardError: {
              background: "var(--error-background-color)",
            },
            standardSuccess: {
              background: "var(--success-background-color)",
            },
            message: {
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
              color: "var(--alert-text-color)",
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              ".MuiSvgIcon-root": {
                width: 24,
                height: 24,
                color: "#ADADAD",
              },
              ".Mui-checked": {
                ".MuiSvgIcon-root": {
                  color: "#673AB7",
                },
              },
            },
            label: {
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 18,
              lineHeight: "27px",
              color: "var(--default-text-color)",
              "&.Mui-disabled": {
                color: "var(--default-text-color)",
                opacity: 0.3,
              },
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              marginLeft: 36,
              paddingLeft: 0,
              ".MuiSvgIcon-root": {
                width: 24,
                height: 24,
                color: "#ADADAD",
              },
              "& + .MuiFormLabel-root": {
                marginBottom: 0,
              },
              "&.Mui-checked": {
                ".MuiSvgIcon-root": {
                  color: "#673AB7",
                },
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              marginBottom: "0.9375rem",
              "&.Mui-error": {
                color: "#FF004D",
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 14,
              lineHeight: "21px",
              color: "#ADADAD",
              padding: "20px 0",
              borderBottom: "1px solid #ffffff",
            },
            body: {
              borderBottom: "none",
              padding: "20px 0",
              verticalAlign: "top",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 18,
              lineHeight: "27px",
              color: "var(--default-text-color)",
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              background: "var(--tooltip-background-color)",
              color: "var(--tooltip-text-color)",
              borderRadius: 8,
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 12,
              lineHeight: "18px",
              padding: "1px 4px",
            },
            arrow: {
              width: 29,
              height: 16,
              color: "var(--tooltip-background-color)",
              zIndex: -1,

              "&::before": {
                borderRadius: 2,
              },
            },
          },
        },
        MuiPaginationItem: {
          defaultProps: {
            disableRipple: true,
          },
          styleOverrides: {
            previousNext: {
              padding: 0,
              margin: 0,
              minWidth: 0,
            },
            page: {
              minWidth: 44,
              height: 44,
              padding: "7px 16px",
              margin: "0 20px",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 20,
              lineHeight: "30px",
              color: "#ADADAD",
              borderRadius: 60,
              "&:hover": {
                textDecoration: "none",
              },
              "&.Mui-selected": {
                color: "#ffffff",
                background:
                  "linear-gradient(91.96deg, #DC70EE 0%, #6E3EF7 100%, #673AB7 100%)",
              },
            },
          },
        },
        MuiTimeline: {
          styleOverrides: {
            root: {
              background:
                "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
              backdropFilter: "blur(10px)",
              padding: 48,
              borderRadius: 56,
              margin: 0,
            },
          },
        },
        MuiTimelineItem: {
          styleOverrides: {
            root: {
              marginBottom: 16,
              minHeight: 0,
              "&:last-child": {
                marginBottom: 0,
              },
            },
          },
        },
        MuiTimelineOppositeContent: {
          styleOverrides: {
            root: {
              maxWidth: 160,
              marginRight: 16,
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 18,
              lineHeight: "25px",
              color: "var(--default-text-color)",
              textAlign: "left",
              padding: 0,
            },
          },
        },
        MuiTimelineSeparator: {
          styleOverrides: {
            root: {},
          },
        },
        MuiTimelineDot: {
          styleOverrides: {
            root: {
              padding: 8,
              margin: "2.5px 0 18.5px",
              backgroundColor: "#ADADAD",
            },
          },
        },
        MuiTimelineConnector: {
          styleOverrides: {
            root: {
              minHeight: 34,
              backgroundColor: "#ADADAD",
            },
          },
        },
        MuiTimelineContent: {
          styleOverrides: {
            root: {
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 18,
              lineHeight: "25px",
              color: "var(--default-text-color)",
              padding: 0,
              marginLeft: 80,
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              "&::before": {
                backgroundColor: "var(--default-text-color)",
                opacity: 0.1,
              },
              "&:first-of-type": {
                borderTopLeftRadius: 60,
                borderTopRightRadius: 60,
              },
              "&:last-of-type": {
                borderBottomLeftRadius: 60,
                borderBottomRightRadius: 60,
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            content: {
              margin: 0,
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              background: "none",
              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
              borderRadius: "1.125rem !important",
              boxShadow: "4px 4px 10px 0px rgba(0, 7, 72, 0.10) !important",
              backdropFilter: "blur(10px)",
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            inputRoot: {
              position: "relative",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: "1.54rem",
              padding: "20px 36px",
              background:
                "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
              color: "#ADADAD",
              borderRadius: "56px",
              "&.Mui-focused": {
                "& > svg": {
                  stroke: "url(#paint1_linear_1071_2653)",
                },
              },
            },

            input: {
              padding: "0 !important",
            },

            popper: {
              paddingTop: "1rem",
              paddingBottom: "1rem",
            },

            paper: {
              background: "none",
              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
              borderRadius: "1.125rem !important",
              boxShadow: "4px 4px 10px 0px rgba(0, 7, 72, 0.10) !important",
              backdropFilter: "blur(10px)",
            },

            option: {
              color: "var(--default-text-color)",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              ".MuiInputBase-root": {
                position: "relative",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: "1.54rem",
                padding: "20px 36px",
                background:
                  "linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%)",
                color: "#ADADAD",
                borderRadius: "56px",
                "&.Mui-focused": {
                  "& > svg": {
                    stroke: "url(#paint1_linear_1071_2653)",
                  },
                },
                input: {
                  padding: 0,
                },
              },
            },
          },
        },
        MuiBadge: {
          styleOverrides: {
            badge: {
              fontSize: "0.625rem",
              height: "0.9375rem",
              minWidth: "0.9375rem",
            },
          },
        },
      },
    };

    this.state = {
      mode: Cookies.get("reminto-theme") || "light",
      theme: createTheme(this.theme),
    };
  }

  toggleThemeMode() {
    const mode = this.state.mode === "light" ? "dark" : "light";

    document.body.setAttribute("data-theme", mode);
    Cookies.set("reminto-theme", mode, { expires: 365 });

    this.setState({ ...this.state, mode });
  }

  render() {
    return (
      <ThemeModeContext.Provider
        value={{
          ...this.state,
          toggleThemeMode: this.toggleThemeMode.bind(this),
        }}
      >
        {this.props.children}
      </ThemeModeContext.Provider>
    );
  }
}
