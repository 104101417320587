import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Caption = ({ Icon, title }) => {
  return (
    <Box sx={{ mb: 8, display: "flex", alignItems: "center" }}>
      {Icon && <Box sx={{ mr: 2 }}>{Icon}</Box>}

      <Typography component="h2" variant="title">
        <span>{title}</span>
      </Typography>
    </Box>
  );
};

Caption.propTypes = {
  Icon: PropTypes.object,
  title: PropTypes.string,
};

Caption.defaultProps = {
  Icon: null,
};

export default Caption;
