import { useLoaderData } from "react-router-dom";
import { BigNumber, utils } from "ethers";
import moment from "moment";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { Caption } from "..";
import { NETWORKS } from "../../config";
import IconChart from "../../images/icon-chart.png";

const Transactions = () => {
  const data = useLoaderData();

  return (
    <>
      <Caption title={"Transactions"} Icon={<img src={IconChart} alt="" />} />

      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Affiliate Amount</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length > 0 &&
              data.map(
                (
                  {
                    createdAt,
                    hash,
                    value,
                    affiliate_value,
                    Campaign: { ContractSetting },
                  },
                  index
                ) => {
                  const { TX_URL, CURRENCY } =
                    NETWORKS?.[ContractSetting.network_id];

                  return (
                    <TableRow key={`${hash}${index}`}>
                      <TableCell>
                        <Typography variant="billing.text">
                          {moment(createdAt).format("LLL")}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="billing.text">
                          {CURRENCY}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="billing.text"
                          component="a"
                          href={`${TX_URL}${hash}`}
                          target="_blank"
                        >
                          {`${hash.substring(0, 13)}...`}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="billing.text">
                          {utils.formatEther(BigNumber.from(value))}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="billing.text">
                          {utils.formatEther(
                            BigNumber.from(affiliate_value || 0)
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Transactions;
