import { ethers, utils, providers, BigNumber } from "ethers";
import { _web3Modal, messages, config, NETWORKS } from ".";
import { CAMPAIGN } from "./RESTAPI/";

export default class Contract {
  /**
   * Running deploy smart contract in network
   *
   * @param {json} abi
   * @param {string} bytecode
   * @returns <Promise>
   */
  static async deploy(abi, bytecode) {
    const instance = await _web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider.getSigner();

    const factory = new ethers.ContractFactory(abi, bytecode, signer);
    return factory.deploy();
  }

  static async readProp({ contract_address, abi, network_id, prop }) {
    const contract = new ethers.Contract(
      contract_address,
      abi,
      new providers.EtherscanProvider(network_id, config.etherscan_api)
    );

    const { variable, params } = prop;
    const value = await contract[variable].call(null, ...Object.values(params));

    switch (typeof value) {
      case "string":
      default:
        return value;
      case "object":
        return value instanceof BigNumber
          ? value.toString()
          : value.map((v) => v.toNumber());
    }
  }

  static async writeProp({ contract_address, abi, network_id, prop }, func) {
    if (+window.ethereum.networkVersion !== network_id) {
      try {
        await Contract.switchNetwork(network_id);
      } catch (e) {
        return false;
      }
    }

    const instance = await _web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);

    const contract = new ethers.Contract(
      contract_address,
      abi,
      provider.getSigner()
    );

    const { method, params } = prop;
    const { inputs } = abi.find((a) => a.name === method);

    inputs.forEach(({ name, type }) => {
      if (type === "bool") {
        params[name] =
          params[name].toString().toLowerCase() === "false" ? false : true;
      }
    });

    try {
      const transaction = await contract[method].call(
        contract,
        ...Object.values(params)
      );
      const response = await transaction.wait();

      if (response.status === 1) {
        const { TX_URL } = NETWORKS?.[network_id];
        func(
          true,
          <>
            Transaction Executed Succeeded{" "}
            <a
              href={`${TX_URL}${response.transactionHash}`}
              target="_blank"
              rel="noreferrer"
            >
              {response.transactionHash}
            </a>
          </>
        );

        return true;
      }

      return false;
    } catch (e) {
      console.log(e);
      func(false, e.error?.message || e.reason);
      return false;
    }
  }

  /**
   * Sending request to REST API for compile smart contract
   *
   * @param {int} campaignId
   * @returns <Promise>
   */
  static compile(campaignId, networkId) {
    return CAMPAIGN.Compile(campaignId, networkId);
  }

  /**
   * Saving data in DB after deploying smart contract
   *
   * @param {contract, campaignId} param0
   * @returns <Promise>
   */
  static async saveDataFromDeploy({ contract, campaignid, abi }) {
    const { address: contract_address, signer } = contract;
    const network_id = await signer.getChainId();
    const owner = await signer.getAddress();

    return CAMPAIGN.SetContractDataAfterPublish(campaignid, {
      contract_address,
      network_id,
      owner,
      abi,
    });
  }

  /**
   * Sending request to change networkID
   *
   * @param {int} networkVersion
   * @returns <Promise>
   */
  static switchNetwork(networkVersion) {
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: utils.hexValue(networkVersion) }],
    });
  }

  /**
   * Publish smart contract in network
   *
   * @param {networkVersion, campaignid} param0
   * @param {function} callback
   * @returns <Boolean>
   */
  static async publish({ networkVersion, campaignid }, callback) {
    // CHANGE NETWORK ID
    if (+window.ethereum.networkVersion !== networkVersion) {
      try {
        callback(messages.switch_network_id_request);
        await Contract.switchNetwork(networkVersion);
      } catch (e) {
        callback(e.message);
        return false;
      }
    }

    // COMPILE CONTRACT
    callback(messages.send_contract_to_compile);

    const compile = await Contract.compile(campaignid, networkVersion);

    if (compile.status === "fail") {
      callback(messages.compile_failed);
      return false;
    }

    callback(messages.compile_success);

    // DEPLOY
    const { abi, bytecode } = compile;

    callback(messages.confirmation_deploy);

    try {
      const contract = await Contract.deploy(abi, bytecode);

      callback(messages.start_deploy);
      await contract.deployTransaction.wait();
      callback(messages.deploy_success);

      // SAVE IN DB
      callback(messages.save_db);
      await Contract.saveDataFromDeploy({ contract, campaignid, abi });
    } catch (e) {
      if (e.code === "ACTION_REJECTED") {
        callback(messages.user_rejected);
        return false;
      }

      console.log(e);

      callback(e.reason);
      return false;
    }

    return true;
  }

  /**
   * Verify smart contract
   *
   * @param {campaignId} param0
   * @param {function} callback
   * @returns <Boolean>
   */
  static async verify({ campaignId }, callback) {
    callback("Sending request with contract data");

    const verifyResponse = await CAMPAIGN.VerifySourceCode(campaignId);

    if (verifyResponse.status === "fail") {
      callback(verifyResponse.result);
      return false;
    }

    callback("Waiting for verification confirmation");

    await new Promise((resolve) =>
      setTimeout(async () => resolve(), 30 * 1000)
    );

    callback("Checking contract verification status");

    const checkResponse = await CAMPAIGN.CheckVerifyStatus(campaignId);

    if (checkResponse.status === "fail") {
      callback(checkResponse.result);
      return false;
    }

    callback("The contract has been verified");

    return true;
  }
}
