import RESTAPI from "../";

export default class STATISTIC {
  static async GetStats(campaignId = null) {
    try {
      const { data } = await RESTAPI.axios.get(
        `api/statistics${(campaignId && `/${campaignId}`) || ""}`
      );
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }
}
