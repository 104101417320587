import RESTAPI from "../";

export default class CAMPAIGN {
  static async GetList(page = 1) {
    try {
      const { data } = await RESTAPI.axios.get(`api/campaign/list/${page}`);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async GetSlugList(excluded_hosting = "", excluded_marketplace = "") {
    try {
      const { data } = await RESTAPI.axios.get(`api/campaign/slugs`, {
        params: { excluded_hosting, excluded_marketplace },
      });
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async GetCampaignById(id) {
    try {
      const { data } = await RESTAPI.axios.get(`api/campaign/${id}`);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async GetContractByCampaignId(campaignid) {
    try {
      const { data } = await RESTAPI.axios.get(`api/contract/${campaignid}`);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async Remove(id) {
    try {
      const { data } = await RESTAPI.axios.delete(`api/campaign/${id}`);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async SetContractDataByCampaignId(campaignId, contract) {
    try {
      const { data } = await RESTAPI.axios.put(
        `api/contract/${campaignId}/update`,
        { contract }
      );
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async Add(values) {
    try {
      const { data } = await RESTAPI.axios.post(`api/campaign/add`, values);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async Update(id, values) {
    try {
      const { data } = await RESTAPI.axios.put(`api/campaign/${id}`, values);
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async Compile(campaignId, networkId) {
    try {
      const { data } = await RESTAPI.axios.post(`api/contract/compile`, {
        campaignId,
        networkId,
      });
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async SetContractDataAfterPublish(campaignid, _data) {
    try {
      const { data } = await RESTAPI.axios.put(
        `api/contract/${campaignid}/publish`,
        { ..._data }
      );
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async VerifySourceCode(campaignId) {
    try {
      const { data } = await RESTAPI.axios.get(
        `api/contract/${campaignId}/verifysourcecode`
      );
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }

  static async CheckVerifyStatus(campaignId) {
    try {
      const { data } = await RESTAPI.axios.get(
        `api/contract/${campaignId}/checkverifystatus`
      );
      return data;
    } catch (e) {
      throw Error(e.message);
    }
  }
}
