import { goerli, mainnet, polygon, polygonMumbai, sepolia } from "wagmi/chains";
import { configureChains } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { object, string, number, addMethod } from "yup";

import config from "./config.json";
import messages from "./messages.json";
import abi from "./abi.json";

import NETWORKS from "./networks.json";

const {
  chains: [_polygonMumbai],
} = configureChains(
  [polygonMumbai],
  [
    jsonRpcProvider({
      rpc: () => ({ http: "https://polygon-mumbai-bor.publicnode.com" }),
    }),
  ]
);

/** Set CHAIN for networks */
NETWORKS[1].CHAIN = mainnet;
NETWORKS[5].CHAIN = goerli;
NETWORKS[137].CHAIN = polygon;
NETWORKS[80001].CHAIN = _polygonMumbai;
NETWORKS[11155111].CHAIN = sepolia;

const _web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: config.infuraId, // required
      },
    },
  },
});

addMethod(string, "isUniqueSlug", function (slugs = [], message = "") {
  return this.test("is-unique-slug", message, (value, { parent }) => {
    return !slugs[parent.domain_id]?.includes(value);
  });
});

addMethod(string, "isUniqueSlugMarketplace", function (slugs = [], message = "") {
  return this.test("is-unique-slug-marketplace", message, (value) => {
    return !slugs.includes(value);
  });
});

const validationSchema = {
  campaign: object().shape({
    name: string().required("Field is required"),
    contract: object().shape({
      nft_name: string().min(3, "Must be at least 3 characters").required("Field is required"),
      nft_symbol: string().min(3, "Must be at least 3 characters").required("Field is required"),
      cid: string().required("Field is required"),
      nft_price: number().positive().moreThan(0, "NFT Price must be greater than 0").required("Field is required"),
      max_mint_amount: number().positive().moreThan(0, "Mint Limit per Wallet must be greater than 0").required("Field is required"),
      total_number: number().positive().integer().moreThan(0, "Total Number of NFTs must be greater than 0").required("Field is required"),
      affiliate_percent: number().positive().moreThan(0, "Affiliate Percent must be greater than 0").required("Field is required"),
    }),
  }),
  contract: object().shape({
    contract: object().shape({
      nft_name: string().min(3, "Must be at least 3 characters").required("Field is required"),
      nft_symbol: string().min(3, "Must be at least 3 characters").required("Field is required"),
      cid: string().required("Field is required"),
      nft_price: number().positive().moreThan(0, "NFT Price must be greater than 0").required("Field is required"),
      affiliate_percent: number().positive().moreThan(0, "Affiliate Percent must be greater than 0").required("Field is required"),
      // single_type:
      max_mint_amount: number().when(["limit_enable", "nft_type"], {
        is: (limit_enable, nft_type) => limit_enable && nft_type === "collection",
        then: (schema) => schema.positive().moreThan(0, "Mint Limit per Wallet must be greater than 0").required("Field is required"),
      }),
      total_number: number().when(["nft_type", "single_type"], {
        is: (nft_type, single_type) => nft_type === "collection" || (nft_type === "single" && single_type === "limited"),
        then: (schema) => schema.positive().integer().moreThan(0, "Total Number of NFTs must be greater than 0").required("Field is required"),
      }),
    }),
  }),
  edit_campaign: object().shape({
    campaign_name: string().required("Field is required"),
    mint_url: string()
      .when("type_campaign", {
        is: (type_campaign) => +type_campaign === 2,
        then: (schema) => schema.min(3).matches(/^[a-z0-9-]+$/, "Only letters [a-z] or [0-9] or [-]"),
        otherwise: (schema) => schema.url(({ value }) => `${value} must be a valid URL`),
      })
      .required("Field is required"),
    contract: object().shape({
      nft_name: string().min(3, "Must be at least 3 characters").required("Field is required"),
      nft_symbol: string().min(3, "Must be at least 3 characters").required("Field is required"),
      cid: string().required("Field is required"),
      nft_price: number().positive().moreThan(0, "NFT Price must be greater than 0").required("Field is required"),
      max_mint_amount: number().when(["limit_enable", "nft_type"], {
        is: (limit_enable, nft_type) => limit_enable && nft_type === "collection",
        then: (schema) => schema.positive().moreThan(0, "Mint Limit per Wallet must be greater than 0").required("Field is required"),
      }),
      total_number: number().when(["total_enable", "nft_type"], {
        is: (total_enable, nft_type) => total_enable && nft_type === "collection",
        then: (schema) => schema.positive().integer().moreThan(0, "Total Number of NFTs must be greater than 0").required("Field is required"),
      }),
      affiliate_percent: number().positive().moreThan(0, "Affiliate Percent must be greater than 0").required("Field is required"),
    }),
  }),
};

export { config, abi, messages, _web3Modal, validationSchema, NETWORKS };

export { ThemeModeContextProvider, ThemeModeContext } from "./theme";
export { default as store } from "./store";
export { default as Contract } from "./contract";
export { default as Billing } from "./billing";
export { default as router } from "./router";
