import { createSlice } from "@reduxjs/toolkit";

export const category = createSlice({
  name: "category",
  initialState: [],
  reducers: {
    setCategory: (_, action) => {
      return [...action.payload];
    },
  },
});

export const { setCategory } = category.actions;
export default category.reducer;
